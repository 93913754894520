import cn from "classnames"
import { Route } from "navi"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute, useNavigation } from "react-navi"
import { TEnv, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { layoutReducerActions } from "../../store/reducers/layoutReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import useReduxDispatch from "../../store/useReduxDispatch"
import { TUserRelation } from "../../type/user/TUserRelation"
import "./NavMenu.scss"

interface INavItemProps extends IMenuLink {
    isActive: boolean
}
const NavItem = ({ isActive, href, iconClass, textId, noIcon }: INavItemProps) => {
    const { t } = useTranslation()
    const navi = useNavigation()
    const dispatch = useReduxDispatch()
    return (
        <li
            onClick={() => {
                navi.navigate(href)
                dispatch(layoutReducerActions.setNavBarOpen(false))
            }}
            className={cn(isActive && "router-link-exact-active router-link-active")}
        >
            <div className={cn("nav__item", noIcon && "no_icon")}>
                {!noIcon && <span className={`icon ${iconClass ?? ""}`}></span>}
                <p>{t(textId)}</p>
            </div>
        </li>
    )
}

interface IMenuLink {
    userRelationTypes?: TUserRelation[]
    href: string
    iconClass?: string
    noIcon?: boolean
    textId: string
    requiredMasterPartnerRelationSelected?: boolean
    includeEnvs?: TEnv[]
}

const MenuLinks: IMenuLink[] = [
    {
        userRelationTypes: ["Merchant"],
        href: "/merchantDetails",
        textId: "Merchant Details",
        noIcon: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/merchantOnboardings",
        textId: "Merchant Onboardings",
        requiredMasterPartnerRelationSelected: true,
        noIcon: true,
    },
    {
        userRelationTypes: ["Partner", "Merchant"],
        href: "/sentEmails",
        textId: "Sent Emails",
        noIcon: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/updateEmailTemplate",
        textId: "Update Email Template",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/updateBaseTemplate",
        textId: "Update Base Template",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/emailTemplateImages",
        textId: "Images",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/updateSmsTemplate",
        textId: "Update SMS Template",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner", "Merchant"],
        href: "/sentSmses",
        textId: "Sent Smses",
        noIcon: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/updateTermsTemplate",
        textId: "Update Terms Template",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/updateTermsBaseTemplate",
        textId: "Update Terms Base Template",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/managewebshopassets",
        textId: "CDN",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/webshopApiTester",
        textId: "Webshop API Tester",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/piqConfigGenerator",
        textId: "Piq Config Generator",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/swEnsureOnboarding",
        textId: "Swiipe Ensure Onboarding",
        noIcon: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/release_notes",
        textId: "Release notes",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/deleteUser",
        textId: "Delete user",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/customerContacts",
        textId: "Customer contacts",
        noIcon: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/credentials",
        textId: "Login details",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant", "Partner"],
        href: "/healthStatus",
        textId: "Health Status",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/order",
        textId: "Order recalculation",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/manageBilling",
        textId: "Manage billing",
        noIcon: true,
    },
    {
        userRelationTypes: ["Merchant", "Partner", "User"],
        href: "/testRuns",
        textId: "Test Runs",
        noIcon: true,
        includeEnvs: ["Test", "Development"],
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/plusSellSessions",
        textId: "Plus-sell",
        noIcon: true,
        includeEnvs: ["Test", "Development", "Production"],
    },
]

const NavMenu = () => {
    const currentRoute = useCurrentRoute()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const currentPath = currentRoute.url.pathname
    const [path, setPath] = useState(currentRoute.url.pathname)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)

    useEffect(() => {
        return navigationService.addNavigationListener((value: Route) => {
            // New route, trigger rerender
            if (path !== value.url.pathname) {
                setPath(value.url.pathname)
            }
        })
    }, [])

    const links = MenuLinks.filter((ml) => {
        if (!currentUserRelation) {
            return
        }

        return (
            (!ml.includeEnvs || ml.includeEnvs.includes(env)) &&
            (!ml.userRelationTypes || ml.userRelationTypes.find((r) => r === currentUserRelation.relationType)) &&
            (!ml.requiredMasterPartnerRelationSelected || currentUserRelation.isMasterPartner)
        )
    })
    return (
        <ul className={cn("sidebar-top")}>
            {links.map((link) => (
                <NavItem isActive={link.href === currentPath} key={link.href} {...link} />
            ))}
        </ul>
    )
}

export default NavMenu
