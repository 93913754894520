import cn from "classnames"
import React, { useState } from "react"
import { Input, InputProps, Label } from "reactstrap"
import { FieldsetFormGroup } from "../FieldsetFormGroup"
import "./FormGroupWithCheckbox.scss"

interface IFormGroupWithCheckboxProps extends InputProps {
    title?: string
    className?: string
    validationError?: string
    hint?: string
}

export const FormGroupWithCheckbox = ({
    title,
    children,
    className,
    validationError,
    hint,
    ...otherProps
}: IFormGroupWithCheckboxProps) => {
    const [showHint, setShowHint] = useState(false)

    return (
        <FieldsetFormGroup forCheckbox className={cn("form-group-with-checkbox", className)}>
            <Label check>
                <Input
                    className="swiipe-checkbox"
                    onInvalid={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (validationError) e.target.setCustomValidity(validationError)
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (validationError) e.target.setCustomValidity("")
                    }}
                    type="checkbox"
                    {...otherProps}
                />
                {title}
                {children}
                {hint && (
                    <div className="checkbox-hint-container">
                        <span
                            className="icon hint-icon"
                            onClick={(e) => {
                                e.preventDefault()
                                setShowHint(!showHint)
                            }}
                            onMouseOut={() => {
                                setShowHint(false)
                            }}
                            onTouchStart={() => {
                                setShowHint(true)
                            }}
                            onTouchEnd={() => {
                                setShowHint(false)
                            }}
                        ></span>
                        <span className={cn("hint-text", { "d-none": !showHint })}>{hint}</span>
                    </div>
                )}
            </Label>
        </FieldsetFormGroup>
    )
}
