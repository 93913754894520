import React from "react"

interface IPageTitleProps {
    title: string
}

const PageTitle = ({ title }: IPageTitleProps) => {
    return (
        <div className="page-title form-row mb-5">
            <h5 className="text-center pt-1 w-100">{title}</h5>
        </div>
    )
}

export default PageTitle
