import React, { useEffect, useState } from "react"
import { Row } from "reactstrap"
import { PageContainer, PageWrapper, StandardButton, StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import DropDownList from "../../components/form/input/DropDownList"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import useReduxDispatch from "../../store/useReduxDispatch"
import FieldsetFormGroup from "./../../components/form/FieldsetFormGroup"
import { merchantSelectors } from "./../../store/reducers/merchantReducer"
import { IPluginAssetSelection, TPluginAssetName, TPluginAssetType, pluginSelectors } from "./../../store/reducers/pluginReducer"
import {
    enableFastUpdateThunk,
    ensureWebshopAssetsSettingsThunk,
    getPluginAssetThunk,
    getPluginInfoThunk,
    updatePluginAssetThunk,
} from "./../../store/thunks/webshopAssetThunks"
import "./WebshopAssetEditPage.scss"

interface IWebshopAssetEditPageProps {}

const WebshopAssetEditPage = ({}: IWebshopAssetEditPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant", requiredRole: 90 }])
    const pluginInfoData = useSelector(pluginSelectors.pluginInfoData)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const webshops = merchantDetails?.webshops || []
    const [webshopId, setWebshopId] = useState("")
    const settings = useSelector((state: StoreState) => pluginSelectors.webshopAssetsSettings(state, webshopId))

    const dispatch = useReduxDispatch()
    useEffect(() => {
        if (!pluginInfoData) {
            dispatch(getPluginInfoThunk())
        }
    }, [])

    useEffect(() => {
        if (!settings) {
            dispatch(ensureWebshopAssetsSettingsThunk(webshopId))
        }
    }, [webshopId])

    const [timeCounter, setTimeCounter] = useState(0)
    const [content, setContent] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const [countryId, setCountryId] = useState("")
    const [pluginName, setPluginName] = useState("")
    const [assetName, setAssetName] = useState<TPluginAssetName>("checkout")
    const [assetType, setAssetType] = useState<TPluginAssetType>("style")

    const platformId = webshopId ? webshops.find((w) => w.webshopId === webshopId)?.platform ?? "" : ""

    const pluginAssetSelection: IPluginAssetSelection = {
        webshopId,
        platform: platformId,
        country: countryId,
        plugin: pluginName,
        assetName,
        assetType,
    }

    const pluginAsset = useSelector(pluginSelectors.pluginAsset(pluginAssetSelection))

    useEffect(() => {
        setContent(pluginAsset)
    }, [pluginAsset])

    useEffect(() => {
        if (settings && settings.fastUpdateUntil && settings.fastUpdateUntil > new Date()) {
            setTimeout(() => {
                setTimeCounter(timeCounter + 1)
            }, 1000 * 60)
        }
    }, [timeCounter, settings])

    useEffect(() => {
        if (!pluginInfoData || pluginInfoData.length === 0) {
            setCountryId("")
            setPluginName("")
            return
        }
        let updatedVars = false

        if (!webshopId) {
            if (merchantDetails && merchantDetails.webshops.length > 0) {
                setWebshopId(merchantDetails.webshops[0].webshopId)
                updatedVars = true
            } else {
                return
            }
        }

        // Find platform
        const platform = pluginInfoData.find((d) => d.id === platformId)
        if (!platform) {
            return
        }
        if (platform.countries.length === 0) {
            setCountryId("")
            setPluginName("")
            return
        }
        // Find country
        let country = platform.countries.find((d) => d.id === countryId)
        if (!country) {
            country = platform.countries[0]
            setCountryId(country.id)
            updatedVars = true
        }
        if (country.plugins.length === 0) {
            setPluginName("")
            return
        }
        // Find plugin
        let selectedPlugin = country.plugins.find((p) => p === pluginName)
        if (!selectedPlugin) {
            selectedPlugin = country.plugins.find((p) => p === "checkout")
            if (!selectedPlugin) {
                selectedPlugin = country.plugins[0]
            }
            setPluginName(selectedPlugin)
            updatedVars = true
        }
        // Ensure assetName
        if (!assetName) {
            setAssetName("checkout")
            updatedVars = true
        }
        // Ensure assetType
        if (!assetType) {
            setAssetType("style")
            updatedVars = true
        }
        if (!updatedVars) {
            dispatch(getPluginAssetThunk(pluginAssetSelection))
        }
    }, [merchantDetails, webshopId, pluginInfoData, platformId, countryId, pluginName, assetName, assetType])

    if (!pluginInfoData || !merchantDetails) {
        return null
    }

    const save = async () => {
        try {
            setIsSaving(true)
            await dispatch(updatePluginAssetThunk(pluginAssetSelection, content))
        } catch (err) {
            // Catch to stop showing spinner
        }
        setIsSaving(false)
    }

    const platforms = pluginInfoData
    const platform = pluginInfoData.find((p) => p.id === platformId)
    const countries = platform ? platform.countries || [] : []
    const country = countries.find((p) => p.id === countryId)
    const plugins = country ? country.plugins || [] : []
    const plugin = plugins.find((p) => p === pluginName)
    const assets: TPluginAssetName[] = plugin ? ["checkout", "thankyou", "product", "admin", "return"] : []
    const assetTypes: TPluginAssetType[] = plugin ? ["script", "style"] : []

    return (
        <PageWrapper fullWidth>
            <PageContainer classNameBody="webshop-asset-edit-page" title="Plugin asset editor">
                <SpinnerContainer aboveContent showSpinner={isSaving}>
                    <div className="webshop-asset-selector-container">
                        <Row>
                            <FieldsetFormGroup field col2>
                                {webshops.length > 0 && (
                                    <DropDownList
                                        placeholder={"Webshop"}
                                        value={webshopId}
                                        onChange={(e) => {
                                            setWebshopId(e.target.value)
                                        }}
                                        options={webshops.map((d) => ({ text: `${d.hostName}`, value: d.webshopId }))}
                                    />
                                )}
                            </FieldsetFormGroup>
                            <FieldsetFormGroup field col2 className="platform-name">
                                {platforms.length > 0 && platformId}
                            </FieldsetFormGroup>
                        </Row>
                        <Row>
                            <FieldsetFormGroup field col2>
                                {countries.length > 0 && (
                                    <DropDownList
                                        placeholder={"Country"}
                                        value={countryId}
                                        onChange={(e) => setCountryId(e.target.value)}
                                        options={countries.map((d) => ({ text: d.displayName, value: d.id }))}
                                    />
                                )}
                            </FieldsetFormGroup>
                            <FieldsetFormGroup field col2>
                                {plugins.length > 0 && (
                                    <DropDownList
                                        placeholder={"Plugin Name"}
                                        value={pluginName}
                                        onChange={(e) => setPluginName(e.target.value)}
                                        options={plugins.map((d) => ({ text: d, value: d }))}
                                    />
                                )}
                            </FieldsetFormGroup>
                        </Row>
                        <Row>
                            <FieldsetFormGroup field col2>
                                {plugin && (
                                    <DropDownList
                                        placeholder={"Webpage for asset"}
                                        value={assetName}
                                        onChange={(e) => setAssetName(e.target.value as TPluginAssetName)}
                                        options={assets.map((d) => ({ text: d, value: d }))}
                                    />
                                )}
                            </FieldsetFormGroup>
                            <FieldsetFormGroup field col2>
                                {plugin && (
                                    <DropDownList
                                        placeholder={"Type of asset"}
                                        value={assetType}
                                        onChange={(e) => setAssetType(e.target.value as TPluginAssetType)}
                                        options={assetTypes.map((d) => ({ text: d, value: d }))}
                                    />
                                )}
                            </FieldsetFormGroup>
                        </Row>
                    </div>
                    <div className="webshop-asset-editor mt-2">
                        <SpinnerContainer showSpinner={typeof pluginAsset === "undefined"}>
                            <CodeEditor
                                code={content}
                                onChange={(code) => setContent(code)}
                                language={assetType === "script" ? "js" : "css"}
                                minHeight={300}
                                onSaveKey={() => {
                                    save()
                                }}
                            />
                        </SpinnerContainer>
                        <FieldsetFormGroup className="button-container mt-2">
                            <StandardButtonWithSpinner
                                invertedBlue
                                title="Set fast update for 1 hour"
                                onClick={async () => {
                                    try {
                                        await dispatch(enableFastUpdateThunk(webshopId))
                                    } catch (ex) {
                                        // Ignore
                                    }
                                }}
                            />
                            <SpinnerContainer showSpinner={isSaving}>
                                <StandardButton
                                    title="Update asset"
                                    onClick={async () => {
                                        save()
                                    }}
                                />
                            </SpinnerContainer>
                        </FieldsetFormGroup>
                        {settings && settings.fastUpdateUntil && settings.fastUpdateUntil > new Date() && (
                            <div className="fast-update-time">
                                <span>
                                    Fast update enabled for{" "}
                                    {Math.ceil((settings.fastUpdateUntil.getTime() - new Date().getTime()) / (1000 * 60))} minutes
                                </span>
                            </div>
                        )}
                    </div>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default WebshopAssetEditPage
