import React from "react"
import cn from "classnames"
import "./CheckmarkCircle.scss"

interface ICheckmarkCircle {
    checked: boolean
    size?: "medium" | "big"
}

export const CheckmarkCircle = ({ checked, size = "medium" }: ICheckmarkCircle) => {
    return (
        <div className={cn("checkmark-circle", size && `checkmark-circle__size-${size}`)}>
            <svg
                className={cn("checkmark", checked ? "enabled" : "disabled")}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
                key={checked ? "checked" : "disabled"}
            >
                <circle className={cn("checkmark_circle", checked ? "enabled" : "disabled")} cx="26" cy="26" r="25" fill="none" />
                <path
                    className="checkmark_check"
                    fill="none"
                    d={checked ? "M14.1 27.2l7.1 7.2 16.7-16.8" : "M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"}
                />
            </svg>
        </div>
    )
}
