import { IPiqConfigData } from "../type/paymentIQ/IPiqConfigData"
import { sortAscending } from "../util/arrayUtil"

import {
    IClearhausMPIPiqConfig,
    IClearhausPiqConfig,
    IKlarnaConfig,
    IMerchantPiqConfig,
    IMobilePayPiqConfig,
    IPaymentIQGeneratedConfigs,
    ISwishPiqConfig,
    IVippsConfig,
} from "../type/paymentIQ/IPaymentIQConfigs"

export function GenerateConfigs(configData: IPiqConfigData): IPaymentIQGeneratedConfigs {
    const result: IPaymentIQGeneratedConfigs = {
        configs: [
            {
                label: "MerchantConfig",
                config: createMerchantConfig({
                    apiIntegrationUsername: configData.piqSecrets.apiIntegrationUsername,
                    apiIntegrationPassword: configData.piqSecrets.apiIntegrationPassword,
                }),
            },
            {
                label: "SharedUserPspAccountConfig",
                config: createdSharedUserPspAccountConfig(),
            },
            {
                label: "ClearhausConfig",
                config: createClearhausConfig({
                    apiKey: configData.clearhausApiKey,
                    secrets: configData.piqSecrets.clearhausSecrets,
                    descriptor: configData.clearhausDescriptor,
                    mcc: configData.clearhausMcc,
                    merchantCountry: configData.country,
                    mid: configData.clearhausMerchantId,
                    merchantName: configData.merchantName,
                    merchantUrl: configData.merchantUrl,
                }),
            },
            {
                label: "ClearhausMPIConfig",
                config: createClearhausMpiConfig({ merchantUrl: configData.merchantUrl }),
            },
            {
                label: "MobilePayConfig",
                config: createMobilePayConfig({
                    shopName: configData.mpShopName,
                    logoUrl: configData.mpLogoUrl,
                    merchantCountry: configData.country,
                    isDankort: configData.isDankort,
                    shopUrl: configData.merchantUrl,
                }),
            },
            {
                label: "SwishConfig",
                config:
                    configData.swishId && configData.piqSecrets.swishSecrets
                        ? createSwishConfig({
                              swishId: configData.swishId,
                              descriptor: new URL(configData.merchantUrl).host,
                              secrets: configData.piqSecrets.swishSecrets,
                          })
                        : undefined,
            },
            {
                label: "KlarnaConfig",
                config:
                    configData.klarnaId && configData.piqSecrets.klarnaSecrets
                        ? createKlarnaConfig({ klarnaId: configData.klarnaId, secrets: configData.piqSecrets.klarnaSecrets })
                        : undefined,
            },
            {
                label: "VippsConfig",
                config:
                    configData.vippsId && configData.vippsClientId && configData.piqSecrets.vippsSecrets
                        ? createVippsConfig({
                              vippsId: configData.vippsId,
                              vippsClientId: configData.vippsClientId,
                              secrets: configData.piqSecrets.vippsSecrets,
                          })
                        : undefined,
            },
            {
                label: "VippsSystemConfig",
                config:
                    configData.vippsId && configData.vippsClientId && configData.piqSecrets.vippsSecrets
                        ? createVippsSystemConfig({
                              vippsId: configData.vippsId,
                              vippsClientId: configData.vippsClientId,
                              secrets: configData.piqSecrets.vippsSecrets,
                          })
                        : undefined,
            },
        ],
    }
    // sort listed configs to keep order 1-1 with piq - piq sorts their configs in alphabetic order
    result.configs = sortAscending(result.configs, (c) => c.label)
    return result
}

function createdSharedUserPspAccountConfig(): string {
    return `<com.devcode.paymentiq.integration.merchant.SharedUserPspAccountConfig>
    <userPspAccountMid>100231000</userPspAccountMid>
</com.devcode.paymentiq.integration.merchant.SharedUserPspAccountConfig>`
}

function createClearhausConfig(data: IClearhausPiqConfig): string {
    let merchantId = data.mid
    const size = 15 // clearhaus needs merchant id of 15 chars padded by zero
    while (merchantId.length < size) {
        merchantId = "0" + merchantId
    }

    //KeyVault stores secret in one line despite multilines, but BEGIN RSA and END RSA needs to be in separate lines to be valid key. These are added manually instead
    const rsaKey = data.secrets.clearhausRsaKey
        .replace("-----BEGIN RSA PRIVATE KEY-----", "")
        .replace("-----END RSA PRIVATE KEY-----", "")

    return `<com.devcode.paymentiq.integration.clearhaus.ClearhausConfig>
  <enabled>true</enabled>
  <useViqProxy>true</useViqProxy>
  <providerTxRefField>\${ptx.merchantTxId}</providerTxRefField>
  <accounts>
    <entry>
      <string>N3DS</string>
      <account>
        <serviceEndpoint>https://gateway.clearhaus.com</serviceEndpoint>
        <username>${data.apiKey}</username>
        <password></password>
        <use3Dsecure>false</use3Dsecure>
        <authType>FINAL_AUTH</authType>
      </account>
    </entry>
    <entry>
      <string>3DS</string>
      <account>
        <serviceEndpoint>https://gateway.clearhaus.com</serviceEndpoint>
        <username>${data.apiKey}</username>
        <password></password>
        <use3Dsecure>true</use3Dsecure>
        <authType>FINAL_AUTH</authType>
      </account>
    </entry>
    <entry>
      <string>3DS2</string>
      <account>
        <serviceEndpoint>https://gateway.clearhaus.com</serviceEndpoint>
        <username>${data.apiKey}</username>
        <password></password>
        <use3Dsecure>true</use3Dsecure>
        <authType>FINAL_AUTH</authType>
        <!-- 3DS2 mapping -->
        <acquirerMerchantId>${merchantId}</acquirerMerchantId>
        <mcc>${data.mcc}</mcc>
        <merchantCountry>${data.merchantCountry}</merchantCountry>
        <merchantUrl>${data.merchantUrl}</merchantUrl>
        <merchantName>${data.merchantName}</merchantName>
        <!-- End of 3DS2 mapping -->
      </account>
    </entry>
    <entry>
      <string>3DS2LVX</string>
      <account>
        <scaExemption>LOW_VALUE_PAYMENT</scaExemption>
        <serviceEndpoint>https://gateway.clearhaus.com</serviceEndpoint>
        <username>${data.apiKey}</username>
        <password></password>
        <use3Dsecure>true</use3Dsecure>
        <authType>FINAL_AUTH</authType>
        <!-- 3DS2 mapping -->
        <acquirerMerchantId>${merchantId}</acquirerMerchantId>
        <mcc>${data.mcc}</mcc>
        <merchantCountry>${data.merchantCountry}</merchantCountry>
        <merchantUrl>${data.merchantUrl}</merchantUrl>
        <merchantName>${data.merchantName}</merchantName>
        <!-- End of 3DS2 mapping -->
      </account>
    </entry>
  </accounts>
  <defaultDescriptor>${data.descriptor}</defaultDescriptor>
  <signingApiKey>${data.secrets.clearhausSigningApiKey}</signingApiKey>
  <signingRsaPrivateKey>
-----BEGIN RSA PRIVATE KEY-----
${rsaKey?.trim()}
-----END RSA PRIVATE KEY-----
  </signingRsaPrivateKey>
</com.devcode.paymentiq.integration.clearhaus.ClearhausConfig>`
}

function createClearhausMpiConfig(data: IClearhausMPIPiqConfig): string {
    return `<com.devcode.paymentiq.integration.clearhaus.ClearhausMPIConfig>
  <enabled>true</enabled>
  <merchantUrl>${data.merchantUrl}</merchantUrl>
</com.devcode.paymentiq.integration.clearhaus.ClearhausMPIConfig>`
}

function createMobilePayConfig(data: IMobilePayPiqConfig): string {
    return `<com.devcode.paymentiq.integration.mobilepay.MobilePayConfig>
  <enabled>true</enabled>
  <apiVersion>v3</apiVersion>
  <shopName>\${ptx.latestTxCmdMap.MobilePayDepositInput.attributes.mpoShopName}</shopName>
  <shopCountryCode>${data.merchantCountry}</shopCountryCode>
  <shopId>\${ptx.latestTxCmdMap.MobilePayDepositInput.attributes.mpoMerchantId}</shopId>
  <shopLogoUrl>\${ptx.latestTxCmdMap.MobilePayDepositInput.attributes.mpoShopLogoUrl}</shopLogoUrl>
  <shopUrl>\${ptx.latestTxCmdMap.MobilePayDepositInput.attributes.mpoShopUrl}</shopUrl>
  <container>window</container> <!-- Set container value on the redirectOutput response (window/iframe) -->
  <usePhoneNumberFromVerifyUser>true</usePhoneNumberFromVerifyUser>
  <selectProviderAfterCallback>${data.isDankort}</selectProviderAfterCallback>
  <acceptedCards>
    <card><type>MC-DEBIT</type></card>
    <card><type>MC-CREDIT</type></card>
    <card><type>MTRO-DEBIT</type></card>
    <card><type>ELEC-DEBIT</type></card>
    <card><type>VISA-DEBIT</type></card>
    <card><type>VISA-CREDIT</type></card>
    ${data.isDankort ? "<card><type>DANKORT</type></card>" : "<!--card><type>DANKORT</type></card-->"}
  </acceptedCards>
</com.devcode.paymentiq.integration.mobilepay.MobilePayConfig>`
}

function createMerchantConfig(data: IMerchantPiqConfig): string {
    return `<com.devcode.paymentiq.integration.merchant.MerchantConfig>
  <enabled>true</enabled>
  <apiIntegrationUrl>https://paymentiq.swiipe.com/api/paymentiq/{action}</apiIntegrationUrl>
  <apiIntegrationUsername>${data.apiIntegrationUsername}</apiIntegrationUsername>
  <apiIntegrationPassword>${data.apiIntegrationPassword}</apiIntegrationPassword>
  <integrationService>standardMerchantIntegrationService</integrationService>
  <adminApiReadingFromES>true</adminApiReadingFromES>
  <threeDSConfig>
    <enabled>true</enabled>
  </threeDSConfig>
  <txTypesWithActiveSha256Matching>ALL_TYPES</txTypesWithActiveSha256Matching>
  <!-- Activates block within scope functionality -->
  <userPspAccountsBlockWithinScopeEnabled>true</userPspAccountsBlockWithinScopeEnabled>
  <sendNotifications>false</sendNotifications>
  <properties>
    <entry>
      <!-- Required for 1-click api -->
      <string>oauthBaseRedirectUrl</string>
      <string>https://backoffice.paymentiq.io/paymentiq</string>
    </entry>
    <entry>
      <string>baseRedirectUrl</string>
      <string>https://api.paymentiq.io/paymentiq</string>
    </entry>
    <entry>
      <string>baseCallbackUrl</string>
      <string>https://api.paymentiq.io/paymentiq</string>
    </entry>
    <entry>
      <string>successUrl</string>
      <string>\${baseCashierV2Url}/redirect-success?txId=\${ptx.id}</string>
    </entry>
    <entry>
      <string>failureUrl</string>
      <string>\${baseCashierV2Url}/redirect-failure?txId=\${ptx.id}</string>
    </entry>
    <entry>
      <string>pendingUrl</string>
      <string>\${baseCashierV2Url}/redirect-pending?txId=\${ptx.id}</string>
    </entry>
    <entry>
      <string>cancelUrl</string>
      <string>\${baseCashierV2Url}/redirect-cancel?txId=\${ptx.id}</string>
    </entry>
  </properties>
  <errorCodeMapping>
    <entries>
      <statusCodeEntry>
        <response>100</response>
        <statusCode>ERR_DECLINED_NO_FUNDS</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>110</response>
        <statusCode>ERR_DECLINED_BAD_REQUEST</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>200</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>201</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>202</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>203</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>204</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>205</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>250</response>
        <statusCode>ERR_DECLINED_LIMIT_OVERDRAWN</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>300</response>
        <statusCode>ERR_NOT_AUTHENTICATED</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>400</response>
        <statusCode>ERR_DECLINED_BAD_REQUEST</statusCode>
      </statusCodeEntry>
      <statusCodeEntry>
        <response>500</response>
        <statusCode>ERR_MERCHANT_OUT_OF_SERVICE</statusCode>
      </statusCodeEntry>
    </entries>
  </errorCodeMapping>
  <countryCodes>SWE|USA|FIN|NOR|ESP|RUS|ZZZ|CZE|SVK|GBR|DEU</countryCodes>
  <channels>I|M</channels>
  <currencies>RUS|SEK|EUR|USD|NOK|GBP|PLN|CZK</currencies>
  <types>quickdeposit</types>
  <userCategories>VIP|Standard</userCategories>
  <psps>EPay|BamboraGa|WirecardElastic|Clearhaus</psps>
  <geoIPFile>/etc/tomcat7/GeoLiteCity.dat</geoIPFile>
  <velocityDaysBack>150</velocityDaysBack>
  <velocityNoOfHistoricalTx>1000</velocityNoOfHistoricalTx>
  <locales>sv_SE|en_US|en_GB|en_AU|nb_NO|no_NO|pl_PL|de_AT|de_DE|de_CH|fi_FI|cs_CZ|en|dk</locales>
  <defaultLocale>en</defaultLocale>
  <serviceMapping>
    <entry>
      <string>ENVOY</string>
      <string>EPS|SOFORT|GIROPAY|FUNDSEND|IDEAL|PRZELEWY|EKONTO|TRUSTPAY|UKASHHOSTED</string>
    </entry>
    <entry>
      <string>ACCENTPAY</string>
      <string>WEBMONEY|YANDEX|QIWI|MONETA</string>
    </entry>
    <entry>
      <string>PPRO</string>
      <string>ASTROPAYCARD|ASTROPAYDIRECT|MISTERCASH|BOLETO|EPS|GIROPAY|IDEAL|OXXO|P24|PAYSAFECARD|POLI|POSTFINANCE|PUGGLEPAY|QIWI|SAFETYPAY|SEPADIRECTDEBIT|SKRILL|SOFORT|TELEINGRESO|TRUSTLY|TRUSTPAY|VERKKOPANKKI</string>
    </entry>
  </serviceMapping>
  <extraAttributes>
    <!-- Add for needed Interac parameters to be sent in transfer -->
    <entry>
      <string>bankname</string>
      <string>\${ptx.latestTxCmdMap.InteracCallbackInput.idebitBankName}</string>
    </entry>
    <entry>
      <string>issconf</string>
      <string>\${ptx.latestTxCmdMap.InteracCallbackInput.idebitISSCONF}</string>
    </entry>
    <entry>
      <string>cardBrand</string>
      <string>\${ptx.latestTxCmdMap.BinBlockRes.cardBrand}</string>
    </entry>
  </extraAttributes>
  <userEmailCommunicationConfig>
    <enabled>true</enabled>
    <providerId>changetocorrespondtoemailintegrationconfig</providerId>
    <fromEmail>noreply@yourdomain.com</fromEmail>
  </userEmailCommunicationConfig>
  <internalEmailCommunicationConfig>
    <enabled>true</enabled>
    <providerId>mailgun</providerId>
    <fromEmail>no-reply@paymentiq.io</fromEmail>
  </internalEmailCommunicationConfig>
  <forcedCommunicationChannel>
    <com.devcode.paymentiq.service.merchant.MerchantUserCommunicationChannel>EMAIL</com.devcode.paymentiq.service.merchant.MerchantUserCommunicationChannel>
  </forcedCommunicationChannel>
  <sortPSPAccountsByService>true</sortPSPAccountsByService>
  <userPspAccountBlockReasons>
    <string>Chargeback</string>
    <string>3rd Party Card - Not Authorised</string>
    <string>Business Card</string>
    <string>Confirmed Fraud</string>
    <string>Suspected Fraud</string>
    <string>Customer Request</string>
    <string>Gambling Problem</string>
    <string>UK Self-exclusion</string>
    <string>Self-exclusion</string>
    <string>Other</string>
  </userPspAccountBlockReasons>
  <tags>
    <entry>
      <string>Refund</string>
      <list>
        <tag>
          <id>RF-1</id>
          <name>Refund due to to technical error</name>
        </tag>
        <tag>
          <id>RF-2</id>
          <name>Refund other reason</name>
        </tag>
      </list>
    </entry>
    <entry>
      <string>Chargeback</string>
      <list>
        <tag>
          <id>CB-1</id>
          <name>Stolen card</name>
        </tag>
        <tag>
          <id>CB-2</id>
          <name>Other reason</name>
        </tag>
      </list>
    </entry>
    <entry>
      <string>Void</string>
      <list>
        <tag>
          <id>V-1</id>
          <name>Other reason</name>
        </tag>
      </list>
    </entry>
  </tags>
  <!--Frond-End api shall have right "Success" + "Error" response-->
  <ignorePtxStateCheck>true</ignorePtxStateCheck>
  <sendErrorIfPtxIsNotFound>true</sendErrorIfPtxIsNotFound>
  <!-- -->
  <boHasActiveEsRes>true</boHasActiveEsRes>
  <boReadingFromES>true</boReadingFromES>
</com.devcode.paymentiq.integration.merchant.MerchantConfig>`
}

function createSwishConfig(data: ISwishPiqConfig): string {
    return `<com.devcode.paymentiq.integration.swish.SwishConfig>
    <enabled>true</enabled>
    <accounts>
        <entry>
            <string>default</string>
            <account>
                <supportedCurrencies>SEK</supportedCurrencies>
                <quickDeposit>false</quickDeposit>
                <merchantNumber>${data.swishId?.trim()}</merchantNumber>
                <httpClientConfigEntry>
                    <urlPattern>.*cpc.getswish.net.*</urlPattern>
                    <viqProxy>false</viqProxy>
                    <useClientCert>true</useClientCert>
                    <clientCertPem>
${data.secrets.swishProviderCertificate?.trim()}
                    </clientCertPem>
                    <clientCertKey>
${data.secrets.swishRsaKey?.trim()}
                    </clientCertKey>
                </httpClientConfigEntry>
            </account>
        </entry>
    </accounts>
    <defaultDescriptor>${data.descriptor}</defaultDescriptor>
</com.devcode.paymentiq.integration.swish.SwishConfig>`
}

function createKlarnaConfig(data: IKlarnaConfig): string {
    return `<com.devcode.paymentiq.integration.klarna.KlarnaConfig>
  <enabled>true</enabled>
  <useViqProxy>false</useViqProxy>
  <accounts>
    <entry>
      <string>PAY_LATER</string>
      <account>
        <logoUrl>https://static.paymentiq.io/klarna_swiipe.png</logoUrl>
        <supportedCurrencies>AUD|CAD|CHF|DKK|EUR|GBP|NOK|SEK|USD</supportedCurrencies>
        <username>${data.klarnaId}</username>
        <password>${data.secrets.klarnaApiKey}</password>
        <authType>FINAL_AUTH</authType>
        <showTerms>true</showTerms>
        <service>PAY_LATER</service>
      </account>
    </entry>
    <entry>
      <string>PAY_OVER_TIME</string>
      <account>
        <logoUrl>https://static.paymentiq.io/klarna_swiipe.png</logoUrl>
        <supportedCurrencies>AUD|CAD|CHF|DKK|EUR|GBP|NOK|SEK|USD</supportedCurrencies>
        <username>${data.klarnaId}</username>
        <password>${data.secrets.klarnaApiKey}</password>
        <authType>FINAL_AUTH</authType>
        <showTerms>true</showTerms>
        <service>PAY_OVER_TIME</service>
      </account>
    </entry>
  </accounts>
  <container>iframe</container>
</com.devcode.paymentiq.integration.klarna.KlarnaConfig>`
}

function createVippsConfig(data: IVippsConfig): string {
    return `<com.devcode.paymentiq.integration.vipps.VippsConfig>
  <enabled>true</enabled>
  <useViqProxy>true</useViqProxy>
  <accounts>
    <entry>
      <string>DEFAULT</string>
      <account>
        <merchantId>${data.vippsId}</merchantId>
        <supportedCurrencies>NOK</supportedCurrencies>
      </account>
    </entry>
  </accounts>
  <container>window</container>
</com.devcode.paymentiq.integration.vipps.VippsConfig>`
}

function createVippsSystemConfig(data: IVippsConfig): string {
    return `<com.devcode.paymentiq.integration.vipps.VippsSystemConfig>
    <enabled>true</enabled>
    <!--Provided by Vipps-->
    <superMerchantId></superMerchantId>
    <clientId>${data.vippsClientId}</clientId>
    <clientSecret>${data.secrets.vippsClientSecret}</clientSecret>
    <subscriptionKey>${data.secrets.vippsSubscriptionKey}</subscriptionKey>
</com.devcode.paymentiq.integration.vipps.VippsSystemConfig>`
}
