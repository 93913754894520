import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { Form } from "reactstrap"
import { FloatingLabelInput } from "swiipe.portal.shared"
import { loginThunk } from "../../../store/thunks/loginThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import { LoginFormModel } from "../../../type/login/loginForm"
import { valFuncRequiredAndPattern, validationPatterns } from "../../../util/validationUtil"
import FieldsetFormGroup from "../FieldsetFormGroup"
import SubmitButton from "./../../buttons/SubmitButton"
import ShowErrorMessages from "./../ShowErrorMessages"
import "./LoginForm.scss"

interface ILoginFormProps {
    isTrimmed: boolean
}

const LoginForm = ({}: ILoginFormProps) => {
    const { handleSubmit, register, errors, formState } = useForm<LoginFormModel>()
    const { t } = useTranslation()
    const currentRoute = useCurrentRoute()
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<LoginFormModel> = async (data) => {
        try {
            await dispatch(loginThunk(data, (currentRoute.url.query.returnUrl || "/").split("#")[0]))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="email"
                    innerRef={register(valFuncRequiredAndPattern(validationPatterns.email, t("validation.wrongMail")))}
                    placeholder={t("login.email")}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="password"
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.password, t("validation.loginForm.wrongPassword"))
                    )}
                    type="password"
                    placeholder={t("login.password")}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup className="form-group-login-btn">
                <SubmitButton formState={formState} id="login-btn" isLogin />
            </FieldsetFormGroup>
            <ShowErrorMessages<LoginFormModel>
                errors={errors}
                singleErrorFromCount={{ errorMessage: t("login.errors.emailandpassword"), errosCount: 2 }}
            />
        </Form>
    )
}

export default LoginForm
