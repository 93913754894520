export type ELegalRelationType = "Merchant" | "Partner" | "User"
export type ETermsProductType =
    | "WinBack"
    | "ReturnSell"
    | "PlusSell"
    | "AnywhereMobile"
    | "Acquiring"
    | "A2A"
    | "PSP"
    | "Klarna"
    | "Swish"
    | "ApplePay"
    | "Dankort"
    | "ViaBill"
    | "Forbrugsforeningen"
    | "Vipps"
    | "MobilePayOnline"
    | "Resurs"
    | "Dashboard"
    | "Tryg"
export type ETermsProvider = "Clearhaus"
export type ETermsCategory = "GeneralTerms" | "PrivacyPolicy" | "Agreement"

export const allTermsTypes = [
    "TermsMerchant",
    "TermsUser",
    "TermsPartner",
    "MerchantPartnerAgreement",
    "PrivacyPolicyShared",
    "TermsReturnSell",
    "TermsPlusSell",
    "TermsWinBack",
    "TermsAnywhereMobile",
    "DataProcessingAgreement",
    "TermsAcquiringClearhaus",
    "TermsA2a",
    "TermsPSP",
    "TermsKlarna",
    "TermsSwish",
    "TermsApplePay",
    "TermsDankort",
    "TermsViaBill",
    "TermsForbrugsforeningen",
    "TermsVipps",
    "TermsMobilePayOnline",
    "TermsResurs",
    "TermsExternalAnalytics",
    "TermsTrygUser",
] as const
export type ETermsType = (typeof allTermsTypes)[number]
