import React, { useEffect, useState } from "react"
import { PageContainer, PageWrapper, TextButton, useSelector } from "swiipe.portal.shared"
import EmailTemplateImage from "../../components/emailTemplate/EmailTemplateImage"
import DropDownList from "../../components/form/input/DropDownList"
import PageTitle from "../../components/page/PageTitle"
import { languages, languagesIncludeShared } from "../../data/languages"
import { getImagesWithBestLanguageFit } from "../../services/emailTemplateService"
import { StoreState } from "../../store/StoreState"
import { emailTemplateSelectors } from "../../store/reducers/emailTemplateReducer"
import { getEmailTemplateImagesThunk } from "../../store/thunks/emailTemplateImageThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import "./EmailTemplateImagesPage.scss"

interface IEmailTemplateImagesPage {}

const EmailTemplateImagesPage = ({}: IEmailTemplateImagesPage) => {
    const dispatch = useReduxDispatch()
    const [language, setLanguage] = useState(languages[0].code)
    const [showHelp, setShowHelp] = useState(false)

    const images = useSelector((state: StoreState) => emailTemplateSelectors.emailTemplateImages(state)) ?? []
    const imagesBestFits = getImagesWithBestLanguageFit(images, language)

    useEffect(() => {
        dispatch(getEmailTemplateImagesThunk(false))
    }, [])

    return (
        <PageWrapper fullWidth>
            <PageContainer id="email-template-images-page">
                <PageTitle title="Handle Images For Email Templates" />
                <TextButton text={showHelp ? "Hide help" : "Show help"} onClick={async () => setShowHelp(!showHelp)} />
                {showHelp && (
                    <div>
                        <p>
                            The border of the images can be either:
                            <ol>
                                <li>
                                    <span style={{ color: "green" }}>Green</span> if the image exist for the selected language
                                </li>
                                <li>
                                    <span style={{ color: "blue" }}>Blue</span> if the image does not exist for the selected
                                    language, but exists for shared
                                </li>
                                <li>
                                    <span style={{ color: "red" }}>Red</span> if the image only exists for another language and
                                    not shared
                                </li>
                            </ol>
                        </p>
                        <p>
                            So use shared for images that does not contain text and can be used across languages.
                            <br />
                            When sending an email, the server will first check if the is an image for the language.
                            <br /> If the is none, then the shared image will be selected instead.
                        </p>
                    </div>
                )}
                <DropDownList
                    label="Language"
                    containerClassName="language"
                    value={language}
                    onChange={(v) => setLanguage(v.target.value)}
                    options={languagesIncludeShared.map((x) => ({ text: x.label, value: x.code }))}
                />
                <div className="images">
                    {[undefined, ...imagesBestFits].map((img, i) => {
                        return <EmailTemplateImage key={i + (img?.name ?? "")} language={language} image={img} />
                    })}
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default EmailTemplateImagesPage
