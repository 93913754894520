import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IPlusSellReport } from "../../type/plusSell/IPlusSellReport"
import { IPlusSellSession } from "../../type/plusSell/IPlusSellSession"
import { StoreState } from "../StoreState"

export type PlusSellState = Readonly<{
    plusSellSessions: { [webshopId: string]: IPlusSellSession[] | undefined }
    plusSellAggregatedReports: { [webshopId: string]: IPlusSellReport | undefined }
    plusSellSessionsContinuationTokens: { [webshopId: string]: string | undefined }
}>

const initialState: PlusSellState = {
    plusSellSessions: {},
    plusSellAggregatedReports: {},
    plusSellSessionsContinuationTokens: {},
}

export const plusSellReducerActions = {
    setPlusSellSessions: (webshopId: string, sessions: IPlusSellSession[], continuationToken: string) =>
        createAction("APP/SET_PLUSSELL_SESSIONS", { sessions, webshopId, continuationToken }),
    setPlusSellAggregatedReport: (webshopId: string, report: IPlusSellReport) =>
        createAction("APP/SET_PLUSSELL_AGGREGATED_REPORT", { report, webshopId }),
}

export type PlusSellActions = ActionsUnion<typeof plusSellReducerActions>

export const plusSellReducer = (state: PlusSellState = initialState, action: PlusSellActions) => {
    switch (action.type) {
        case "APP/SET_PLUSSELL_SESSIONS": {
            return {
                ...state,
                plusSellSessions: {
                    ...state.plusSellSessions,
                    [action.webshopId]: [...(state.plusSellSessions[action.webshopId] ?? []), ...action.sessions],
                },
                plusSellSessionsContinuationTokens: {
                    ...state.plusSellSessionsContinuationTokens,
                    [action.webshopId]: action.continuationToken,
                },
            }
        }
        case "APP/SET_PLUSSELL_AGGREGATED_REPORT": {
            return {
                ...state,
                plusSellAggregatedReports: {
                    ...state.plusSellAggregatedReports,
                    [action.webshopId]: action.report,
                },
            }
        }
        default: {
            return state
        }
    }
}

export const plusSellSelectors = {
    plusSellSessions: (state: StoreState, webshopId: string) => state.plusSell.plusSellSessions?.[webshopId],
    plusSellSessionsContinuationToken: (state: StoreState, webshopId: string) =>
        state.plusSell.plusSellSessionsContinuationTokens?.[webshopId],
    plusSellAggregatedReport: (state: StoreState, webshopId: string) => state.plusSell.plusSellAggregatedReports?.[webshopId],
}
