import React, { useEffect, useMemo, useState } from "react"
import { StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import { termsLanguages } from "../../../data/termsLanguages"
import termsTemplateTypeSpecs from "../../../data/termsTemplateTypeSpecs"
import { StoreState } from "../../../store/StoreState"
import { invoicingSelectors } from "../../../store/reducers/invoicingReducer"
import { userRelationSelectors } from "../../../store/reducers/userRelationReducer"
import { getInvoicingPricesThunk } from "../../../store/thunks/invoicingThunks"
import { addModalThunk } from "../../../store/thunks/modalThunks"
import { IGetTermsModel, getTermsContentTypeThunk, getTermsThunk, getTermsUrlThunk } from "../../../store/thunks/termsThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import { ELegalRelationType, ETermsType } from "../../../type/terms/ETermsType"
import { getDistinct, sortAscending, toDict } from "../../../util/arrayUtil"
import { getShortVersion } from "../../../util/versionUtil"
import SpinnerContainer from "../../buttons/SpinnerContainer"
import TemplateDataExample from "../../template/TemplateDataExample"
import DropDownList from "../input/DropDownList"
import ToggleButtonCheckbox from "../input/ToggleButtonCheckbox"
import "./TermsExampleForm.scss"

interface ITermsExampleFormProps {
    version: string
    revision: number
    termsType: ETermsType
}

const TermsExampleForm = ({ version, revision, termsType }: ITermsExampleFormProps) => {
    const dispatch = useReduxDispatch()
    const [legalRelation, setLegalRelation] = useState<{ id: string; type: ELegalRelationType }>({ id: "", type: "Merchant" })
    const [language, setLanguage] = useState("en")
    const [showPriceTypes, setShowPriceTypes] = useState(false)
    const relations = useSelector(userRelationSelectors.userRelationsFlattened) ?? []
    const merchants = useMemo(
        () =>
            sortAscending(
                getDistinct(
                    relations.filter((r) => r.relationType === "Merchant" && r.name),
                    (m) => m.id
                ),
                (m) => m.name?.trim().toLowerCase()
            ),
        [relations]
    )
    const termsTemplateType = termsTemplateTypeSpecs[termsType]
    const prices = useSelector((state: StoreState) => invoicingSelectors.pricesForRelation(state, legalRelation.id))
    const resolvedPrices = useSelector((state: StoreState) =>
        invoicingSelectors.resolvedPricesForRelation(state, legalRelation.id)
    )

    useEffect(() => {
        if (legalRelation?.id) {
            dispatch(getInvoicingPricesThunk(legalRelation.id, legalRelation.type))
        }
    }, [legalRelation])

    useEffect(() => {
        const lastSelectedtermsRelation = localStorage.getItem("lastSelectedTermsRelation")
        if (lastSelectedtermsRelation) {
            const merchant = merchants.find((m) => m.id === lastSelectedtermsRelation)
            if (merchant) {
                setLegalRelation({
                    id: merchant.id,
                    type: "Merchant",
                })
            }
        }
    }, [merchants])

    return (
        <div className="terms-example-form">
            <div className="terms-example-form__inner-container"></div>
            <DropDownList
                label="Merchants"
                className="terms-example-form__terms-relation-ddl"
                value={legalRelation?.id}
                onChange={(e) => {
                    const value = e.target.value
                    const merchant = merchants.find((m) => m.id === value)
                    if (merchant) {
                        localStorage.setItem("lastSelectedTermsRelation", merchant.id)
                        setLegalRelation({ id: merchant.id, type: "Merchant" })
                    }
                }}
                options={[
                    { text: "Choose merchant", value: "" },
                    ...merchants.map((x) => ({
                        text: x.name,
                        value: x.id,
                    })),
                ]}
            />
            <DropDownList
                label="Language"
                containerClassName="language"
                value={language}
                onChange={(v) => {
                    setLanguage(v.target.value)
                }}
                options={termsLanguages.map((x) => ({ text: x.label, value: x.code }))}
            />
            <StandardButtonWithSpinner
                isSmall
                disabled={!legalRelation.id && termsTemplateType.relationTypes.includes("Merchant")}
                containerClass="terms-example-form__save-container"
                title={`Get ${termsTemplateType.label} terms example`}
                onClick={async () => {
                    if (!legalRelation.id && termsTemplateType.relationTypes.includes("Merchant")) {
                        alert("For this template type, you must first select a merchant")
                        return
                    }

                    const model: IGetTermsModel = {
                        language: language,
                        revision,
                        version,
                        termsType,
                        relationId: legalRelation?.id,
                        relationType: legalRelation?.type,
                    }

                    const contentType = await dispatch(getTermsContentTypeThunk(model))
                    if (contentType === "text/html") {
                        const content = await dispatch(getTermsThunk(model, true))
                        if (!content) {
                            return
                        }
                        await dispatch(
                            addModalThunk({
                                type: "legal",
                                content: content,
                            })
                        )
                    } else {
                        const url = await dispatch(getTermsUrlThunk(model))
                        await dispatch(
                            addModalThunk({
                                type: "legal",
                                url: url,
                            })
                        )
                    }
                }}
            />
            <SpinnerContainer
                showSpinner={!!legalRelation.id && legalRelation.type === "Merchant" && !resolvedPrices}
                aboveContent
            >
                <ToggleButtonCheckbox
                    id="terms-example-form__show-price-types"
                    label={`Show also prices types (For all Swiipe Plans. ${
                        !legalRelation.id || legalRelation.type !== "Merchant" ? "Must select merchant first." : ""
                    })`}
                    checked={showPriceTypes}
                    onClick={async () => {
                        setShowPriceTypes(!showPriceTypes)
                    }}
                />
                <TemplateDataExample
                    data={{
                        VersionPrefix: "v.",
                        VersionPrefixFirstUpper: "V.",
                        Version: version,
                        ShortVersion: getShortVersion(version),
                        Revision: revision,
                        Currency: resolvedPrices?.find((p) => !!p.currency)?.currency,
                        Market: resolvedPrices?.find((p) => !!p.market)?.market,
                        ...termsTemplateTypeSpecs[termsType].exampleData,
                        ...toDict(
                            (resolvedPrices ?? []).filter((p) => p.isActive),
                            (p) => "PriceCat" + p.priceCategory,
                            (p) => p.amount
                        ),
                        ...(!showPriceTypes
                            ? {}
                            : toDict(
                                  prices ?? [],
                                  (p) => "PriceType" + p.priceType,
                                  (p) => p.amount
                              )),
                    }}
                />
            </SpinnerContainer>
        </div>
    )
}

export default TermsExampleForm
