import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { TSwiipePlan } from "../../type/merchant/TSwiipePlan"
import { StoreState } from "../StoreState"
import { MixedMerchantDetails } from "./../../type/merchant/mixedmerchantdetails"

export type MerchantState = Readonly<{
    merchantDetails: { [merchantId: string]: MixedMerchantDetails }
    swiipePlan: { [merchantId: string]: TSwiipePlan }
    selectedWebshops: { [merchantId: string]: string }
    createMerchantStatus: { [crn: string]: IMerchantCreateStatusResponse }
}>

export interface IMerchantCreateStatusResponse {
    isAlreadyCreated: boolean
    isOwnerConfirmed: boolean
    ownerEmail: string
    isDeleted: boolean
    isCoreServicesStopped: boolean
    isAllServicesStopped: boolean
    isMarkedForDeletion: boolean
}

const initialState: MerchantState = {
    merchantDetails: {},
    swiipePlan: {},
    selectedWebshops: {},
    createMerchantStatus: {},
}

export const merchantReducerActions = {
    setMerchantDetails: (merchantId: string, merchantDetails: MixedMerchantDetails) =>
        createAction("APP/SET_MERCHANGT_DETAILS", { merchantId, merchantDetails }),
    setSwiipePlan: (merchantId: string, swiipePlan: TSwiipePlan) =>
        createAction("APP/SET_SWIIPE_PLAN", { merchantId, swiipePlan }),
    setSelectedWebhop: (merchantId: string, webshopId: string) =>
        createAction("APP/SET_SELECTED_WEBSHOP_PER_MERCHANT", { merchantId, webshopId }),
    setCreateMerchantStatus: (crn: string, status: IMerchantCreateStatusResponse) =>
        createAction("APP/SET_CREATE_MERCHANT_STATUS", { crn, status }),
}

export type MerchantActions = ActionsUnion<typeof merchantReducerActions>

export const merchantReducer = (state: MerchantState = initialState, action: MerchantActions) => {
    switch (action.type) {
        case "APP/SET_MERCHANGT_DETAILS": {
            return {
                ...state,
                merchantDetails: {
                    ...state.merchantDetails,
                    [action.merchantId]: action.merchantDetails,
                },
            }
        }
        case "APP/SET_SWIIPE_PLAN": {
            return {
                ...state,
                swiipePlan: {
                    ...state.swiipePlan,
                    [action.merchantId]: action.swiipePlan,
                },
            }
        }
        case "APP/SET_SELECTED_WEBSHOP_PER_MERCHANT": {
            return {
                ...state,
                selectedWebshops: {
                    ...state.selectedWebshops,
                    [action.merchantId]: action.webshopId,
                },
            }
        }
        case "APP/SET_CREATE_MERCHANT_STATUS": {
            return {
                ...state,
                createMerchantStatus: {
                    ...state.createMerchantStatus,
                    [action.crn]: action.status,
                },
            }
        }
        default: {
            return state
        }
    }
    return state
}

export const merchantSelectors = {
    merchantDetails: (state: StoreState) =>
        state.userRelation.currentRelation ? state.merchant.merchantDetails[state.userRelation.currentRelation.id] : undefined,
    swiipePlan: (state: StoreState, swMerchantId?: string) => {
        if (swMerchantId) {
            return state.merchant.swiipePlan[swMerchantId]
        }
        return state.userRelation.currentRelation ? state.merchant.swiipePlan[state.userRelation.currentRelation.id] : undefined
    },
    selectedWebshopId: (state: StoreState) =>
        state.userRelation.currentRelation ? state.merchant.selectedWebshops[state.userRelation.currentRelation.id] : undefined,
    merchantCreateStatus: (state: StoreState, crn: string) => state.merchant.createMerchantStatus[crn],
}
