import React from "react"

interface PrefetchedDataSectionProps {
    merchantName: string
    url: string
    country: string
    mpName?: string
    mpLogo?: string
    clearhausName?: string
    clearhausMid?: string
    clearhausMcc?: string
    clearhausDescriptor?: string
    netsDankortId?: string
    netsForbrugsforeningenId?: string
    swishId?: string
    klarnaId?: string
    klarnaKey?: string
    vippsId?: string
}

const PrefetchedDataSection = (props: PrefetchedDataSectionProps) => {
    return (
        <div>
            <h4>Pre-fetched Data</h4>
            {!props.clearhausMid && (
                <p className="warning-text">
                    <b>ERROR:</b> Unable to find clearhaus merchant with the given clearhaus API key
                </p>
            )}
            {props.clearhausName && !props.clearhausName?.toLowerCase().includes(props.merchantName.toLocaleLowerCase()) && (
                <p className="warning-text">
                    <b>Warning:</b> Fetched clearhaus name &quot;<b>{props.clearhausName}</b>&quot; differs from stored merchant
                    name &quot;<b>{props.merchantName}</b>&quot;. Please ensure clearhaus API key is correct!
                </p>
            )}
            <PrefetchDataRow attributeName="Merchant name" value={props.clearhausName ?? props.merchantName} />
            <PrefetchDataRow attributeName="Url" value={props.url} />
            <PrefetchDataRow attributeName="Clearhaus MID" value={props.clearhausMid} />
            <PrefetchDataRow attributeName="Clearhaus MCC" value={props.clearhausMcc} />
            <PrefetchDataRow attributeName="Clearhaus Descriptor" value={props.clearhausDescriptor} />
            <PrefetchDataRow attributeName="Mobile Pay Name" value={props.mpName} />
            <PrefetchDataRow attributeName="Mobile Pay LogoUrl" value={props.mpLogo} />
            <PrefetchDataRow attributeName="Mobile Pay Country" value={props.country} />
            <PrefetchDataRow attributeName="Swish onboarding" value={props.swishId ? `Yes - ${props.swishId}` : "No"} />
            <PrefetchDataRow
                attributeName="Klarna onboarding"
                value={props.klarnaId && props.klarnaKey ? `Yes - ${props.klarnaId} - ${props.klarnaKey}` : "No"}
            />
            <PrefetchDataRow attributeName="Vipps onboarding" value={props.vippsId ? `Yes - ${props.vippsId}` : "No"} />
            <PrefetchDataRow
                attributeName="Dankort onboarding"
                value={props.netsDankortId ? `Yes - ${props.netsDankortId}` : "No"}
            />
            <PrefetchDataRow
                attributeName="Forbrugsforeningen onboarding"
                value={props.netsForbrugsforeningenId ? `Yes - ${props.netsForbrugsforeningenId}` : "No"}
            />
        </div>
    )
}

const PrefetchDataRow = (props: { attributeName: string; value: string | undefined }) => {
    return (
        <p>
            <b>{props.attributeName}:</b> {props.value ?? ""}{" "}
        </p>
    )
}

export default PrefetchedDataSection
