import cn from "classnames"
import React, { useState } from "react"
import InfoBoldBlue from "../../images/icons/info/info-icon-blue.png"
import InfoLightBlue from "../../images/icons/info/info-light-blue.png"
import InfoLightGray from "../../images/icons/info/info-light-gray.png"
import "./TitleWithFoldableHint.scss"

type TInfoIcon = "lightBlue" | "lightGray" | "boldBlue"
interface ITitleWithFoldableHint {
    title: string
    titleFontSize?: "14" | "16" | "20"
    titleTextAlign?: "left" | "center"

    hint: string | JSX.Element
    hintFontSize?: "14" | "15" | "16"
    hintFontWeight?: "300" | "400"

    icon?: TInfoIcon
    theme?: "blue" | "grey" | "white"

    className?: string
    titleClassName?: string
}

export const TitleWithFoldableHint = ({
    title,
    titleFontSize = "14",
    titleTextAlign = "left",
    hint,
    hintFontSize = "14",
    hintFontWeight = "400",
    icon = "lightBlue",
    theme = "blue",
    className,
    titleClassName,
}: ITitleWithFoldableHint) => {
    const [showHint, setShowHint] = useState(false)

    const getInfoIcon = (icon: TInfoIcon) => {
        switch (icon) {
            case "lightBlue":
                return InfoLightBlue
            case "lightGray":
                return InfoLightGray
            case "boldBlue":
                return InfoBoldBlue
        }
    }

    const Hint = (props: { hint: string | JSX.Element; showHint?: boolean }) => {
        const showHint = props.showHint
        const hint = props.hint
        const classNames = [
            "foldable-hint__container",
            `foldable-hint__container--font-size-${hintFontSize}`,
            `foldable-hint__container--font-weight-${hintFontWeight}`,
            showHint && "foldable-hint__container--expanded",
        ]
        if (typeof hint === "string") {
            return <div className={cn(...classNames)} dangerouslySetInnerHTML={{ __html: hint }} />
        }
        return <div className={cn(...classNames)}>{hint}</div>
    }

    return (
        <div
            className={cn(
                "title-with-foldable-hint",
                hint && "title-with-foldable-hint--has-hint",
                `title-with-foldable-hint--theme-${theme}`,
                className
            )}
        >
            <div
                className={cn(
                    "foldable-hint__title-container",
                    `foldable-hint__title-container--text-align-${titleTextAlign}`,
                    showHint && "foldable-hint__title-container--show"
                )}
                onClick={() => {
                    if (hint) {
                        setShowHint(!showHint)
                    }
                }}
            >
                <span
                    className={cn("foldable-hint__title", `foldable-hint__title--font-size-${titleFontSize}`, titleClassName)}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {hint && (
                    <span className={cn("foldable-hint__icon", { "foldable-hint__icon--hidden_arrow": !showHint })}>
                        <img src={getInfoIcon(icon)} />
                    </span>
                )}
            </div>
            <Hint hint={hint} showHint={showHint} />
        </div>
    )
}
