import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { deepMerge } from "swiipe.portal.shared"

export const initializeLocales = (language: string | undefined) => {
    const shared = {
        en: {
            translation: require("swiipe.portal.shared/src/locale/lang/en.json"),
        },
    }
    const own = {
        en: {
            translation: require("./lang/en.json"),
        },
    }
    i18next
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: deepMerge({}, shared, own),
            // We hardcode the language to da until we are ready with the english translations
            lng: language ?? navigator.language.split("-")[0],
            fallbackLng: "en",

            interpolation: {
                escapeValue: false,
            },
        })
}
