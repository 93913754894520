export function calculateVersionValue(version: string, revision: number | string): number {
    const numberRevision = typeof revision === "string" ? parseInt(revision) : revision
    if (!version) {
        return numberRevision
    }
    const parts = version.split(".").slice(0, 3)
    let result = numberRevision
    result += parts.length > 0 ? parseInt(parts[0]) * 1000000 : 0
    result += parts.length > 1 ? parseInt(parts[1]) * 10000 : 0
    result += parts.length > 2 ? parseInt(parts[2]) * 100 : 0
    return result
}

export function extractVersionFromValue(value: number | string) {
    const numberValue = typeof value === "string" ? parseInt(value) : value
    const major = Math.floor(numberValue / 1000000)
    const minor = Math.floor((numberValue % 1000000) / 10000)
    const patch = Math.floor((numberValue % 10000) / 100)
    const revision = numberValue % 100

    return { version: `${major}.${minor}.${patch}`, revision: revision }
}

export function isValidNumberForVersion(value: number | string, options?: "require-positive"[]) {
    const numberValue = typeof value === "string" ? parseInt(value) : value
    if (options?.includes("require-positive") && numberValue < 1) {
        return false
    }
    return Math.round(numberValue) === numberValue && numberValue >= 0 && numberValue < 100
}

export function getShortVersion(version: string) {
    if (!version) {
        return version
    }
    const parts = version.split(".").slice(0, 3)
    if (parts.length < 3 || parts[2].trim() === "0") {
        return parts[0] + "." + parts[1]
    }
    return version
}
