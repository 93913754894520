import cn from "classnames"
import React, { useState } from "react"
import { FormState } from "react-hook-form"
import { CheckmarkCircle } from "../icons/CheckmarkCircle"
import { StandardBox } from "../layouts/StandardBox"
import "./FormFieldsSection.scss"
import { ShowErrorMessages } from "./ShowErrorMessages"

interface IFormFieldsSection {
    header: string
    headerClassName?: string
    headerFont?: "normal" | "smaller"
    fieldsIndentLeft?: "normal" | "smaller"
    children: React.ReactNode
    validatedFields?: string[]
    showValidationCheckmark?: boolean
    formState?: FormState<any>
    showErrors?: boolean
    folded?: boolean
    className?: string
}

export const FormFieldsSection = ({
    header,
    headerClassName,
    headerFont = "normal",
    fieldsIndentLeft = "normal",
    children,
    validatedFields,
    showValidationCheckmark,
    formState,
    showErrors,
    folded,
    className,
}: IFormFieldsSection) => {
    const [fieldsAreValid, setFieldsAreValid] = useState(!validatedFields)
    return (
        <StandardBox
            className={cn("form-field-section mt-2", folded && "form-field-section__folded", className)}
            padding="35-20"
            margin="0"
            borders="rounded"
        >
            <div
                className={cn(
                    "form-field-section__header",
                    headerFont && `form-field-section__header--font-${headerFont}`,
                    headerClassName
                )}
            >
                {showValidationCheckmark && <CheckmarkCircle checked={fieldsAreValid} />}
                <span>{header}</span>
            </div>
            <div
                className={cn(
                    "form-field-section__form-fields",
                    fieldsIndentLeft && `form-field-section__form-fields--indent-${fieldsIndentLeft}`
                )}
            >
                <div className="indent" />
                <div className="fields">
                    {children}
                    {formState && validatedFields && (
                        <ShowErrorMessages
                            formState={formState}
                            className="form-field-section__form-errors mt-3"
                            isHidden={!showErrors}
                            onlyForFieldNames={validatedFields}
                            hasErrorsCallback={(hasErrors: boolean) => setFieldsAreValid(!hasErrors)}
                        />
                    )}
                </div>
            </div>
        </StandardBox>
    )
}
