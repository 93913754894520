import React, { useState } from "react"
import {
    ISearchedUser,
    PageContainer,
    PageWrapper,
    SearchBox,
    StandardButtonWithSpinner,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import PageTitle from "../../components/page/PageTitle"
import { deleteUserThunk, searchForUserThunk } from "../../store/thunks/userThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import "./DeleteUserPage.scss"

interface IDeleteUserPage {}

const DeleteUserPage = ({}: IDeleteUserPage) => {
    const dispatch = useReduxDispatch()
    const [searchValue, setSearchValue] = useState("")
    const searchedUsers = useSelector(userSelectors.searchedUsers)

    const users = !searchValue
        ? []
        : Object.values(searchedUsers || {}).filter((u) => u.email.toLowerCase().startsWith(searchValue.toLowerCase()))

    return (
        <PageWrapper wider>
            <PageContainer id="delete-user-page">
                <PageTitle title="Delete user" />
                <SearchBox
                    delayOnChangeMs={800}
                    currentValue={searchValue}
                    placeholder="Email"
                    onValueChange={(val) => {
                        if (val.length === 1) {
                            return
                        }
                        setSearchValue(val)
                        dispatch(searchForUserThunk(val))
                    }}
                />
                <SpinnerContainer showSpinner={!searchedUsers && !!searchValue}>
                    <div className="users">
                        {users.map((u) => (
                            <DeleteUserRow key={u.userId} user={u} />
                        ))}
                    </div>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

interface IDeleteUserRow {
    user: ISearchedUser
}

const DeleteUserRow = ({ user }: IDeleteUserRow) => {
    const dispatch = useReduxDispatch()
    return (
        <div className="user">
            <div className="email">{user.email}</div>
            <div className="name">{user.fullName}</div>
            <StandardButtonWithSpinner
                onClick={async () => {
                    await dispatch(deleteUserThunk(user.userId))
                }}
            >
                Delete user
            </StandardButtonWithSpinner>
        </div>
    )
}

export default DeleteUserPage
