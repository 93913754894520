import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FloatingLabelInput, useSelector, userSelectors } from "swiipe.portal.shared"
import { addModalThunk } from "swiipe.portal.shared/src/thunks/modalThunks"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import SubmitButton from "../../components/buttons/SubmitButton"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import MerchantCustomPricesForm from "../../components/form/billing/MerchantCustomPricesForm"
import DateInput from "../../components/form/input/DateInput"
import FormGroupWithCheckbox from "../../components/form/input/FormGroupWithCheckbox"
import PageSection from "../../components/page/PageSection"
import { getPricesForSwiipePlan } from "../../services/invoicingService"
import {
    IMerchantCustomPricesSubForm,
    getPriceFormErrors,
    preparePriceFormForUpdate,
} from "../../services/merchantCustomPricesService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { invoicingSelectors } from "../../store/reducers/invoicingReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import {
    fetchInvoicingOrganizationThunk,
    getInvoicingMerchantConfigThunk,
    setInvoicingMerchantConfigThunk,
    updateInvoicingOrganizationThunk,
} from "../../store/thunks/invoicingThunks"
import { updateMerchantDetails } from "../../store/thunks/merchantThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { addYears, formatLocalDate, setTime } from "../../util/dateUtil"
import { valFuncPattern, validationPatterns } from "../../util/validationUtil"
import "./ManageBillingForMerchantPage.scss"

interface IForm {
    prices: IMerchantCustomPricesSubForm
    invoiceRequireDraftFlow: boolean
    isNotInvoiceable: boolean
    saleClaimedBy: string
    partnerId: string
    useOldPrices: boolean
    trialExpiration: string
}

interface IManageBillingForMerchantProps {}

const ManageBillingForMerchantPage = ({}: IManageBillingForMerchantProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, formState, reset, errors, control } = useForm<IForm>()

    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const currenUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const [customPricesSubFormReset, setCustomPricesSubFormReset] = useState<{
        [swMerchantId: string]: IMerchantCustomPricesSubForm
    }>({})
    const [showTrialExpirationDatePicker, setShowTrialExpirationDatePicker] = useState(false)

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const userData = useSelector(userSelectors.userData)
    const prices = useSelector((state: StoreState) => invoicingSelectors.prices(state, currenUserRelation!.id))
    const market = prices && prices.length > 0 ? prices[0].market : "Denmark"

    const invoicingMerchantConfig = useSelector((state: StoreState) =>
        invoicingSelectors.merchantConfig(state, currenUserRelation!.id)
    )

    const invoicingOrganization = useSelector(invoicingSelectors.invoicingOrganization)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const pricesForPlan = getPricesForSwiipePlan(prices, swiipePlan)

    useWatch<IForm>({
        control,
        name: "trialExpiration",
    })

    const form = control.getValues()

    useEffect(() => {
        if (!merchantDetails || !invoicingOrganization) {
            if (!invoicingOrganization) {
                dispatch(fetchInvoicingOrganizationThunk(false))
            }
            return
        }
        const currentForm = control.getValues()
        reset({
            ...currentForm,
            prices: customPricesSubFormReset[merchantDetails.swMerchant.swMerchantId] ?? currentForm.prices,
            trialExpiration: merchantDetails.trialExpiration,
            invoiceRequireDraftFlow: invoicingOrganization.invoiceRequireDraftFlow,
            isNotInvoiceable: invoicingOrganization.isNotInvoiceable,
            partnerId: invoicingOrganization.partnerId,
            saleClaimedBy: invoicingOrganization.saleClaimedBy,
        })
    }, [merchantDetails, invoicingOrganization, customPricesSubFormReset])

    useEffect(() => {
        dispatch(getInvoicingMerchantConfigThunk(currenUserRelation!.id, false))
    }, [currenUserRelation])

    if (!merchantDetails || !userData || !invoicingOrganization) {
        return null
    }

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            console.log("DDD", data)
            const priceErrors = getPriceFormErrors(pricesForPlan, data.prices)
            if (priceErrors.length > 0) {
                dispatch(
                    addModalThunk({
                        type: "error",
                        title: "Validation error",
                        errorMessage: priceErrors[0],
                    })
                )
                return
            }

            const preparedPriceData = preparePriceFormForUpdate(pricesForPlan, invoicingMerchantConfig!, data.prices)

            await dispatch(
                updateInvoicingOrganizationThunk({
                    invoiceRequireDraftFlow: data.invoiceRequireDraftFlow,
                    isNotInvoiceable: data.isNotInvoiceable,
                    saleClaimedBy: data.saleClaimedBy,
                    partnerId: data.partnerId,
                    useOldPrices: data.useOldPrices,
                })
            )
            await dispatch(
                setInvoicingMerchantConfigThunk(
                    merchantDetails.swMerchant.swMerchantId,
                    {
                        priceOverrides: preparedPriceData.priceOverrides,
                        customPrices: preparedPriceData.customPrices,
                    },
                    preparedPriceData.idsToRemove
                )
            )

            const trialExpiration = setTime(new Date(data.trialExpiration), new Date(merchantDetails.created))
            if (trialExpiration.getTime() !== new Date(merchantDetails.trialExpiration).getTime()) {
                await dispatch(updateMerchantDetails(merchantDetails.swMerchant.swMerchantId, trialExpiration))
            }
        } catch {
            // Ignore
        }
    }

    return (
        <div className="manage-merchant-invoicing-page">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <PageSection>
                    <div className="manage-merchant-invoicing-page__title-container">
                        <div className="manage-merchant-invoicing-page__title pr-4">Billing settings</div>
                        <SubmitButton
                            className="manage-merchant-invoicing-page__save-top"
                            dark
                            noBorder
                            isWide
                            formState={formState}
                        >
                            {t("managebilling.save")}
                        </SubmitButton>
                    </div>
                </PageSection>
                <PageSection title="General" indentContent>
                    <div className="manage-merchant-invoicing-page__data-row">
                        <div className="manage-merchant-invoicing-page__data-row-left">Merchant created:</div>
                        <div className="manage-merchant-invoicing-page__data-row-right">
                            {formatLocalDate(merchantDetails.created, "short")}
                        </div>
                    </div>
                    <div className="manage-merchant-invoicing-page__data-row">
                        <div className="manage-merchant-invoicing-page__data-row-left">Merchant trial expiry:</div>
                        <div className="manage-merchant-invoicing-page__data-row-right manage-merchant-invoicing-page__trial-expiration-outer">
                            <div className="manage-merchant-invoicing-page__trial-expiration-value">
                                {formatLocalDate(form.trialExpiration, "short")}{" "}
                            </div>
                            <div className="manage-merchant-invoicing-page__trial-expiration-container">
                                <div
                                    className="manage-merchant-invoicing-page__trial-expiration-edit"
                                    onClick={() => {
                                        setShowTrialExpirationDatePicker(!showTrialExpirationDatePicker)
                                    }}
                                >
                                    Edit
                                </div>
                                <DateInput
                                    classNameDatePicker={cn(
                                        "manage-merchant-invoicing-page__trial-expiration-date-picker",
                                        !showTrialExpirationDatePicker &&
                                            "manage-merchant-invoicing-page__trial-expiration-date-picker--hide"
                                    )}
                                    name="trialExpiration"
                                    formControl={control}
                                    inline
                                    placeholder="Merchant trial expiration"
                                    minDate={new Date(merchantDetails.created)}
                                    maxDate={addYears(new Date(merchantDetails.created), 5)}
                                    onChange={(newD) => {
                                        setShowTrialExpirationDatePicker(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="manage-merchant-invoicing-page__data-row">
                        <div className="manage-merchant-invoicing-page__data-row-left">Sale made by:</div>
                        <div className="manage-merchant-invoicing-page__data-row-right">
                            <FloatingLabelInput
                                smallBorderRadius
                                containerClass="manage-merchant-invoicing-page__data-row-input"
                                name="saleClaimedBy"
                                type="text"
                                placeholder="Sale claimed by (e.g ipo, ami)"
                                defaultValue={invoicingOrganization.saleClaimedBy}
                                innerRef={register(
                                    valFuncPattern(
                                        validationPatterns.saleClaimedBy,
                                        "Please enter sale claimed by in correct format (should take a first 3 letters of the swiipe email as an input)"
                                    )
                                )}
                            />
                        </div>
                    </div>
                    <div className="manage-merchant-invoicing-page__data-row">
                        <div className="manage-merchant-invoicing-page__data-row-left">Partner ID:</div>
                        <div className="manage-merchant-invoicing-page__data-row-right">
                            <FloatingLabelInput
                                smallBorderRadius
                                containerClass="manage-merchant-invoicing-page__data-row-input"
                                name="partnerId"
                                type="text"
                                placeholder="Partner Id"
                                defaultValue={invoicingOrganization.partnerId}
                                innerRef={register(
                                    valFuncPattern(
                                        validationPatterns.partnerId,
                                        "Please enter partner id in correct format (4 digit number)"
                                    )
                                )}
                            />
                        </div>
                    </div>
                </PageSection>
                <PageSection title="Invoices" indentContent>
                    <FormGroupWithCheckbox
                        defaultChecked={invoicingOrganization.invoiceRequireDraftFlow ?? false}
                        innerRef={register()}
                        name="invoiceRequireDraftFlow"
                        title="Require manual invoice approval before sending to customer"
                    />
                    <FormGroupWithCheckbox
                        defaultChecked={invoicingOrganization.isNotInvoiceable ?? false}
                        innerRef={register()}
                        name="isNotInvoiceable"
                        title="Do not create invoices for this merchant"
                    />
                </PageSection>
                <PageSection
                    title="Custom & temporary prices"
                    className="manage-merchant-invoicing-page__section-prices"
                    classNameTitle="manage-merchant-invoicing-page__section-prices-title"
                >
                    <SpinnerContainer showSpinner={!prices || !invoicingMerchantConfig}>
                        <ShowErrorMessages errors={errors} />

                        <MerchantCustomPricesForm<IForm>
                            control={control}
                            fieldSelectorPrefix="prices."
                            getFieldsFromControl={(form) => form?.prices}
                            market={market}
                            sectionDisplayType="allAsAccordians"
                            sectionIndentLeftSize={110}
                            sectionIndentRightSize={90}
                            reset={(subForm) => {
                                setCustomPricesSubFormReset({
                                    ...customPricesSubFormReset,
                                    [merchantDetails.swMerchant.swMerchantId]: subForm,
                                })
                            }}
                            isPreOnboarding={false}
                            invoicingMerchantConfig={invoicingMerchantConfig}
                            merchantOrPreOnboardingId={merchantDetails.swMerchant.swMerchantId}
                        />
                    </SpinnerContainer>
                </PageSection>
                <PageSection className="manage-merchant-invoicing-page__footer">
                    <div className="manage-merchant-invoicing-page__footer-container">
                        <SubmitButton
                            className="manage-merchant-invoicing-page__footer-container-save"
                            dark
                            noBorder
                            isWide
                            formState={formState}
                        >
                            {t("managebilling.save")}
                        </SubmitButton>
                    </div>
                </PageSection>
            </Form>
        </div>
    )
}

export default ManageBillingForMerchantPage
