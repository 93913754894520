import React, { useState } from "react"
import cn from "classnames"
import "./ToggleButtonCheckbox.scss"

interface IToggleButtonCheckbox {
    id: string
    label?: string
    onClick: () => Promise<void>
    checked: boolean
    className?: string
    isErrorColor?: boolean
}

const ToggleButtonCheckbox = ({ id, onClick, label, className, isErrorColor, ...otherProps }: IToggleButtonCheckbox) => {
    const [isSwitching, setIsSwitching] = useState(false)

    return (
        <div className={cn("toggle-btn-checkbox", className)}>
            {label && <span className="text-label">{label ?? ""}</span>}
            <input
                {...otherProps}
                type="checkbox"
                id={id}
                readOnly
                className={cn("switch", { switching: isSwitching }, isErrorColor && "error")}
            />
            <label
                onClick={async () => {
                    setIsSwitching(true)
                    await onClick()
                    setIsSwitching(false)
                }}
                htmlFor={id}
            ></label>
        </div>
    )
}

export default ToggleButtonCheckbox
