import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper, StandardButton, useSelector } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import EmailBaseTemplateLegend from "../../components/emailTemplate/EmailBaseTemplateLegend"
import EmailTemplateImageDisplay from "../../components/emailTemplate/EmailTemplateImageDisplay"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import DropDownList from "../../components/form/input/DropDownList"
import ToggleButtonCheckbox from "../../components/form/input/ToggleButtonCheckbox"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import PageTitle from "../../components/page/PageTitle"
import { languagesIncludeShared } from "../../data/languages"
import { getImagesWithBestLanguageFit } from "../../services/emailTemplateService"
import { StoreState } from "../../store/StoreState"
import { emailTemplateSelectors } from "../../store/reducers/emailTemplateReducer"
import { getEmailTemplateImagesThunk } from "../../store/thunks/emailTemplateImageThunks"
import { getEmailBaseTemplatesThunk, updateEmailBaseTemplateThunk } from "../../store/thunks/emailTemplateThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getDistinct } from "../../util/arrayUtil"
import { createGuid } from "../../util/stringUtil"
import { valFuncRequired } from "../../util/validationUtil"
import "./EmailBaseTemplatePage.scss"

interface IEmailBaseTemplatePage {}

interface IForm {
    name: string
}

const EmailBaseTemplatePage = ({}: IEmailBaseTemplatePage) => {
    const { handleSubmit, register, errors, formState, setValue } = useForm<IForm>()
    const dispatch = useReduxDispatch()
    const [showHelp, setShowHelp] = useState(false)
    const [showImages, setShowImages] = useState(false)
    const [body, setBody] = useState("")
    const [newTypeId, setNewTypeId] = useState(createGuid())
    const [dataInitialized, setDataInitialized] = useState(false)
    const [typeId, setTypeId] = useState(newTypeId)
    const [language, setLanguage] = useState(languagesIncludeShared[0].code)
    const [isDisabled, setIsDisabled] = useState(false)

    const emailBaseTemplate = useSelector((state: StoreState) =>
        emailTemplateSelectors.emailBaseTemplate(state, typeId, language)
    )

    const images = useSelector(emailTemplateSelectors.emailTemplateImages) ?? []
    const imagesBestFits = getImagesWithBestLanguageFit(images, language)

    const emailBaseTemplates = useSelector(emailTemplateSelectors.emailBaseTemplates)
    const typeIds = getDistinct(emailBaseTemplates ?? [], (t) => t.typeId).map((t) => t.typeId)

    useEffect(() => {
        dispatch(getEmailBaseTemplatesThunk(false))
        dispatch(getEmailTemplateImagesThunk(false))
    }, [])

    useEffect(() => {
        setBody(emailBaseTemplate?.body ?? "")
        setIsDisabled(emailBaseTemplate?.isDisabled ?? false)
        setValue("name", emailBaseTemplate?.name ?? "")
    }, [emailBaseTemplate])

    useEffect(() => {
        if (emailBaseTemplates && !dataInitialized) {
            setDataInitialized(true)
            if (emailBaseTemplates.length > 0) {
                setTypeId(emailBaseTemplates[0].typeId)
            }
        }
    }, [emailBaseTemplates])

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            await dispatch(
                updateEmailBaseTemplateThunk({
                    body,
                    typeId,
                    name: data.name,
                    language: language,
                    isDisabled,
                })
            )
            if (typeId === newTypeId) {
                const guid = createGuid()
                setNewTypeId(guid)
            }
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="email-base-template-page">
                <PageTitle title="Update Email Base Template" />
                <div className="ddl-container">
                    <DropDownList
                        label="Email Base Template Type"
                        containerClassName="email-base-template-type"
                        value={typeId}
                        onChange={(v) => setTypeId(v.target.value)}
                        options={[
                            ...typeIds
                                .map((id) => {
                                    const ts = emailBaseTemplates ?? []
                                    const languageFit = ts.find((t) => t.typeId === id && t.language === language)
                                    if (languageFit) {
                                        return languageFit
                                    }
                                    const sharedFit = ts.find((t) => t.typeId === id && t.language === "shared" && !t.isDisabled)
                                    if (sharedFit) {
                                        return sharedFit
                                    }
                                    const firstEnabled = ts.find((t) => t.typeId === id && !t.isDisabled)
                                    if (firstEnabled) {
                                        return firstEnabled
                                    }
                                    return ts.find((t) => t.typeId === id)!
                                })
                                .map((x) => ({
                                    text: x.name + (x.language === language ? "" : " (" + x.language + ")"),
                                    value: x.typeId,
                                }))
                                .filter((x) => x.value !== newTypeId),
                            { text: "Add New Base Template", value: newTypeId },
                        ]}
                    />
                    <DropDownList
                        label="Language"
                        containerClassName="language"
                        value={language}
                        onChange={(v) => setLanguage(v.target.value)}
                        options={languagesIncludeShared.map((x) => ({ text: x.label, value: x.code }))}
                    />
                </div>

                <label>Actions</label>
                <div className="actions">
                    <StandardButton
                        className="show-help"
                        isSmall
                        invertedBlue
                        title={showHelp ? "Hide help" : "Show help"}
                        onClick={() => setShowHelp(!showHelp)}
                    />
                    <StandardButton
                        isSmall
                        invertedBlue
                        title={showImages ? "Hide Images" : "Show Images"}
                        onClick={() => setShowImages(!showImages)}
                    />
                </div>

                {showHelp && <EmailBaseTemplateLegend />}
                {showImages && (
                    <div className="image-container">
                        {imagesBestFits.map((img, index) => (
                            <EmailTemplateImageDisplay key={index} image={img} language={language} />
                        ))}
                    </div>
                )}

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <div className="name-container">
                        <FloatingLabelInput
                            name="name"
                            defaultValue={emailBaseTemplate?.name ?? ""}
                            innerRef={register(valFuncRequired("Name is required"))}
                            placeholder="Base Template Name"
                        />
                        <SubmitButton formState={formState} isLarge containerClass="save-button">
                            Save Email Base Template
                        </SubmitButton>
                    </div>
                    <ShowErrorMessages<IForm> errors={errors} />
                </Form>

                <ToggleButtonCheckbox
                    className="set-is-disabled"
                    label="Is Disabled"
                    id="isDisabled"
                    isErrorColor
                    checked={isDisabled}
                    onClick={async () => {
                        setIsDisabled(!isDisabled)
                    }}
                />
                <CodeEditor code={body} label="Body" onChange={(code) => setBody(code)} language="html" minHeight={300} />
            </PageContainer>
        </PageWrapper>
    )
}

export default EmailBaseTemplatePage
