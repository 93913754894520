import React, { Suspense, useEffect } from "react"
import { NotFoundBoundary, Router, View } from "react-navi"
import { sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import MainLayout from "../components/layouts/MainLayout"
import NotFoundPage from "../pages/NotFoundPage"
import { navigationService } from "../services/navigationService"
import useReduxDispatch from "../store/useReduxDispatch"
import { initializeThunk } from "./../store/thunks/startupThunks"
import { INavigationContext } from "./INavigationContext"
import { mainRoutes } from "./mainRoutes"

interface IProps {}

const MainRouter = ({}: IProps) => {
    const dispatch = useReduxDispatch()

    const initialized = useSelector(sharedConfigurationSelectors.initialized)
    const env = useSelector(sharedConfigurationSelectors.environment)

    useEffect(() => {
        dispatch(initializeThunk())
    }, [])

    if (!initialized) {
        return null
    }

    const navigationContext: INavigationContext = {}
    const navigation = navigationService.setup(navigationContext, mainRoutes)
    return (
        <Router navigation={navigation} context={navigationContext}>
            <MainLayout>
                <NotFoundBoundary render={() => <NotFoundPage />}>
                    <Suspense fallback={null}>
                        <View />
                    </Suspense>
                </NotFoundBoundary>
            </MainLayout>
        </Router>
    )
}

export default MainRouter
