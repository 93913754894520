import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IReleaseNote } from "../../type/releaseNotes/releaseNote"
import { StoreState } from "../StoreState"
import { releaseNotesReducerActions, releaseNotesSelectors } from "../reducers/releaseNotesReducer"

export const fetchReleaseNotes =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentReleaseNotes = releaseNotesSelectors.releaseNotes(getState())

        if (currentReleaseNotes && !force) {
            return
        }

        const releaseNotes = await dispatch(requestThunk<IReleaseNote[]>(endpoints.Content.getReleaseNotes))

        dispatch(releaseNotesReducerActions.setReleaseNotes(releaseNotes))
    }

export const addReleaseNote =
    (releaseNote: IReleaseNote): ThunkAction<Promise<string>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const response = await dispatch(
            requestThunk<{ crossLanguageId: string }>(endpoints.Content.addReleaseNote, {
                data: releaseNote,
            })
        )

        dispatch(fetchReleaseNotes(true))

        return response.crossLanguageId
    }

export const editReleaseNote =
    (releaseNote: IReleaseNote, releaseNoteId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<any>(endpoints.Content.editReleaseNote(releaseNoteId), {
                data: {
                    ...releaseNote,
                },
            })
        )

        dispatch(fetchReleaseNotes(true))
    }

export const deleteReleaseNote =
    (releaseNoteId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(requestThunk<any>(endpoints.Content.deleteReleaseNote(releaseNoteId)))

        dispatch(fetchReleaseNotes(true))
    }
