import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, StandardButton, StandardButtonWithSpinner } from "swiipe.portal.shared"
import { addCredentialsThunk, deleteCredentialsThunk } from "../../store/thunks/credentialsThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { Credentials } from "../../type/merchant/Credentials"
import { valFuncRequired } from "../../util/validationUtil"
import SubmitButton from "../buttons/SubmitButton"
import FieldsetFormGroup from "../form/FieldsetFormGroup"
import ShowErrorMessages from "../form/ShowErrorMessages"
import "./CredentialsForm.scss"

interface ICredentialsFormProps {
    merchantId: string
    webshopId: string
    credentialsId?: string
    currentCredentials?: Credentials
    onCancel?: () => void
}

export interface CredentialsFormModel {
    url: string
    admin: string
    password: string
    notes: string
}

const CredentialsForm = ({ merchantId, webshopId, credentialsId, currentCredentials, onCancel }: ICredentialsFormProps) => {
    const { handleSubmit, register, setValue, errors, formState } = useForm<CredentialsFormModel>()
    const dispatch = useReduxDispatch()

    useEffect(() => {
        if (currentCredentials) {
            setValue("url", currentCredentials.url)
            setValue("admin", currentCredentials.admin)
            setValue("password", currentCredentials.password)
            setValue("notes", currentCredentials.notes)
        }
    }, [currentCredentials])

    const onSubmit: SubmitHandler<CredentialsFormModel> = async (data) => {
        try {
            await dispatch(addCredentialsThunk(merchantId, webshopId, data, credentialsId))

            if (onCancel) {
                onCancel()
            }
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <div className="credentials-form-container">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FieldsetFormGroup field>
                    <FloatingLabelInput name="url" innerRef={register(valFuncRequired("Please enter url"))} placeholder="Url" />
                </FieldsetFormGroup>
                <FieldsetFormGroup field>
                    <FloatingLabelInput
                        name="admin"
                        innerRef={register(valFuncRequired("Please enter admin field"))}
                        placeholder="Admin"
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field>
                    <FloatingLabelInput
                        name="password"
                        innerRef={register(valFuncRequired("Please enter password field"))}
                        placeholder="Password"
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field>
                    <FloatingLabelInput name="notes" type="textarea" innerRef={register} placeholder="Notes" />
                </FieldsetFormGroup>
                <FieldsetFormGroup className="form-group-login-btn">
                    <div className="buttons-container row justify-content-end">
                        {!currentCredentials ? (
                            <div className="col-3">
                                <StandardButton
                                    inverted
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onCancel!()
                                    }}
                                >
                                    Cancel
                                </StandardButton>
                            </div>
                        ) : (
                            <div className="col-9">
                                <StandardButtonWithSpinner
                                    inverted
                                    isDelete
                                    noBorderRadius
                                    className="delete-btn"
                                    onClick={async (showSpinner, e) => {
                                        e.preventDefault()

                                        const modalResponse = await dispatch(
                                            addModalThunk({
                                                type: "dialog",
                                                title: "Are you sure you want to delete these login details?",
                                                message: "Once deleted, this information cannot be restored.",

                                                cancelBtn: {
                                                    text: "No, go back",
                                                },
                                                confirmBtn: {
                                                    text: "Yes, delete",
                                                    isDelete: true,
                                                    isInvertedColor: true,
                                                },
                                            })
                                        )

                                        if (modalResponse.type == "accepted") {
                                            await dispatch(deleteCredentialsThunk(merchantId, webshopId, credentialsId ?? ""))
                                        }
                                    }}
                                >
                                    Delete
                                </StandardButtonWithSpinner>
                            </div>
                        )}
                        <div className="pl-0 col-3">
                            <SubmitButton dark noBorder noBorderRadius formState={formState}>
                                Save changes
                            </SubmitButton>
                        </div>
                    </div>
                </FieldsetFormGroup>
                <ShowErrorMessages<CredentialsFormModel> errors={errors} />
            </Form>
        </div>
    )
}

export default CredentialsForm
