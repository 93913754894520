import React from "react"
import "./UploadedFilesOverview.scss"

interface IUploadedFilesOverviewProps {
    files: FileList
    onRemoveFile?: (fileIndex: number) => void
}

const UploadedFilesOverview = ({ files, onRemoveFile }: IUploadedFilesOverviewProps) => {
    return (
        <div className="swiipe-files-list">
            {Object.keys(files).map((key) => {
                return (
                    <div className="file-option" key={key}>
                        <span className="file">{files[key].name}</span>
                        {onRemoveFile && (
                            <div
                                className="delete-file-btn"
                                onClick={() => {
                                    onRemoveFile(parseInt(key))
                                }}
                            >
                                ×
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default UploadedFilesOverview
