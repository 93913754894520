import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { RequestConfig } from "../thunks/serverThunks"
import { THttpMethod } from "../types/request/THttpMethod"
import { parseServerError } from "./errorService"

export async function serverServiceExecuteRequest<T>(
    method: THttpMethod,
    url: string,
    config?: AxiosRequestConfig,
    requestConfig?: RequestConfig
): Promise<T> {
    const conf = config || {}
    try {
        let headers: any = conf.headers
        const contentType = conf.data instanceof FormData ? "multipart/form-data" : "application/json"
        switch (method) {
            case "get":
                break
            case "post":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "put":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "delete":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            case "patch":
                headers = {
                    ...conf.headers,
                    "Content-Type": contentType,
                }
                break
            default:
                throw new Error("HttpMethod not supported: " + method)
        }

        const response: AxiosResponse<T> = await axios({
            ...conf,
            method,
            headers,
            url,
            paramsSerializer: { indexes: null },
        })
        if (!response) {
            throw new Error("No response")
        }

        const responseData = response.data as T
        return responseData
    } catch (error) {
        console.log("Request failed:", url, conf, error)
        const serverError = parseServerError(error, requestConfig ? requestConfig.defaultErrorMessage : undefined)
        throw serverError
    }
}
