import React, { useEffect, useState } from "react"
import { PageContainer, PageWrapper, StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import DropDownList from "../../components/form/input/DropDownList"
import FormGroupWithCheckbox from "../../components/form/input/FormGroupWithCheckbox"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import SessionEventsTable from "../../components/session/sessionEventsTable/SessionEventsTable"
import SessionReport from "../../components/session/sessionReport/SessionReport"
import { getPlusSellSessionStartTime } from "../../services/plusSellService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { plusSellSelectors } from "../../store/reducers/plusSellReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { ensureAggregatedPlusSellReportThunk, fetchPlusSellSessionsThunk } from "../../store/thunks/plusSellThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { IPlusSellSession } from "../../type/plusSell/IPlusSellSession"
import { sortDescending } from "../../util/arrayUtil"
import { formatDate } from "../../util/dateUtil"
import { getTimeBetween } from "../../util/timeUtil"
import "./PlusSellSessionsPage.scss"

interface PlusSellSessionsPageProps {}

const PlusSellSessionsPage = ({}: PlusSellSessionsPageProps) => {
    const dispatch = useReduxDispatch()
    const [currentSession, setCurrentSession] = useState<IPlusSellSession | undefined>(undefined)
    const [showNonPlusSellEvents, setShowNonPlusSellEvents] = useState(true)
    const [showCodeEvents, setShowCodeEvents] = useState(true)
    const [showContextEvents, setShowContextEvents] = useState(true)
    const [showActionEvents, setShowActionEvents] = useState(true)

    const swMerchant = useSelector(merchantSelectors.merchantDetails)
    const initialSelectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const [selectedWebshopId, setSelectedWebshopId] = useState(
        initialSelectedWebshopId || swMerchant?.webshops[0].webshopId || ""
    )

    const userRelation = useSelector(userRelationSelectors.currentUserRelation)
    const aggregatedReport = useSelector((state: StoreState) =>
        plusSellSelectors.plusSellAggregatedReport(state, selectedWebshopId)
    )
    const sessions = useSelector((state: StoreState) => plusSellSelectors.plusSellSessions(state, selectedWebshopId))
    const continuationToken = useSelector((state: StoreState) =>
        plusSellSelectors.plusSellSessionsContinuationToken(state, selectedWebshopId)
    )
    const preparedSessions = sortDescending(sessions ?? [], (s) => getPlusSellSessionStartTime(s))

    useEffect(() => {
        setCurrentSession(preparedSessions?.length ? preparedSessions[0] : undefined)
        if (!selectedWebshopId) {
            return
        }
        dispatch(fetchPlusSellSessionsThunk(selectedWebshopId))
        dispatch(ensureAggregatedPlusSellReportThunk(selectedWebshopId))
    }, [userRelation, selectedWebshopId])

    if (!swMerchant) return null

    return (
        <PageWrapper fullWidth>
            <PageContainer id="plus-sell-sessions">
                <WebshopSelect
                    className="plus-sell-sessions__webshop-select"
                    merchantDetails={swMerchant}
                    onWebshopChange={(selectedWebshopId: string) => {
                        setSelectedWebshopId(selectedWebshopId)
                    }}
                    pageWrapperFullWidth
                    renderAsPage={false}
                />
                {aggregatedReport && (
                    <SessionReport
                        reportRows={[
                            {
                                name: "Plus-sell starts",
                                value: aggregatedReport?.plusSellStarts,
                            },
                            {
                                name: "With any plus-sell event (except start)",
                                value: aggregatedReport?.relevantSessions,
                            },
                            {
                                name: "Add product clicks",
                                value: aggregatedReport?.addProductClicks,
                            },
                            {
                                name: "Show confirmation modal",
                                value: aggregatedReport?.showConfModals,
                            },
                            {
                                name: "Place order",
                                value: aggregatedReport?.placePlusSellOrders,
                            },
                            {
                                name: "Without any recommendations",
                                value: aggregatedReport?.noRecommendationsSessions,
                            },
                        ]}
                    />
                )}
                {(!sessions || !preparedSessions) && (
                    <SpinnerContainer showSpinner>
                        <div className="plus-sell-sessions__spinner"></div>
                    </SpinnerContainer>
                )}
                {sessions && preparedSessions && preparedSessions.length > 0 && (
                    <>
                        <div className="plus-sell-sessions__fetch-more">
                            <span>
                                Showing sessions for period:
                                <br />
                                {formatDate(
                                    getPlusSellSessionStartTime(preparedSessions[preparedSessions.length - 1]),
                                    "slashes"
                                )}{" "}
                                - {formatDate(getPlusSellSessionStartTime(preparedSessions[0]), "slashes")}
                            </span>
                            <StandardButtonWithSpinner
                                title="Fetch more"
                                onClick={async () => {
                                    await dispatch(fetchPlusSellSessionsThunk(selectedWebshopId, true))
                                }}
                                isSmall
                                invertedBlue
                                disabled={!continuationToken}
                            />
                        </div>
                        <DropDownList
                            className="plus-sell-sessions__sessions-dropdown"
                            onChange={(el) => {
                                setCurrentSession(preparedSessions.find((s) => s.sessionId === el.target.value))
                            }}
                            options={[
                                ...(currentSession ? [] : [{ text: "Select session", value: "" }]),
                                ...preparedSessions.map((s) => ({
                                    text: `${formatDate(getPlusSellSessionStartTime(s), "slashes")} 　 Actions: ${
                                        (s.relevantActions + "").length === 1 ? "  " + s.relevantActions : s.relevantActions
                                    } 　　　 Session: ${s.sessionId.substring(0, 6)}`,
                                    value: s.sessionId,
                                })),
                            ]}
                        />
                        {[
                            {
                                title: "Show events triggered by user",
                                checked: showActionEvents,
                                onChange: () => setShowActionEvents(!showActionEvents),
                            },
                            {
                                title: "Show events triggered by code (non config)",
                                checked: showCodeEvents,
                                onChange: () => setShowCodeEvents(!showCodeEvents),
                            },
                            {
                                title: "Show contextual events",
                                checked: showContextEvents,
                                onChange: () => setShowContextEvents(!showContextEvents),
                            },
                            {
                                title: "Show non Plus-sell events",
                                checked: showNonPlusSellEvents,
                                onChange: () => setShowNonPlusSellEvents(!showNonPlusSellEvents),
                            },
                        ].map(({ title, checked, onChange }, index) => (
                            <FormGroupWithCheckbox
                                key={`session-filter-${index}`}
                                className="plus-sell-sessions__sessions-filter-checkbox"
                                title={title}
                                checked={checked}
                                onChange={onChange}
                            />
                        ))}
                    </>
                )}
                {!!currentSession && (
                    <SessionEventsTable
                        events={currentSession.parsedEvents.filter((e) => {
                            if (e.isPlusSellEvent) {
                                if (!e.category) {
                                    // For old sessions show all events disregarding category
                                    return true
                                }
                                if (
                                    (showCodeEvents && e.category === "Event") ||
                                    (showContextEvents && e.category === "Context") ||
                                    (showActionEvents && e.category === "Action")
                                ) {
                                    return true
                                }
                            } else if (showNonPlusSellEvents) {
                                return true
                            }
                            return false
                        })}
                        getBetweenEventInterval={(evt) =>
                            getTimeBetween(showNonPlusSellEvents ? evt.timePlusSellEventBefore : evt.timeEventBefore, evt.time)
                        }
                        conditionalLineStyle={(evt) => {
                            if (evt.isPlusSellStart || evt.type === "start") {
                                return ["--first", "--bolder-black"]
                            }
                            if (evt.type === "firstClick") {
                                return ["--italic", "--bolder-orange"]
                            }
                            if (evt.type.toLowerCase().endsWith("done")) {
                                return ["--italic", "--bolder-black"]
                            }
                            if (evt.type.toLowerCase().endsWith("failed")) {
                                return ["--bolder-red"]
                            }
                            if (evt.type.includes("add_product_start") || evt.type === "addProductStart") {
                                return ["--bolder-blue"]
                            }
                            if (evt.type.includes("place_order") || evt.type.includes("placeOrder")) {
                                return ["--bolder-green"]
                            }
                            if (evt.type.toLowerCase().endsWith("start")) {
                                return ["--bolder-black"]
                            }
                            return ["--regular"]
                        }}
                    />
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default PlusSellSessionsPage
