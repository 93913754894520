import cn from "classnames"
import React from "react"
import "./IconButton.scss"

interface IIconButtonProps {
    text: string
    onClick: () => void
    isSmall?: boolean
    icon: "add" | "close" | "none"
    className?: string
}

export const IconButton = ({ text, onClick, isSmall, icon, className }: IIconButtonProps) => {
    return (
        <div
            className={cn("icon-button", "icon-button--type-" + icon, isSmall && "icon-button--small", className)}
            onClick={onClick}
        >
            {icon !== "none" && <span className="icon-button__icon"></span>}
            {text && <span className="icon-button__label">{text}</span>}
        </div>
    )
}
