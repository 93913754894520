import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
    ECommentArea,
    IInternalComment,
    addInternalCommentThunk,
    commentSelectors,
    deleteInternalCommentModalThunk,
    ensureInternalCommentsThunk,
    getFormattedNumber,
    hasValue,
    updateInternalCommentThunk,
    useSelector,
} from "swiipe.portal.shared"
import DataTable from "../../../../components/dateTable/DataTable"
import { getLatestComment } from "../../../../services/commentService"
import { getSegment } from "../../../../services/revenueService"
import { StoreState } from "../../../../store/StoreState"
import { merchantPreOnboardingSelectors } from "../../../../store/reducers/merchantPreOnboardingSlice"
import { merchantSelectors } from "../../../../store/reducers/merchantReducer"
import { RevenueReport, revenueSelectors } from "../../../../store/reducers/revenueReducer"
import { trackedTimeSelectors } from "../../../../store/reducers/trackedTimeReducer"
import { userRelationSelectors } from "../../../../store/reducers/userRelationReducer"
import { webshopSelectors } from "../../../../store/reducers/webshopReducer"
import { getMerchantPreOnboardingDetailsThunk } from "../../../../store/thunks/merchantPreOnboardingThunks"
import { fetchPlusSellSessionsThunk } from "../../../../store/thunks/plusSellThunks"
import { getRevenueReportsThunk } from "../../../../store/thunks/revenueThunks"
import { ensureTrackedTimeThunk } from "../../../../store/thunks/trackedTimeThunks"
import { fetchSwiipePluginVersionsThunk } from "../../../../store/thunks/webshopThunks"
import useReduxDispatch from "../../../../store/useReduxDispatch"
import { IGetTrackedTimeDto } from "../../../../type/trackedTime/IGetTrackedTimeDto"
import { getFormattedDate } from "../../../../util/dateUtil"
import { secondsToZeroPaddedTime } from "../../../../util/timeUtil"
import DataRow from "../dataRow/DataRow"

interface BusinessInfoSectionProps {
    selectedWebshopId?: string
}

const BusinessInfoSection = ({ selectedWebshopId }: BusinessInfoSectionProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const swMerchant = useSelector(merchantSelectors.merchantDetails)

    const comments = useSelector((state: StoreState) =>
        commentSelectors.comments(state, swMerchant?.swMerchant.swMerchantId ?? "")
    )
    const agency = getLatestComment(comments, "AgencyInfo", selectedWebshopId, "AgencyInfo")
    const latestComment = getLatestComment(comments, "BusinessInfo", selectedWebshopId)
    const customPlatformVersion = getLatestComment(comments, "PlatformVersion", selectedWebshopId, "None")
    const customPluginVersion = getLatestComment(comments, "PluginVersion", selectedWebshopId, "None")

    const webshopSnapshot = useSelector((state: StoreState) => webshopSelectors.webshopSnapshot(state, selectedWebshopId ?? ""))
    const swiipePluginVersion = useSelector((state: StoreState) =>
        webshopSelectors.swiipePluginVersion(state, selectedWebshopId ?? "")
    )

    const preOnboardingDetails = useSelector((state: StoreState) =>
        merchantPreOnboardingSelectors.detailsBySwMerchantId(state, swMerchant?.swMerchant.swMerchantId ?? "")
    )

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const trackings = useSelector((state: StoreState) => trackedTimeSelectors.trackedTime(state, currentUserRelation?.id ?? ""))

    const webshopRevenueReport = useSelector((state: StoreState) =>
        revenueSelectors.webshopRevenue(state, swMerchant?.swMerchant.swMerchantId ?? "", selectedWebshopId ?? "")
    )
    const merchantRevenueReport = useSelector((state: StoreState) =>
        revenueSelectors.merchantRevenue(state, swMerchant?.swMerchant.swMerchantId ?? "")
    )
    const segmentConfig = useSelector((state: StoreState) => revenueSelectors.segmentConfig(state))

    useEffect(() => {
        if (!swMerchant) {
            return
        }
        dispatch(getMerchantPreOnboardingDetailsThunk({ swMerchantId: swMerchant.swMerchant.swMerchantId }))
        dispatch(ensureInternalCommentsThunk(swMerchant.swMerchant.swMerchantId, false))
        dispatch(getRevenueReportsThunk(swMerchant.swMerchant.swMerchantId))

        if (!selectedWebshopId) {
            return
        }
        dispatch(fetchPlusSellSessionsThunk(selectedWebshopId))
        dispatch(fetchSwiipePluginVersionsThunk(selectedWebshopId))
    }, [selectedWebshopId, swMerchant])

    useEffect(() => {
        dispatch(ensureTrackedTimeThunk(currentUserRelation?.id ?? "", false))
    }, [currentUserRelation])

    const currentWebshop = swMerchant?.webshops.find((shop) => shop.webshopId == selectedWebshopId)
    if (!swMerchant || !currentWebshop) {
        return null
    }

    const webshopTrackings = trackings?.filter((tr) => tr.webshopId === selectedWebshopId)
    const getAvgMonthlySupportTime = (trackings?: IGetTrackedTimeDto[]) => {
        if (!trackings || trackings.length === 0) {
            return 0
        }
        const distinctMonths = new Set(
            trackings?.map((tr) => {
                const date = new Date(tr.startTime)
                return `${date.getFullYear()}-${date.getMonth() + 1}` // +1 because getMonth() returns a zero-based value
            })
        )
        const totalSupportSeconds = trackings?.reduce((acc, tr) => acc + tr.seconds, 0) ?? 0
        return totalSupportSeconds / distinctMonths.size
    }

    const getRevenueReportColumn = (
        columnName: keyof RevenueReport,
        revenueReport?: RevenueReport,
        dataFormat?: "segment" | "date"
    ) => {
        const mapColumnData = () => {
            if (!revenueReport) {
                return "Loading..."
            }
            const data = revenueReport[columnName]
            if (data === null || data === undefined) {
                return "Unknown"
            }
            if (typeof data === "number") {
                if (dataFormat === "segment") {
                    return getSegment(data as number, segmentConfig)
                }
                return data.toLocaleString(undefined, {
                    currency: "DKK",
                    style: "currency",
                    maximumFractionDigits: 2,
                })
            }
            if (dataFormat === "date") {
                return getFormattedDate(data.toString(), "verbose-culture")
            }
            return data.toString()
        }
        return {
            isLoading: !revenueReport,
            columnData: mapColumnData(),
        }
    }

    const getDeleteCommentFunction = (comment?: IInternalComment) =>
        comment
            ? async () => {
                  await dispatch(
                      deleteInternalCommentModalThunk({
                          relationId: swMerchant.swMerchant.swMerchantId,
                          commentId: comment.commentId,
                      })
                  )
              }
            : undefined

    const getEditCommentFunction = (
        commentArea: ECommentArea,
        comment?: IInternalComment,
        returnUndefinedWhenValuePresent?: string
    ) =>
        returnUndefinedWhenValuePresent
            ? undefined
            : async (newCommentValue: string) => {
                  comment
                      ? await dispatch(
                            updateInternalCommentThunk({
                                comment: newCommentValue,
                                commentId: comment.commentId,
                                relationId: swMerchant.swMerchant.swMerchantId,
                            })
                        )
                      : await dispatch(
                            addInternalCommentThunk({
                                relationId: swMerchant.swMerchant.swMerchantId,
                                relationType: "SwMerchant",
                                comment: newCommentValue,
                                groupId: latestComment?.groupId,
                                webshopId: selectedWebshopId,
                                area: commentArea,
                            })
                        )
              }

    return (
        <section className="merchant-details-container">
            <h4>
                <b>{t("merchantDetails.businessInfoTitle")}</b>
            </h4>

            <DataRow
                attributeName={t("merchantDetails.businessInfo.agency")}
                value={agency?.comment}
                showEditButtonsInRow
                onEditCallback={getEditCommentFunction("AgencyInfo", agency)}
                onDeleteCallback={getDeleteCommentFunction(agency)}
                withoutCopyBtn
            />
            <DataRow
                attributeName={t("merchantDetails.businessInfo.platformVersion")}
                value={webshopSnapshot?.ecommerceVersion ?? customPlatformVersion?.comment}
                showEditButtonsInRow
                withoutCopyBtn
                additionalInfo={webshopSnapshot?.cmsVersion}
                onEditCallback={getEditCommentFunction(
                    "PlatformVersion",
                    customPlatformVersion,
                    webshopSnapshot?.ecommerceVersion
                )}
                onDeleteCallback={getDeleteCommentFunction(customPlatformVersion)}
            />
            <DataRow
                attributeName={t("merchantDetails.businessInfo.pluginVersion")}
                value={swiipePluginVersion ?? customPluginVersion?.comment}
                showEditButtonsInRow
                withoutCopyBtn
                onEditCallback={getEditCommentFunction("PluginVersion", customPluginVersion, swiipePluginVersion)}
                onDeleteCallback={getDeleteCommentFunction(customPluginVersion)}
            />

            <DataTable
                headers={["Webshop", "Merchant"]}
                rows={[
                    {
                        rowName: t("merchantDetails.businessInfo.segment"),
                        columns: [
                            getRevenueReportColumn("averageAnnualRevenue", webshopRevenueReport, "segment"),
                            getRevenueReportColumn("averageAnnualRevenue", merchantRevenueReport, "segment"),
                        ],
                    },
                    {
                        rowName: t("merchantDetails.businessInfo.avgAnnualRevenue"),
                        columns: [
                            getRevenueReportColumn("averageAnnualRevenue", webshopRevenueReport),
                            getRevenueReportColumn("averageAnnualRevenue", merchantRevenueReport),
                        ],
                    },
                    preOnboardingDetails?.preOnboarding.estimatedValue
                        ? {
                              rowName: "Est. annual revenue",
                              columns: [
                                  "",
                                  preOnboardingDetails?.preOnboarding.estimatedValueCurrency +
                                      " " +
                                      getFormattedNumber(preOnboardingDetails?.preOnboarding.estimatedValue),
                              ],
                          }
                        : undefined,
                    {
                        rowName: t("merchantDetails.businessInfo.avgMonthlyRevenue"),
                        columns: [
                            getRevenueReportColumn("averageMonthlyRevenue", webshopRevenueReport),
                            getRevenueReportColumn("averageMonthlyRevenue", merchantRevenueReport),
                        ],
                    },
                    {
                        rowName: t("merchantDetails.businessInfo.lastMonthRevenue"),
                        columns: [
                            getRevenueReportColumn("lastMonthRevenue", webshopRevenueReport),
                            getRevenueReportColumn("lastMonthRevenue", merchantRevenueReport),
                        ],
                    },
                    {
                        rowName: t("merchantDetails.businessInfo.avgMonthlySupportTime"),
                        columns: [
                            secondsToZeroPaddedTime(getAvgMonthlySupportTime(webshopTrackings)) + " /month",
                            secondsToZeroPaddedTime(getAvgMonthlySupportTime(trackings)) + " /month",
                        ],
                    },
                    {
                        rowName: t("merchantDetails.businessInfo.accountCreatedDate"),
                        columns: [
                            getFormattedDate(currentWebshop.created, "verbose-culture"),
                            getFormattedDate(swMerchant.swMerchant.created, "verbose-culture"),
                        ],
                    },
                    {
                        rowName: t("merchantDetails.businessInfo.firstTransactionDate"),
                        columns: [
                            getRevenueReportColumn("firstTransactionDate", webshopRevenueReport, "date"),
                            getRevenueReportColumn("firstTransactionDate", merchantRevenueReport, "date"),
                        ],
                    },
                ].filter(hasValue)}
            />
            <br />

            <DataTable
                hideDividers
                rows={[
                    ...(comments
                        ?.filter((c) => c.area === "BusinessInfo" && c.webshopId === selectedWebshopId)
                        .sort((a, b) => (a.created > b.created ? 1 : -1))
                        .map((c, i) => ({
                            rowId: c.commentId,
                            rowName: i === 0 ? "Comments: " : "",
                            columns: [
                                {
                                    columnData: (
                                        <DataRow
                                            value={c.comment}
                                            withoutCopyBtn
                                            onEditCallback={async (newCommentValue) => {
                                                await dispatch(
                                                    updateInternalCommentThunk({
                                                        commentId: c.commentId,
                                                        relationId: swMerchant.swMerchant.swMerchantId,
                                                        comment: newCommentValue,
                                                    })
                                                )
                                            }}
                                            editableTextArea
                                            onDeleteCallback={getDeleteCommentFunction(c)}
                                        />
                                    ),
                                },
                                getFormattedDate(c.created, "verbose-culture-with-time"),
                            ],
                        })) ?? []),
                    {
                        rowName: "",
                        columns: [
                            {
                                columnData: (
                                    <DataRow
                                        attributeName="Add new comment"
                                        withoutCopyBtn
                                        onEditCallback={async (comment) => {
                                            await dispatch(
                                                addInternalCommentThunk({
                                                    relationId: swMerchant.swMerchant.swMerchantId,
                                                    relationType: "SwMerchant",
                                                    comment,
                                                    groupId: latestComment?.groupId,
                                                    webshopId: selectedWebshopId,
                                                    area: "BusinessInfo",
                                                })
                                            )
                                        }}
                                        editableTextArea
                                    />
                                ),
                            },
                        ],
                    },
                ]}
            />
        </section>
    )
}

export default BusinessInfoSection
