export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export function waitForever(): Promise<void> {
    return new Promise(() => {})
}
