import React from "react"
import { useTranslation } from "react-i18next"
import { IStandardButtonProps, StandardButton } from "swiipe.portal.shared"

interface IDownloadTextButtonCompProps extends IStandardButtonProps {
    textToDownload: string | undefined
    fileName: string
    label?: string
}

const DownloadTextButtonComp = (props: IDownloadTextButtonCompProps) => {
    const { t } = useTranslation()

    const downloadText = (fileContents: string, fileName: string) => {
        const a = document.createElement("a")
        a.href = "data:text/plain;base64," + btoa(fileContents)
        a.download = fileName
        a.click()
        a.remove()
    }
    props = {
        ...props,
        onClick: () => {
            if (!props.textToDownload) {
                return
            }
            downloadText(props.textToDownload, props.fileName)
        },
    }
    return (
        <StandardButton {...props} disabled={!props.textToDownload}>
            {props.label ?? t("common.download")}
        </StandardButton>
    )
}

export default DownloadTextButtonComp
