import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import { ITimeTrackCounterState } from "../../services/timeTrackService"
import { IGetTrackedTimeDto } from "../../type/trackedTime/IGetTrackedTimeDto"
import { delay } from "../../util/timeUtil"
import TrackedTimeEdit from "../trackedTime/TrackedTimeEdit"
import "./ModalTrackTime.scss"

export interface IModalTrackTime extends IModalBase {
    type: "trackTime"
    updateExisting?: IGetTrackedTimeDto
    initWithState?: ITimeTrackCounterState
    organizationId: string
    selectedWebshopId?: string
}

const ModalTrackTime = ({
    title,
    updateExisting,
    initWithState,
    organizationId,
    callback,
    selectedWebshopId,
    ...otherProps
}: IModalTrackTime & IModalControlProps) => {
    const { t } = useTranslation()
    const [shouldTriggerSubmit, setShouldTriggerSubmit] = useState(false)
    const submittedRef = useRef(false)
    return (
        <BaseModal {...otherProps} modalClassName="modal-verify" callback={callback} centered title={title}>
            <ModalBody>
                <TrackedTimeEdit
                    initWithState={initWithState}
                    updateExisting={updateExisting}
                    organizationId={organizationId}
                    hideSubmitButton
                    triggerSubmit={shouldTriggerSubmit}
                    initialSelectedWebshopId={selectedWebshopId}
                    onSubmitted={() => {
                        setShouldTriggerSubmit(false)
                        submittedRef.current = true
                    }}
                />
            </ModalBody>
            <ModalFooter
                leftButton={{ onClick: async () => callback("declined"), text: "Cancel", isInvertedColor: true }}
                rightButton={{
                    onClick: async () => {
                        submittedRef.current = false
                        setShouldTriggerSubmit(true)
                        while (!submittedRef.current) {
                            await delay(500)
                        }
                        callback("accepted")
                    },
                    text: "Save",
                }}
            />
        </BaseModal>
    )
}

export default ModalTrackTime
