import React, { useEffect, useState } from "react"
import { PageContainer, PageWrapper, StandardButton, useSelector } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import FieldsetFormGroup from "../../components/form/FieldsetFormGroup"
import DropDownList from "../../components/form/input/DropDownList"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { adminApiTestThunk } from "../../store/thunks/webshopThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getKeys } from "../../util/objectUtils"
import { merchantSelectors } from "./../../store/reducers/merchantReducer"
import "./WebshopApiTesterPage.scss"

interface IWebshopApiTesterPageProps {}

interface ITemplate {
    function: string
    webshopId: string
    data: Record<string, any>
}

const templates = {
    blank: {
        function: "",
        webshopId: "{{webshopId}}",
        data: {},
    },
    orderDetails: {
        function: "getOrderDetails",
        webshopId: "{{webshopId}}",
        data: {
            orderId: "1234",
        },
    },
    getProducts: {
        function: "getProducts",
        webshopId: "{{webshopId}}",
        data: {
            productIds: ["12"],
        },
    },
} satisfies Record<string, ITemplate>
type ITemplates = typeof templates

const WebshopApiTesterPage = ({}: IWebshopApiTesterPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant", requiredRole: 90 }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const globalSelectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const selectedWebshopId = globalSelectedWebshopId || merchantDetails?.webshops[0]?.webshopId

    const dispatch = useReduxDispatch()

    const [content, setContent] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState("")
    const [response, setResponse] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState<keyof ITemplates>("blank")

    const save = async () => {
        try {
            setIsSaving(true)
            setResponse("")
            const request: ITemplate = JSON.parse(content)

            const res = await dispatch(adminApiTestThunk(request.webshopId, request.function, request.data))
            setResponse(JSON.stringify(res, undefined, 2))
        } catch (err: any) {
            setError(err?.message ?? "Unknown error")
        }
        setIsSaving(false)
    }

    useEffect(() => {
        const storedValue = localStorage.getItem("adminApiTester_" + selectedWebshopId + "_" + selectedTemplate) || ""
        if (storedValue) {
            setContent(storedValue)
            return
        }

        setContent(JSON.stringify(templates[selectedTemplate], undefined, 2).replaceAll("{{webshopId}}", selectedWebshopId ?? ""))
    }, [selectedTemplate, selectedWebshopId])

    if (!merchantDetails) {
        return null
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer classNameBody="webshop-api-tester-page" title="Webshop API Tester">
                <WebshopSelect merchantDetails={merchantDetails} />
                <SpinnerContainer aboveContent showSpinner={isSaving}>
                    <FieldsetFormGroup field col2>
                        <DropDownList
                            placeholder={"Templates"}
                            value={selectedTemplate}
                            onChange={(e) => {
                                setSelectedTemplate(e.target.value as keyof ITemplates)
                            }}
                            options={getKeys(templates).map((t) => ({ text: t, value: t }))}
                        />
                    </FieldsetFormGroup>
                    <CodeEditor
                        code={content}
                        onChange={(code) => {
                            setError("")
                            setContent(code)
                            console.log("Save", selectedWebshopId)
                            localStorage.setItem("adminApiTester_" + selectedWebshopId + "_" + selectedTemplate, code)
                        }}
                        language={"json"}
                        minHeight={300}
                        onSaveKey={() => {
                            save()
                        }}
                    />
                    <div className="webshop-api-tester-page__error">{error}</div>
                    <FieldsetFormGroup className="button-container mt-2">
                        <SpinnerContainer showSpinner={isSaving}>
                            <StandardButton
                                title="Send request"
                                onClick={async () => {
                                    save()
                                }}
                            />
                        </SpinnerContainer>
                    </FieldsetFormGroup>
                    <div className="webshop-api-tester-page__response">
                        {response && <CodeEditor code={response} language={"json"} minHeight={300} />}
                    </div>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default WebshopApiTesterPage
