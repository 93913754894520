import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { StandardButtonWithSpinner } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { getIconClassForRelation, getRootUrlForRelation } from "../../services/userRelationService"
import { switchUserThunk } from "../../store/thunks/startupThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { IUserRelationFlattened } from "../../type/user/IUserRelation"
import "./UserRelationInfo.scss"

interface IUserRelationInfoProps {
    userRelation: IUserRelationFlattened
    parentUserRelation?: IUserRelationFlattened
}

const UserRelationInfo = ({ userRelation, parentUserRelation }: IUserRelationInfoProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [isBtnActive, setBtnActive] = useState(false)

    return (
        <div className="swiipe-user-relation-info px-md-5">
            <span className={cn("icon", getIconClassForRelation(userRelation))}></span>
            <div className="userrelation ml-4 mr-1" onClick={() => setBtnActive(!isBtnActive)}>
                <div className="name">
                    {userRelation.name}
                    {userRelation.markedDeletion && <div className="alert alert-danger deleted">Deleted</div>}
                </div>
                {parentUserRelation && <div className="parentname">{parentUserRelation.name}</div>}
            </div>
            <div className={cn("div-fullwidth")}>
                <div className="action-box">
                    <StandardButtonWithSpinner
                        dark
                        className={cn("btn-switch", "action-button")}
                        title={t("switchaccountspage.choose")}
                        onClick={async () => {
                            await dispatch(switchUserThunk(userRelation))
                            navigationService.navigate(getRootUrlForRelation(userRelation))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserRelationInfo
