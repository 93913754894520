import { IInvoicingPrice } from "swiipe.portal.shared"
import { EInvoicingRelationType } from "../type/IInvoicing"
import { TUserRelation } from "../type/user/TUserRelation"
import { getDistinct, hasValue } from "../util/arrayUtil"
import { TSwiipePlan } from "./../type/merchant/TSwiipePlan"
export function getPricesForSwiipePlan(prices: IInvoicingPrice[] | undefined, swiipePlan: TSwiipePlan | undefined) {
    if (!prices || !swiipePlan) {
        return []
    }
    const categories = getDistinct(prices.map((p) => p.priceCategory))

    const otherPrices = prices.filter((p) => !p.priceType.endsWith("Basic") && !p.priceType.endsWith("Business"))

    if (swiipePlan === TSwiipePlan.Basic) {
        const basicPrices = prices.filter((p) => p.priceType.endsWith("Basic"))
        return categories
            .map((cat) => {
                const bp = basicPrices.find((p) => p.priceCategory === cat)
                if (bp) {
                    return bp
                }
                return otherPrices.find((p) => p.priceCategory === cat)
            })
            .filter(hasValue)
    }
    if (swiipePlan === TSwiipePlan.Business) {
        const businessPrices = prices.filter((p) => p.priceType.endsWith("Business"))
        return categories
            .map((cat) => {
                const bp = businessPrices.find((p) => p.priceCategory === cat)
                if (bp) {
                    return bp
                }
                return otherPrices.find((p) => p.priceCategory === cat)
            })
            .filter(hasValue)
    }
    return []
}

export function mapUserRelationTypeToInvoicingRelationType(userRelationType: TUserRelation): EInvoicingRelationType {
    switch (userRelationType) {
        case "Merchant":
            return "Merchant"
        case "Partner":
            return "Partner"
        case "User":
            return "User"
    }

    throw "Not an invoicing relation type"
}
