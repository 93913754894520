import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type SentSmsState = Readonly<{
    smses?: ISentSms[]
    resentSmsesIds: string[]
    search: string
}>

export interface ISentSms {
    smsId: string
    fromSenderName?: string
    created: string
    type: string
    sentToNumber: string
    sentToEmail?: string
    sentToName?: string
    body: string
    throttled: boolean
    notWhitelisted: boolean
    sendAmountLimitPassed: boolean
}

const initialState: SentSmsState = { resentSmsesIds: [], search: "" }

export const sentSmsReducerActions = {
    setSearch: (search: string) => createAction("APP/SET_SENT_SMSES_SEARCH", { search }),
    resetSmses: () => createAction("APP/RESET_SENT_SMSES"),
    setSmses: (smses: ISentSms[]) => createAction("APP/SET_SENT_SMSES", { smses }),
    addResentSms: (smsId: string) => createAction("APP/ADD_RESENT_SMS", { smsId }),
}

export type SentSmsActions = ActionsUnion<typeof sentSmsReducerActions>

export const sentSmsesReducer = (state: SentSmsState = initialState, action: SentSmsActions) => {
    switch (action.type) {
        case "APP/SET_SENT_SMSES": {
            return {
                ...state,
                smses: action.smses,
            }
        }
        case "APP/ADD_RESENT_SMS": {
            return {
                ...state,
                resentSmsesIds: [...state.resentSmsesIds, action.smsId],
            }
        }
        case "APP/SET_SENT_SMSES_SEARCH": {
            return {
                ...state,
                search: action.search,
            }
        }
        case "APP/RESET_SENT_SMSES": {
            return {
                ...state,
                smses: undefined,
            }
        }
    }

    return state
}

export const sentSmsSelectors = {
    smses: (state: StoreState) => state.sentSms.smses,
    search: (state: StoreState) => state.sentSms.search,
    resentSmsesIds: (state: StoreState) => state.sentSms.resentSmsesIds,
}
