import { TCurrency } from "swiipe.portal.shared"

export const allPriceCategories = [
    "Custom",
    "SwiipePspInitialization",
    "SwiipePspMonthly",
    "SwiipePspExtraWebshopMonthly",
    "SwiipePspTransaction",
    "AcquirerMinTransaction",
    "MobilePayOnlineMonthly",
    "MobilePayOnlineTransactionStep1",
    "MobilePayOnlineTransactionStep2",
    "MobilePayOnlineTransactionStep3",
    "SwishTransaction",
    "VippsTransaction",
    "KlarnaTransaction",
    "ResursInvoicePctTransaction",
    "ResursPartPaymentPctTransaction",
    "ResursRevolvingCreditPctTransaction",
    "ApplePayTransaction",
    "A2aMonthly",
    "A2aMinTransaction",
    "SwiipeWinbackPct",
    "SwiipeWinbackMaxOrderAmount",
    "SwiipePlusSellPct",
    "SwiipeReturnSellMonthly",
    "SwiipeReturnSellTransactionPct",
    "SwiipeReturnSellMinTransaction",
    "SwiipeReturnSellExtraWebshopMonthly",
    "SwiipeAnywhereMobileMonthly",
    "SwiipeAnywhereMobileExtraWebshopMonthly",
    "AcquirerTransactionPct",
    "AcquirerClearhaus3ds",
    "AcquirerClearhausAuth",
] as const

export type TInvoicingPriceCategory = (typeof allPriceCategories)[number]

export type TInvoicingPriceType =
    | "CustomShared"
    | "SwiipePspInitializationBasic"
    | "SwiipePspInitializationBusiness"
    | "SwiipePspMonthlyBasic"
    | "SwiipePspMonthlyBusiness"
    | "AcquirerMinTransactionShared"
    | "SwiipePspExtraWebshopMonthlyBasic"
    | "SwiipePspExtraWebshopMonthlyBusiness"
    | "SwiipePspTransactionBasic"
    | "SwiipePspTransactionBusiness"
    | "MobilePayOnlineMonthlyShared"
    | "MobilePayOnlineTransactionStep1Shared"
    | "MobilePayOnlineTransactionStep2Shared"
    | "MobilePayOnlineTransactionStep3Shared"
    | "SwishTransactionShared"
    | "VippsTransactionShared"
    | "KlarnaTransactionShared"
    | "ApplePayTransactionShared"
    | "ResursInvoicePctTransactionShared"
    | "ResursPartPaymentPctTransactionShared"
    | "ResursRevolvingCreditPctTransactionShared"
    | "A2aMonthlyShared"
    | "A2aMinTransactionShared"
    | "SwiipeWinbackPctBasic"
    | "SwiipeWinbackPctBusiness"
    | "SwiipeWinbackMaxOrderAmountShared"
    | "SwiipePlusSellPctBasic"
    | "SwiipePlusSellPctBusiness"
    | "SwiipeReturnSellMonthlyBasic"
    | "SwiipeReturnSellTransactionPctBasic"
    | "SwiipeReturnSellExtraWebshopMonthlyBasic"
    | "SwiipeReturnSellMonthlyBusiness"
    | "SwiipeReturnSellTransactionPctBusiness"
    | "SwiipeReturnSellExtraWebshopMonthlyBusiness"
    | "AcquirerClearhaus3dsShared"
    | "AcquirerClearhausAuthShared"

export const monthlyPriceCategories: TInvoicingPriceCategory[] = [
    "SwiipePspMonthly",
    "SwiipePspExtraWebshopMonthly",
    "MobilePayOnlineMonthly",
    "A2aMonthly",
    "SwiipeReturnSellMonthly",
    "SwiipeReturnSellExtraWebshopMonthly",
    "SwiipeAnywhereMobileMonthly",
    "SwiipeAnywhereMobileExtraWebshopMonthly",
]

export interface IInvoicingPrice {
    id: string
    amount: number
    currency: TCurrency
    market: EInvoicingMarket
    priceType: TInvoicingPriceType
    priceCategory: TInvoicingPriceCategory
    mustAddVat: boolean
    description: string
    isPercent: boolean
    isActive?: boolean
}

export interface IInvoiceingPriceOverride {
    priceCategory: TInvoicingPriceCategory
    description?: string
    amount?: number
    currency: string
    isModifier: boolean
    market: EInvoicingMarket
    created: string
}

export type TMerchantCustomPrice = "SingleValueWithCarryOver" | "ValueForEachPeriod"

export interface IInvoicingCustomPrice {
    id: string
    periods: number
    periodsLeft: number
    amount?: number
    amountLeft: number
    currency: string
    description?: string
    isDiscount: boolean
    isReplace: boolean
    isReadOnly: boolean
    customPriceType: TMerchantCustomPrice
    priceId: string
    priceCategory?: TInvoicingPriceCategory
    startOfPayment?: string
    endOfPayment?: string
    estimatedFinalEndOfPayment?: string
}

export type EInvoicingMarket = "Denmark" | "Sweden" | "EU" | "Other"
