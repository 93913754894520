import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { StoreState } from "../StoreState"
import { ISmsTemplate, smsTemplateReducerActions, smsTemplateSelectors } from "../reducers/smsTemplateReducer"
import { endpoints } from "./../../data/endpoints"

export const getSmsTemplatesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && smsTemplateSelectors.smsTemplates(getState())) {
            return
        }

        const result = await dispatch(requestThunk<{ smsTemplates: ISmsTemplate[] }>(endpoints.Send.getAllSmsTemplates))

        dispatch(smsTemplateReducerActions.setSmsTemplates(result.smsTemplates))
    }

export const updateSmsTemplateThunk =
    (smsTemplate: ISmsTemplate): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Send.updateSmsTemplate, {
                data: smsTemplate,
            })
        )

        await dispatch(getSmsTemplatesThunk(true))
    }
