import cn from "classnames"
import React from "react"
import { ModalFooter as ModFooter, Row } from "reactstrap"
import { StandardButtonWithSpinner } from "../../buttons/StandardButtonWithSpinner"

export interface ModalFooterButton {
    onClick?: () => Promise<void>
    text: string
    isInvertedColor?: boolean
    isDelete?: boolean
    isSmall?: boolean
}

interface IModalFooterProps {
    leftButton?: ModalFooterButton
    rightButton?: ModalFooterButton
    wideButtons?: boolean
    children?: React.ReactNode
}

export const ModalFooter = ({ leftButton, rightButton, wideButtons, children }: IModalFooterProps) => {
    return (
        <ModFooter>
            <div slot="modal-footer" className="w-100">
                {children}
                {(!!leftButton || !!rightButton) && (
                    <Row className="justify-content-between">
                        {leftButton && (
                            <div className={cn(wideButtons ? "col-md-5 col-6" : "col-auto")}>
                                <StandardButtonWithSpinner
                                    inverted={leftButton.isInvertedColor}
                                    isDelete={leftButton.isDelete}
                                    onClick={async () => {
                                        await leftButton.onClick?.()
                                    }}
                                    title={leftButton.text}
                                    isSmall={leftButton.isSmall}
                                />
                            </div>
                        )}
                        <div />
                        {rightButton && (
                            <div className={cn(wideButtons ? "offset-md-2 col-md-5 col-6" : "col-auto")}>
                                <StandardButtonWithSpinner
                                    inverted={rightButton.isInvertedColor}
                                    isDelete={rightButton.isDelete}
                                    onClick={async () => {
                                        await rightButton.onClick?.()
                                    }}
                                    title={rightButton.text}
                                    isSmall={rightButton.isSmall}
                                />
                            </div>
                        )}
                    </Row>
                )}
            </div>
        </ModFooter>
    )
}
