import { TEnv } from "swiipe.portal.shared"
import { ITermsTemplateTranslationRow } from "../components/form/termsTemplate/TermsTemplateForm"
import { ETermsType, allTermsTypes } from "../type/terms/ETermsType"
import { ITermsTemplate } from "../type/terms/ITermsTemplate"
import { ITermsTemplateTranslation } from "../type/terms/ITermsTemplateTranslation"
import { hasValue, sortDescending } from "../util/arrayUtil"
import { removeOriginFromUrl } from "../util/urlUtil"
import { calculateVersionValue } from "../util/versionUtil"

export function getLatestTemplate(termsTemplateVersions: ITermsTemplate[]) {
    const sorted = sortDescending(termsTemplateVersions, (t) => calculateVersionValue(t.version, t.revision))
    return sorted.length > 0 ? sorted[0] : undefined
}

export function getTranslationsFromContent(content: string) {
    const textMatches = content.match(new RegExp("{_[\\s\\S]*?_}", "g"))
    return (textMatches ?? []).map((match) =>
        removeWrappingTagFromTranslation(cleanTranslation(match.slice(2, match.length - 2)))
    )
}

export function cleanTranslation(translation: string) {
    return translation.trim().replace(new RegExp("\\n", "g"), " ").replace(new RegExp("\\s\\s+", "g"), " ")
}

export function cleanFileUrl(fileUrl: string, isInternal: boolean) {
    let result = fileUrl.trim()
    if (isInternal) {
        if (result.startsWith("http")) {
            result = removeOriginFromUrl(result)
        }
        if (result.startsWith("/termsfiles")) {
            result = result.slice(11)
        }
        if (!result.startsWith("/")) {
            result = "/" + result
        }
    }
    return result
}

function removeWrappingTagFromTranslation(translation: string) {
    const pipeSplit = translation.split("|")
    return pipeSplit.length > 1 ? pipeSplit[1] : pipeSplit[0].trim()
}

export function mergePlaceholders(templateContent: string, baseTemplateContent: string) {
    if (!baseTemplateContent.trim()) {
        return templateContent
    }
    const getPlaceholderContent = (placeholderName: string) => {
        const placeholderMatches = templateContent.matchAll(
            new RegExp("<Placeholder\\s+" + placeholderName + "\\s*>([\\s\\S]*?)</Placeholder>", "g")
        )
        const matches = Array.from(placeholderMatches)
        return matches.length === 0 ? undefined : matches.map((match) => (match.length >= 2 ? match[1] : "")).join("")
    }
    const basePlaceholderMatches = [
        ...Array.from(baseTemplateContent.matchAll(new RegExp("<Placeholder\\s+(\\S+?)\\s*>([\\s\\S]*?)</Placeholder>", "g"))),
        ...Array.from(baseTemplateContent.matchAll(new RegExp("<Placeholder\\s+(\\S+?)\\s*/>", "g"))),
    ]

    const result = basePlaceholderMatches.reduce((resultContent, match) => {
        const allMatch = match.length >= 1 ? match[0] : ""
        const placeholderName = match.length >= 2 ? match[1] : ""
        const placeholderFallbackContent = match.length >= 3 ? match[2] : ""
        const content = placeholderName ? getPlaceholderContent(placeholderName) : placeholderFallbackContent
        const toReplace = typeof content === "undefined" ? placeholderFallbackContent : content
        return allMatch ? resultContent.replace(allMatch, toReplace) : resultContent
    }, baseTemplateContent)
    return result
}

export function getTranslationsToSave(
    termsTemplateId: string,
    termsType: ETermsType,
    editedTrans: { [language: string]: { [key: string]: ITermsTemplateTranslationRow } },
    savedTrans: ITermsTemplateTranslation[],
    transKeysFromContent: string[],
    languages: string[]
) {
    const translationsToSave = languages
        .map((language) => {
            return transKeysFromContent
                .map((key) => {
                    const saved = savedTrans.find((s) => s.key === key && s.language === language)
                    const newValue = editedTrans[language]?.[key]?.translation
                    const translation: ITermsTemplateTranslation = {
                        key,
                        language,
                        termsType,
                        termsTemplateId,
                        translation: newValue ?? saved?.translation ?? "",
                        noFallback: editedTrans[language]?.[key]?.noFallback ?? saved?.noFallback ?? false,
                    }
                    if (!translation.translation && !translation.noFallback) {
                        return undefined
                    }
                    return translation
                })
                .filter(hasValue)
        })
        .flat()
    return translationsToSave
}

export function getInitialTermsType(): ETermsType {
    const saved = localStorage.getItem("lastSelectedTermsType") as ETermsType
    if (allTermsTypes.includes(saved)) {
        return saved
    }
    return allTermsTypes[0]
}

export function saveTermsType(termsType: ETermsType) {
    localStorage.setItem("lastSelectedTermsType", termsType)
}

export function getIsNewVersion(termsTemplateVersions: ITermsTemplate[], version: string, revision: number | string) {
    return (
        (version !== "" || revision !== 0) && !termsTemplateVersions?.find((v) => v.version === version && v.revision == revision)
    )
}

export function prepareTemplateTranslationKeyForFormsLib(key: string) {
    return key
        .replace(new RegExp("\\.", "g"), "__dot__")
        .replace(new RegExp(",", "g"), "__comma__")
        .replace(new RegExp("'", "g"), "__apostrophe__")
        .replace(new RegExp('"', "g"), "__quotes__")
        .replace(new RegExp("\\[", "g"), "__bracketstart__")
        .replace(new RegExp("\\]", "g"), "__bracketend__")
}

export function revertTemplateTranslationKeyFromFormsLib(key: string) {
    return key
        .replace(new RegExp("__dot__", "g"), ".")
        .replace(new RegExp("__comma__", "g"), ",")
        .replace(new RegExp("__apostrophe__", "g"), "'")
        .replace(new RegExp("__quotes__", "g"), '"')
        .replace(new RegExp("__bracketstart__", "g"), "[")
        .replace(new RegExp("__bracketend__", "g"), "]")
}

const editSupportedOnEnvironments: TEnv[] = ["Test", "Development", "Betatest"]
export function isEditTermsTemplateSupportedOnEnvironment(env: TEnv) {
    return editSupportedOnEnvironments.includes(env)
}
