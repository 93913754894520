import { ESessionEventCategory, ESessionEventNamespace } from "../type/ISessionEvent"
import { IPlusSellSession, IPlusSellSessionEvent } from "../type/plusSell/IPlusSellSession"
import { sortAscending } from "../util/arrayUtil"

export function parsePlusSellSessionEvents(events: string): IPlusSellSessionEvent[] {
    const parsedEvents = JSON.parse(events) as {
        type: string
        time: string
        namespace: ESessionEventNamespace
        category: ESessionEventCategory
        data: string
    }[]
    if (!parsedEvents || parsedEvents.length === 0) {
        return []
    }
    const parsedEventsWithDate = parsedEvents.map((parsedEvent) => ({
        type: parsedEvent.type,
        time: new Date(parsedEvent.time),
        namespace: parsedEvent.namespace,
        category: parsedEvent.category,
        data: parsedEvent.data,
    }))
    const sorted = sortAscending(parsedEventsWithDate, (d) => d.time)
    if (sorted.length === 0) {
        return []
    }
    const startTime = sorted[0].time
    let lastPlusSellEventTime: Date | undefined = undefined
    let lastEventTime: Date | undefined = undefined
    let lastPlusSellStarted: Date | undefined = undefined

    return sorted.map(({ type, time, namespace, category, data }) => {
        const isPlusSellStart = type === "plussell_start" || (namespace === "PlusSell" && type === "start")
        if (isPlusSellStart) {
            lastPlusSellStarted = time
        }
        const isPlusSellEvent = type.includes("plus-sell") || type.includes("plussell") || namespace === "PlusSell"

        const event: IPlusSellSessionEvent = {
            time,
            type,
            data,
            category,
            isPlusSellStart,
            isPlusSellEvent,
            firstEventTime: startTime,
            pageStartTime: isPlusSellStart ? undefined : lastPlusSellStarted,
            timeEventBefore: lastEventTime,
            timePlusSellEventBefore: lastPlusSellEventTime,
        }

        if (isPlusSellEvent) {
            lastPlusSellEventTime = time
        }
        lastEventTime = time

        return event
    })
}

export function getPlusSellSessionStartTime(session: IPlusSellSession) {
    return session.parsedEvents.length === 0 ? new Date(session.date) : session.parsedEvents[0].time
}
