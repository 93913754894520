import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IHostedFieldsData } from "../../type/hostedFields/IHostedFieldsData"
import { StoreState } from "../StoreState"

export type HostedFieldsState = Readonly<{
    data?: IHostedFieldsData
}>
const initialState: HostedFieldsState = { data: undefined }

export const hostedFieldsReducerActions = {
    addHostedFieldsData: (data: IHostedFieldsData) => createAction("APP/ADD_HOSTED_FIELDS_DATA", { data }),
}

export type HostedFieldsActions = ActionsUnion<typeof hostedFieldsReducerActions>

export const hostedFiledsReducer = (state: HostedFieldsState = initialState, action: HostedFieldsActions) => {
    switch (action.type) {
        case "APP/ADD_HOSTED_FIELDS_DATA": {
            return {
                ...state,
                data: action.data,
            }
        }
    }

    return state
}

export const hostedFieldsSelectors = {
    data: (state: StoreState) => state.hostedFields.data,
}
