import React, { useState } from "react"
import { SpinnerContainer } from "../loading/SpinnerContainer"
import { IStandardButtonBaseProps, StandardButton } from "./StandardButton"

interface IStandardButtonWithSpinnerProps extends IStandardButtonBaseProps {
    onClick: (setShowSpinner: TStandardButtonSetShowSpinnerFunc, e: React.MouseEvent) => Promise<void>
    containerClass?: string
}

export type TStandardButtonSetShowSpinnerFunc = (showSpinner: boolean) => void

export const StandardButtonWithSpinner = ({
    containerClass,
    onClick,
    children,
    ...otherProps
}: IStandardButtonWithSpinnerProps) => {
    const [isLoading, setisLoading] = useState(false)

    return (
        <SpinnerContainer className={containerClass} showSpinner={isLoading}>
            <StandardButton
                {...otherProps}
                onClick={async (e) => {
                    e.preventDefault()
                    setisLoading(true)
                    try {
                        await onClick((showSpinner) => setisLoading(showSpinner), e)
                        setisLoading(false)
                    } catch (err) {
                        setisLoading(false)
                    }
                }}
            >
                {children}
            </StandardButton>
        </SpinnerContainer>
    )
}
