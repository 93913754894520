import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { IModalCallback, TAllSharedModals, TModalResponseType, initializeModals } from "swiipe.portal.shared"
import { TAllModals } from "../../components/modal/common/TAllModals"
import { StoreState } from "../StoreState"

export interface IModalResponse {
    type: TModalResponseType
    data?: any
}

const modalActions = initializeModals<(TAllModals | TAllSharedModals) & IModalCallback>()

export const addModalThunk =
    (modal: TAllModals | TAllSharedModals): ThunkAction<Promise<IModalResponse>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const modalReponse = await new Promise<IModalResponse>((outerResolve) => {
            dispatch(modalActions.addModal({ ...modal, callback: (respType, data) => outerResolve({ type: respType, data }) }))
        })
        return modalReponse
    }
