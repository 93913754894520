import cn from "classnames"
import React from "react"
import "./PageContainer.scss"

interface IPageContainerProps {
    title?: string
    id?: string
    transparent?: boolean
    noHeader?: boolean
    centerContent?: boolean
    roundPageLook?: boolean
    largeText?: boolean
    classNameBody?: string
    className?: string
    topBottomMargin?: boolean
    children?: React.ReactNode
    disableDefaultPaddingBottom?: boolean
    hide?: boolean
}

export const PageContainer = ({
    children,
    centerContent,
    roundPageLook,
    noHeader,
    title,
    id,
    transparent,
    classNameBody,
    className,
    largeText,
    topBottomMargin,
    disableDefaultPaddingBottom,
    hide,
}: IPageContainerProps) => {
    return (
        <div
            className={cn(
                "swiipe-page-container page",
                roundPageLook && "mt-5",
                disableDefaultPaddingBottom && "disable-def-padding-bottom",
                hide && "d-none",
                className
            )}
            id={id}
        >
            {!noHeader && (
                <div className="page-header mb-1">
                    <span className="d-block d-sm-none text-uppercase text-smaller font-weight-light">{title} </span>
                </div>
            )}
            <div
                className={cn(
                    "page-body",
                    roundPageLook && "rounded px-3",
                    topBottomMargin && "py-5",
                    { transparent, "large-text": largeText },
                    classNameBody
                )}
            >
                <div className={cn(centerContent && "justify-content-center center-content")}>{children}</div>
            </div>
        </div>
    )
}
