import React, { useEffect, useState } from "react"
import {
    PageContainer,
    PageWrapper,
    StandardButtonWithSpinner,
    TextButton,
    sharedConfigurationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import DropDownList from "../../components/form/input/DropDownList"
import PageTitle from "../../components/page/PageTitle"
import termsTemplateTypeSpecs, { ITermsTemplateType } from "../../data/termsTemplateTypeSpecs"
import { StoreState } from "../../store/StoreState"
import useReduxDispatch from "../../store/useReduxDispatch"
import "./TermsTemplatePage.scss"

import { StandardButton } from "swiipe.portal.shared"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import TermsExampleForm from "../../components/form/termsTemplate/TermsExampleForm"
import TermsTemplateForm from "../../components/form/termsTemplate/TermsTemplateForm"
import TermsTemplateVersionForm from "../../components/form/termsTemplate/TermsTemplateVersionForm"
import TemplateLegend from "../../components/template/TemplateLegend"
import {
    getInitialTermsType,
    getIsNewVersion,
    getLatestTemplate,
    isEditTermsTemplateSupportedOnEnvironment,
    saveTermsType,
} from "../../services/termsTemplateService"
import { termsTemplateSelectors } from "../../store/reducers/termsTemplateReducer"
import { releaseTermsThunk } from "../../store/thunks/termsReleaseThunks"
import {
    getTermsBaseTemplatesThunk,
    getTermsTemplateDetailsThunk,
    removeTermsTemplateThunk,
} from "../../store/thunks/termsTemplateThunks"
import { ETermsType } from "../../type/terms/ETermsType"
import { sortAscending, sortDescending } from "../../util/arrayUtil"
import { getKeys } from "../../util/objectUtils"
import { calculateVersionValue, extractVersionFromValue } from "../../util/versionUtil"

interface ITermsTemplatePage {}

const TermsTemplatePage = ({}: ITermsTemplatePage) => {
    const dispatch = useReduxDispatch()

    const [showHelp, setShowHelp] = useState(false)
    const [showExampleForm, setShowExampleForm] = useState(false)
    const env = useSelector(sharedConfigurationSelectors.environment)

    const [termsType, setTermsType] = useState(getInitialTermsType())
    const [versionsSetForTermsType, setVersionsSetForTermsType] = useState<ETermsType | undefined>(undefined)

    const [version, setVersion] = useState("")
    const [revision, setRevision] = useState(0)

    const termsTemplateVersions = useSelector((state: StoreState) =>
        termsTemplateSelectors.termsTemplateDetails(state, termsType)
    )

    const isNewVersion = getIsNewVersion(termsTemplateVersions ?? [], version, revision)
    const showVersionSelection = calculateVersionValue(version, revision) === 0

    const termsTemplate = (termsTemplateVersions ?? []).find((t) => t.version === version && t.revision == revision)
    const savedTranslations = useSelector((state: StoreState) =>
        termsTemplateSelectors.termsTemplateTranslations(state, termsType)
    )

    useEffect(() => {
        if (versionsSetForTermsType === termsType && !!version) {
            return
        }
        const latestTemplate = getLatestTemplate(termsTemplateVersions ?? [])
        setVersion(latestTemplate ? latestTemplate.version : "")
        setRevision(latestTemplate ? latestTemplate.revision : 0)
        setVersionsSetForTermsType(termsType)
    }, [termsTemplateVersions])

    useEffect(() => {
        dispatch(getTermsBaseTemplatesThunk(false))
    }, [])

    useEffect(() => {
        dispatch(getTermsTemplateDetailsThunk(termsType, false))
    }, [termsType])

    const isTermsLoaded = !!termsTemplateVersions && (!termsTemplate?.termsTemplateId || !!savedTranslations)

    return (
        <PageWrapper fullWidth>
            <PageContainer id="terms-template-page">
                {!env.toLocaleLowerCase().includes("test") && env !== "Development" && (
                    <div className="terms-template-page__release-container">
                        <StandardButtonWithSpinner
                            title="Release terms from Test environment"
                            isDelete
                            isTiny
                            onClick={async () => {
                                await dispatch(releaseTermsThunk())
                            }}
                        />
                    </div>
                )}
                <PageTitle title="Update Terms Template" />
                <div className="terms-template-page__type-container">
                    <DropDownList
                        label="Terms Template Type"
                        containerClassName="terms-template-page__template-type-ddl"
                        value={termsType}
                        onChange={(v) => {
                            setTermsType(v.target.value as ETermsType)
                            saveTermsType(v.target.value as ETermsType)
                        }}
                        options={sortAscending<ITermsTemplateType & { type: ETermsType }>(
                            getKeys(termsTemplateTypeSpecs).map((t) => ({ type: t, ...termsTemplateTypeSpecs[t] })),
                            (termsType) => termsType.label
                        ).map((x) => ({
                            text:
                                x.label +
                                (x.relationTypes ? " ___(For: " + x.relationTypes.join(", ") + ")" : "") +
                                (x.productType ? " ___(Product: " + x.productType + ")" : "") +
                                (x.provider ? " ___(Provider: " + x.provider + ")" : ""),
                            value: x.type,
                        }))}
                    />
                </div>
                <div className="terms-template-page__version-container">
                    <SpinnerContainer showSpinner={!termsTemplateVersions} minHeight={40}>
                        <div className="terms-template-page__version-select-container">
                            <DropDownList
                                label="Versions"
                                containerClassName="terms-version-container__version-ddl-container"
                                className="terms-version-container__version-ddl"
                                value={calculateVersionValue(version, revision)}
                                onChange={(e) => {
                                    const value = e.target.value
                                    const { version, revision } = extractVersionFromValue(parseInt(value))
                                    setVersion(value === "0" ? "" : version)
                                    setRevision(revision)
                                    setVersionsSetForTermsType(termsType)
                                }}
                                options={[
                                    isNewVersion
                                        ? {
                                              text: version + " r. " + revision + " (Not saved)",
                                              value: calculateVersionValue(version, revision),
                                          }
                                        : { text: "Create new", value: 0 },
                                    ...sortDescending(termsTemplateVersions ?? [], (t) =>
                                        calculateVersionValue(t.version, t.revision)
                                    ).map((x) => ({
                                        text: x.version + " r. " + x.revision,
                                        value: calculateVersionValue(x.version, x.revision),
                                    })),
                                ]}
                            />
                            {termsTemplate?.isReleased === false && isEditTermsTemplateSupportedOnEnvironment(env) && (
                                <TextButton
                                    className="terms-template-page__version-remove"
                                    text="Remove version"
                                    onClick={async () => {
                                        const wasRemoved = await dispatch(removeTermsTemplateThunk(termsTemplate))
                                        if (!wasRemoved) {
                                            return
                                        }
                                        const latest = getLatestTemplate(
                                            (termsTemplateVersions ?? []).filter(
                                                (t) => t.termsTemplateId !== termsTemplate.termsTemplateId
                                            )
                                        )
                                        setVersion(latest ? latest.version : "")
                                        setRevision(latest?.revision ?? 1)
                                        setVersionsSetForTermsType(termsType)
                                    }}
                                />
                            )}
                        </div>
                    </SpinnerContainer>
                    {isTermsLoaded && showVersionSelection && (
                        <TermsTemplateVersionForm
                            termsType={termsType}
                            onSelection={(version: string, revision: number) => {
                                setVersion(version)
                                setRevision(revision)
                                setVersionsSetForTermsType(termsType)
                            }}
                        />
                    )}
                </div>
                {!showVersionSelection && (
                    <>
                        <label>Actions</label>
                        <div className="terms-template-page__actions">
                            <div className="terms-template-page__actions-buttons">
                                <StandardButton
                                    className="terms-template-page__actions-show-help"
                                    isSmall
                                    invertedBlue
                                    isGrey={showHelp}
                                    title={showHelp ? "Hide help" : "Show help"}
                                    onClick={() => {
                                        setShowHelp(!showHelp)
                                        setShowExampleForm(false)
                                    }}
                                />
                                <StandardButtonWithSpinner
                                    containerClass="terms-template-page__actions-show-example-btn"
                                    title="Show Variables and Get Example"
                                    isSmall
                                    invertedBlue
                                    isGrey={showExampleForm}
                                    onClick={async () => {
                                        setShowExampleForm(!showExampleForm)
                                        setShowHelp(false)
                                    }}
                                />
                            </div>
                            {showExampleForm && (
                                <div className="terms-template-page__example-form mt-3">
                                    <h5>Generate example:</h5>
                                    <TermsExampleForm termsType={termsType} version={version} revision={revision} />
                                </div>
                            )}
                            {showHelp && (
                                <div className="terms-template-page__legend-container mt-3">
                                    <h5>Help:</h5>
                                    <TemplateLegend templateType="terms" />
                                </div>
                            )}
                        </div>
                    </>
                )}

                {!!version && !!revision && (
                    <SpinnerContainer showSpinner={!isTermsLoaded} minHeight={100}>
                        {isTermsLoaded && <TermsTemplateForm version={version} revision={revision} termsType={termsType} />}
                    </SpinnerContainer>
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default TermsTemplatePage
