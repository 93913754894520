import cn from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { SharedStoreState } from "../../store/SharedStoreState"
import { sharedConfigurationSelectors } from "../../store/sharedConfigurationReducer"
import "./NavigateToOtherPortal.scss"

interface INavigateToOtherPortalProps {
    relationId: string
    type: "NavToAdmin" | "NavToPortal"
}

export const NavigateToOtherPortal = ({ type, relationId }: INavigateToOtherPortalProps) => {
    const endpoint = useSelector((state: SharedStoreState) =>
        sharedConfigurationSelectors.endpointConfig(state, type === "NavToAdmin" ? "AdminPortal" : "Portal")
    )
    if (!endpoint || !relationId) {
        return null
    }
    return (
        <div className={cn("navigate-to-other-portal")}>
            <a target="_blank" href={endpoint + "?relationId=" + relationId}>
                {"Go to " + (type === "NavToAdmin" ? "Admin Portal" : "Portal")}
            </a>
        </div>
    )
}
