import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { sortDescending } from "../../util/arrayUtil"
import { StoreState } from "../StoreState"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { WebshopSnapshot, webshopReducerActions, webshopSelectors } from "../reducers/webshopReducer"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const ensureWebshopSnapshotThunk =
    (swMerchantId: string, webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const result = await dispatch(
                requestThunk<{
                    webshopSnapshots: WebshopSnapshot[]
                }>(endpoints.Plugin.recordWebshopSnapshot, {
                    data: {
                        merchantId: swMerchantId,
                        webshopIds: [webshopId],
                    },
                })
            )
            if (result?.webshopSnapshots?.length > 0) {
                dispatch(webshopReducerActions.setWebshopSnapshot(webshopId, result.webshopSnapshots[0]))
            }
        } catch {
            // Dispose error when this call fails - don't stop from onboarding merchant for Win-back
        }
    }

export const fetchSwiipePluginVersionsThunk =
    (webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (webshopSelectors.swiipePluginVersion(getState(), webshopId)) {
            return
        }

        const { pluginVersions } = await dispatch(
            requestThunk<{
                pluginVersions: {
                    date: Date
                    version: string
                }[]
            }>(endpoints.Analytics.getPluginVersions, {
                params: {
                    swMerchantId: currentUserRelation.id,
                    webshopId,
                    perPage: 1,
                },
            })
        )

        const sortedPluginVersions = sortDescending(pluginVersions ?? [], (v) => v.date)
        if (sortedPluginVersions.length === 0) {
            return
        }
        dispatch(webshopReducerActions.setPluginVersion(webshopId, sortedPluginVersions[0].version))
    }

export const adminApiTestThunk =
    (
        webshopId: string,
        functionName: string,
        data: Record<string, any>
    ): ThunkAction<Promise<Record<string, any>>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const result = await dispatch(
            requestThunk<Record<string, any>>(endpoints.Plugin.adminApiTest, {
                data: {
                    function: functionName,
                    webshopId,
                    data,
                },
            })
        )
        return result
    }
