import React, { useState } from "react"
import cn from "classnames"
import "./HintIconWithTextOnClick.scss"

interface IHintIconWithTextOnClick {
    hintText: string | JSX.Element
    showHintOnHover?: boolean
    className?: string
    blue?: boolean
    wider?: boolean
    showOnTop?: boolean
}

export const HintIconWithTextOnClick = ({
    hintText,
    showHintOnHover,
    className,
    blue,
    wider,
    showOnTop,
}: IHintIconWithTextOnClick) => {
    const [showHint, setShowHint] = useState(false)

    return (
        <span className={cn("hint-icon-with-text-on-hover", className)}>
            <span
                className="icon icon-hint"
                onClick={() => setShowHint(!showHint)}
                onMouseEnter={() => {
                    if (showHintOnHover) {
                        setShowHint(true)
                    }
                }}
                onMouseOut={() => setShowHint(false)}
                onTouchStart={() => setShowHint(true)}
                onTouchEnd={() => setShowHint(false)}
            />
            <span
                className={cn("text-hint", !showHint && "d-none", blue && "blue", wider && "wider", showOnTop && "show-on-top")}
            >
                {hintText}
            </span>
        </span>
    )
}
