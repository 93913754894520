import { IInternalComment } from "../types/comments/IInternalComment"
import { ActionsUnion } from "../types/state/actionsUnion"
import { createAction } from "../types/state/createAction"
import { SharedStoreState } from "./SharedStoreState"

export type CommentState = Readonly<{
    comments: { [relationId: string]: IInternalComment[] | undefined }
}>

const initialState: CommentState = {
    comments: {},
}

export const commentReducerActions = {
    setComments: (relationId: string, comments: IInternalComment[]) => createAction("APP/SET_COMMENTS", { relationId, comments }),
}

export type CommentActions = ActionsUnion<typeof commentReducerActions>

export const commentReducer = (state: CommentState = initialState, action: CommentActions) => {
    switch (action.type) {
        case "APP/SET_COMMENTS": {
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.relationId]: action.comments,
                },
            }
        }
    }

    return state
}

export const commentSelectors = {
    comments: (state: SharedStoreState, relationId: string) => state.comment.comments[relationId],
}
