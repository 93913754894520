import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userReducerActions } from "swiipe.portal.shared"
import { authService } from "../../services/authService"
import { LoginFormModel } from "../../type/login/loginForm"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"
import { navigationService } from "./../../services/navigationService"
import { prepareFormData } from "./../../util/formUtil"
import { addModalThunk } from "./modalThunks"

export interface ICheckCookieResponse {
    status: boolean
    cookieTimeout?: string
}
const latestExpirationTimeKey = "latestCookieExpiration"

const pushStateListeners: (() => void)[] = []

const pushState = window.history.pushState
window.history.pushState = function (...args) {
    pushState.apply(window.history, args)
    pushStateListeners.forEach((l) => l())
}

export const checkCookieThunk = (): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> => async (dispatch) => {
    try {
        const response = await dispatch(
            requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie, undefined, {
                noErrorAlert: true,
            })
        )

        if (window.self !== window.top) {
            // Do not start new cookie handling inside iframe
            return response.status
        }

        dispatch(handleCookieTimeoutThunk(response))

        return response.status
    } catch (ex) {
        return false
    }
}

const isLatestExpireTime = (cookieExpireTime: number) => {
    const storedExpirationStr = localStorage.getItem(latestExpirationTimeKey)
    const storedExpiration = storedExpirationStr ? parseInt(storedExpirationStr) : undefined
    const isCookieLatest = storedExpiration && cookieExpireTime >= storedExpiration
    return !storedExpiration || isCookieLatest
}

const handleCookieTimeoutThunk =
    (cookieResponse: ICheckCookieResponse): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!cookieResponse.cookieTimeout) {
            return
        }

        const cookieValidUntilSeconds = parseInt(cookieResponse.cookieTimeout) // unix time is in seconds
        const elapsedTimeInSeconds = Math.floor(Date.now() / 1000) // js time is in milliseconds - divide by 1000 to get seconds
        const timeoutSeconds = Math.max(cookieValidUntilSeconds - elapsedTimeInSeconds, 0)
        const renewBufferSeconds = 120 + Math.floor(Math.random() * 120) // Random time slack to avoid all renewing at the same time
        const renewTimeSeconds = Math.max(timeoutSeconds - renewBufferSeconds, 0)

        dispatch(
            userReducerActions.setLoginSession(
                new Date(cookieValidUntilSeconds * 1000),
                new Date((elapsedTimeInSeconds + renewTimeSeconds) * 1000)
            )
        )
        dispatch(userReducerActions.setLoginSessionState("waitingForUserInteraction"))

        // If renew fails the normal logged out modal should trigger else reschedule
        const showModalTimeout = setTimeout(async () => {
            dispatch(userReducerActions.setLoginSessionState("checking"))
            const newCookieResponse = await dispatch(
                requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie, undefined, {
                    errorHandlers: [
                        {
                            errorCode: "401",
                            handleError: async (err) => {
                                dispatch(handleCookieTimeoutThunk(cookieResponse))
                                return true
                            },
                        },
                    ],
                })
            )
            const cookieExpireTime = newCookieResponse.cookieTimeout ? parseInt(newCookieResponse.cookieTimeout) : undefined

            if (cookieExpireTime && isLatestExpireTime(cookieExpireTime)) {
                localStorage.setItem(latestExpirationTimeKey, cookieExpireTime.toString())
                dispatch(handleCookieTimeoutThunk(newCookieResponse))
                return
            }
            dispatch(userReducerActions.setLoginSessionState("expired"))
            await dispatch(addModalThunk({ type: "modalLoggedOut" }))
        }, timeoutSeconds * 1000)

        if (timeoutSeconds > renewBufferSeconds) {
            // Schedule auto cookie renewal
            // Only renew if the users is active in the session to avoid excessive calls to auth
            let isTheUserThere = false
            const handleNavigation = () => {
                if (!isTheUserThere) {
                    dispatch(userReducerActions.setLoginSessionState("userInteracted"))
                }
                isTheUserThere = true
            }
            const handleScroll = () => {
                if (!isTheUserThere) {
                    dispatch(userReducerActions.setLoginSessionState("userInteracted"))
                }
                isTheUserThere = true
            }

            pushStateListeners.push(handleNavigation)
            document.addEventListener("scroll", handleScroll)

            setTimeout(async () => {
                pushStateListeners.splice(pushStateListeners.indexOf(handleNavigation), 1)
                document.removeEventListener("scroll", handleScroll)

                const container = document.getElementById("cookie-expiration-extender")
                if (!container || !isTheUserThere || !isLatestExpireTime(cookieValidUntilSeconds)) {
                    // If already renewed from other tabs
                    dispatch(userReducerActions.setLoginSessionState("renewNotPossible"))
                    return
                }
                localStorage.setItem(latestExpirationTimeKey, (cookieValidUntilSeconds + 1800).toString()) // Add 30 min to prevent other threads from trying to renew

                dispatch(userReducerActions.setLoginSessionState("renewing"))
                // Insert cookie into the cookie-expiration-extender div
                const iframe = document.createElement("iframe")
                iframe.src = document.location.origin + "?forceauth=1"
                iframe.style["height"] = "0px"
                iframe.style["display"] = "none"
                container.appendChild(iframe)

                // The until we expect the cookie renewal the succeed - so we remove the iframe after that
                const timeToFinishLogin = 20 * 1000
                setTimeout(async () => {
                    container.removeChild(iframe)
                }, timeToFinishLogin)
            }, renewTimeSeconds * 1000)
        } else {
            dispatch(userReducerActions.setLoginSessionState("renewNotPossible"))
        }
    }

export const loginThunk =
    (form: LoginFormModel, returnUrl?: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const preparedForm = prepareFormData(form, [])
        const otac = await dispatch(
            requestThunk<string>(endpoints.Auth.login, {
                data: preparedForm,
            })
        )
        await authService.login(returnUrl || "/", otac)
    }

export const logOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch) => {
    const response = await dispatch(addModalThunk({ type: "logOut" }))
    if (response.type === "accepted") {
        navigationService.navigate("forgotpassword")
    }
}
