import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form, FormGroup } from "reactstrap"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import DropDownList from "../../components/form/input/DropDownList"
import { exportMerchantContactsThunk } from "../../store/thunks/merchantThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import "./CustomerContactsPage.scss"

interface ICustomerContactsPage {}

interface IForm {
    merchantsPlan: string
}

const CustomerContactsPage = ({}: ICustomerContactsPage) => {
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, formState } = useForm<IForm>()

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        await dispatch(exportMerchantContactsThunk(data.merchantsPlan))
    }

    return (
        <PageWrapper>
            <PageContainer id="customer-contacts-page" topBottomMargin>
                <div className="title text-center mb-5">Generate customer contact list</div>

                <Form onSubmit={handleSubmit(onSubmit)} className="main-form">
                    <FormGroup className="select-plan">
                        <span>Select merchants plan:</span>
                        <DropDownList
                            options={[
                                {
                                    text: "Basic",
                                    value: "Basic",
                                },
                                {
                                    text: "Business",
                                    value: "Business",
                                },
                                {
                                    text: "Basic and Business",
                                    value: "Both",
                                },
                                {
                                    text: "No plan",
                                    value: "NoPlan",
                                },
                                {
                                    text: "Basic, Business and No Plan",
                                    value: "All",
                                },
                            ]}
                            innerRef={register}
                            name="merchantsPlan"
                        />
                    </FormGroup>

                    <SubmitButton isSmall containerClass="btn-save mb-4" dark noBorder formState={formState}>
                        Generate contact list
                    </SubmitButton>
                </Form>
            </PageContainer>
        </PageWrapper>
    )
}

export default CustomerContactsPage
