import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { IPluginAssetSelection, IPluginInfoData, pluginReducerActions } from "../reducers/pluginReducer"
import { IWebshopAssetsSettings } from "./../reducers/pluginReducer"

export const getPluginInfoThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const pluginInfoData = await dispatch(requestThunk<IPluginInfoData>(endpoints.Plugin.getPluginInfo))

    dispatch(pluginReducerActions.addPluginInfoData(pluginInfoData))
}

export const getPluginAssetThunk =
    (pluginAssetSelection: IPluginAssetSelection): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const pluginAssetData = await dispatch(
            requestThunk<string>(endpoints.Plugin.getPluginAsset, {
                params: {
                    webshopId: pluginAssetSelection.webshopId,
                    platform: pluginAssetSelection.platform,
                    country: pluginAssetSelection.country,
                    plugin: pluginAssetSelection.plugin,
                    assetName: pluginAssetSelection.assetName,
                    assetType: pluginAssetSelection.assetType,
                },
            })
        )

        dispatch(pluginReducerActions.setPluginAsset(pluginAssetData, pluginAssetSelection))
    }

export const updatePluginAssetThunk =
    (pluginAssetSelection: IPluginAssetSelection, content: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const success = await dispatch(
            requestThunk<boolean>(endpoints.Plugin.updatePluginAsset, {
                data: {
                    webshopId: pluginAssetSelection.webshopId,
                    platform: pluginAssetSelection.platform,
                    country: pluginAssetSelection.country,
                    plugin: pluginAssetSelection.plugin,
                    assetName: pluginAssetSelection.assetName,
                    isScript: pluginAssetSelection.assetType === "script",
                    content: content,
                },
            })
        )

        if (success) {
            dispatch(pluginReducerActions.setPluginAsset(content, pluginAssetSelection))
        }
    }

export const enableFastUpdateThunk =
    (webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(endpoints.Plugin.enablePluginAssetsFastUpdate, {
                data: {
                    webshopId: webshopId,
                },
            })
        )

        await dispatch(ensureWebshopAssetsSettingsThunk(webshopId))
    }

export const ensureWebshopAssetsSettingsThunk =
    (webshopId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!webshopId) {
            return
        }
        const settings = await dispatch(
            requestThunk<IWebshopAssetsSettings>(endpoints.Plugin.getWebshopAssetsSettings, {
                params: {
                    webshopId: webshopId,
                },
            })
        )

        if (settings.fastUpdateUntil) {
            settings.fastUpdateUntil = new Date(settings.fastUpdateUntil)
        }

        dispatch(pluginReducerActions.setWebshopAssetsSettings(webshopId, settings))
    }
