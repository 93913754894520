import { TPayment } from "./TPayment"
export type TProviderType = "PspAndAquirer" | "Psp" | "Aquirer" | "Other"
export type TProviderName =
    | "Swiipe"
    | "Clearhaus"
    | "Bambora"
    | "DankortEpay"
    | "Finaro"
    | "ForbrugsforeningenEpay"
    | "MobilePayOnline"
    | "Swish"
    | "Viabill"
    | "Vipps"
    | "Klarna"
    | "Resurs"
    | "ApplePay"
    | "AccountToAccount"

export type TAcquirerName = "Nets" | "Clearhaus" | "Forbrugsforeningen" | "Swish" | "Viabill" | "Klarna" | "Resurs"

export type TOnboardingStatus = "ActionRequired" | "InProgress" | "Ready"

export interface IGatewayProvider {
    paymentType: TPayment
    providerType: TProviderType
    providerName: TProviderName
    status?: TOnboardingStatus
    enabled?: boolean
}

export enum TSwiipePlan {
    None = 0,
    Basic = 1,
    Business = 2,
}
