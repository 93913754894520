import { ECommentArea, IInternalComment } from "swiipe.portal.shared"
import { sortDescending } from "../util/arrayUtil"

export function getLatestComment(
    comments: IInternalComment[] | undefined,
    area: ECommentArea,
    webshopId?: string,
    defaultArea: ECommentArea | "None" = "CompanyInfo"
) {
    if (!comments) {
        return undefined
    }

    const commentsForSelectedFilters = comments.filter((c) => c.area === area && (!webshopId || c.webshopId === webshopId))
    if (defaultArea === "None" && commentsForSelectedFilters.length === 0) {
        return undefined
    }

    const sortedByTimeCreated = sortDescending(
        commentsForSelectedFilters.length > 0 ? commentsForSelectedFilters : comments.filter((c) => c.area === defaultArea),
        (c) => c.created
    )

    return sortedByTimeCreated.length > 0 ? sortedByTimeCreated[0] : undefined
}
