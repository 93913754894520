import {
    EInvoicingMarket,
    IInvoiceingPriceOverride,
    IInvoicingCustomPrice,
    IInvoicingPrice,
    TInvoicingPriceCategory,
    TMerchantCustomPrice,
    getDistinct,
    getKeys,
    hasValue,
    numberToFixedDecimals,
    toDict,
} from "swiipe.portal.shared"
import { IInvoicingMerchantConfig } from "../type/IInvoicing"
import { getValues } from "../util/objectUtils"

export interface IMerchantCustomPricesSubForm {
    priceOverrides: {
        [category in TInvoicingPriceCategory]?: IFormOverride
    }
    customPrices?: {
        [id in string]?: IFormCustomPrice
    }
}

export interface IFormCustomPrice {
    description?: string
    periods: string
    price: string
    type: "0" | "1" | "2"
    priceCategory: TInvoicingPriceCategory
}

export interface IFormOverride {
    priceCategory: TInvoicingPriceCategory
    description?: string
    price: string
    isModifier: "0" | "1"
}

export function getPriceFormErrors(prices: IInvoicingPrice[], pricesSubForm: IMerchantCustomPricesSubForm) {
    const customPriceData = getValues(pricesSubForm.customPrices || {})
    return customPriceData
        .map((d) => {
            const { price, isDiscount, isReplace, periods } = parseCustomPriceValues(d!, "ValueForEachPeriod")
            if (isDiscount || !isReplace) {
                return undefined
            }
            if (typeof price === "undefined" && !periods) {
                return undefined
            }
            if (typeof price !== "undefined" && periods) {
                return undefined
            }
            const invoicingPrice = prices.find((p) => p.priceCategory === d?.priceCategory)
            if (!invoicingPrice) {
                return undefined
            }
            if (!periods) {
                return `Please select the temporary period in months for ${invoicingPrice.description}`
            }
            const section = merchantCustomPriceSections.find((s) =>
                s.prices.find((p) => p.category === invoicingPrice.priceCategory)
            )

            const priceInOtherCategoriesInSection = (section?.prices ?? [])
                .filter((p) => p.category !== invoicingPrice.priceCategory)
                .filter((p) => {
                    const data = customPriceData.find((cp) => cp?.priceCategory === p.category)
                    if (!data) {
                        return false
                    }
                    const { price } = parseCustomPriceValues(data, "ValueForEachPeriod")
                    return typeof price !== "undefined"
                })
            if (priceInOtherCategoriesInSection.length > 0) {
                return undefined
            }

            return `Please provide a temporary fee value for ${section?.title} section. The temporary period was selected.`
        })
        .filter(hasValue)
}

export function preparePriceFormForUpdate(
    prices: IInvoicingPrice[],
    invoicingMerchantConfig: IInvoicingMerchantConfig,
    pricesSubForm: IMerchantCustomPricesSubForm
) {
    const currency = prices && prices.length > 0 ? prices[0].currency : "DKK"
    const market = prices && prices.length > 0 ? prices[0].market : "Denmark"

    const newOverrides: IInvoiceingPriceOverride[] = prices
        .map((p) => {
            const priceOverride = pricesSubForm.priceOverrides[p.priceCategory]
            if (!priceOverride) {
                return undefined
            }
            const newValues = parsePriceOverrideValuesFromForm(priceOverride)
            return getPriceOverrideToSave(
                newValues.description,
                newValues.price,
                currency,
                market,
                newValues.isModifier,
                p,
                invoicingMerchantConfig.priceOverrides.find((po) => po.priceCategory === p.priceCategory)
            )
        })
        .filter(hasValue)

    const customPriceData = pricesSubForm.customPrices || {}
    const customPriceIds = Object.keys(customPriceData)
    const customPricesToSave = customPriceIds
        .map((id: string) => {
            const saved = invoicingMerchantConfig?.customPrices.find(
                (cps) => cps.priceCategory === customPriceData[id]!.priceCategory && !cps.isReadOnly
            )
            return parseMerchantCustomPrice(customPriceData[id]!, "ValueForEachPeriod", currency, prices, saved)
        })
        .filter(hasValue)
    const idsToRemove: string[] = []
    return {
        priceOverrides: newOverrides,
        customPrices: customPricesToSave,
        idsToRemove,
    }
}

function parseCustomPriceValues(values: IFormCustomPrice, type: TMerchantCustomPrice) {
    const newDescription = values.description

    let newPrice = parsePriceAmountValue(values.price)

    const newPeriodsVal = (values.periods ?? "").trim()
    let newPeriods = newPeriodsVal === "" ? 0 : parseFloat(newPeriodsVal)
    if (isNaN(newPeriods ?? 0) || type !== "ValueForEachPeriod") {
        newPeriods = 0
    }

    const newTypeVal = parseInt(values.type)
    let newIsDiscount = newTypeVal === 1
    const newIsReplace = type === "ValueForEachPeriod" && newTypeVal === 2

    if (typeof newPrice !== "undefined" && newPrice < 0) {
        newPrice = 0
        newIsDiscount = false
    }

    return {
        description: newDescription,
        price: newPrice,
        isDiscount: newIsDiscount,
        isReplace: newIsReplace,
        periods: newPeriods,
    }
}

function parseMerchantCustomPrice(
    values: IFormCustomPrice,
    type: TMerchantCustomPrice,
    currency: string,
    prices: IInvoicingPrice[],
    customPriceSaved?: IInvoicingCustomPrice
): IInvoicingCustomPrice | undefined {
    const newPriceId = prices.find((p) => p.priceCategory === "Custom")?.id ?? ""
    const { description, price, isDiscount, isReplace, periods } = parseCustomPriceValues(values, type)

    if (customPriceSaved) {
        // Update existing
        if (customPriceSaved.isReadOnly) {
            // Not allowed
            return undefined
        }
        if (
            customPriceSaved.amount === price &&
            customPriceSaved.description === description &&
            customPriceSaved.isDiscount === isDiscount &&
            customPriceSaved.isReplace === isReplace &&
            customPriceSaved.priceId === newPriceId &&
            customPriceSaved.periods === periods &&
            customPriceSaved.priceCategory === values.priceCategory
        ) {
            // No change
            return undefined
        }
        return {
            ...customPriceSaved,
            amount: price,
            amountLeft: price ?? 0,
            description: description,
            isDiscount: isDiscount,
            isReplace: isReplace,
            customPriceType: type,
            periods: periods,
            periodsLeft: periods,
            priceId: newPriceId,
            priceCategory: type == "ValueForEachPeriod" ? values.priceCategory : undefined,
        }
    }

    if (!price && price !== 0) {
        return undefined
    }

    if (type == "ValueForEachPeriod" && !periods) {
        return undefined
    }

    // New
    return {
        amount: price,
        amountLeft: price ?? 0,
        currency: currency,
        description: description,
        isDiscount: isDiscount,
        isReplace: isReplace,
        customPriceType: type,
        periods: periods,
        periodsLeft: periods,
        id: "",
        isReadOnly: false,
        priceId: newPriceId,
        priceCategory: type == "ValueForEachPeriod" ? values.priceCategory : undefined,
    }
}

export function parsePriceAmountValue(amountStr: string) {
    const newPriceVal = (amountStr ?? "").trim()
    if (newPriceVal === "") {
        return undefined
    }
    const newPrice = parseFloat(newPriceVal)
    return isNaN(newPrice) ? undefined : newPrice
}

function parsePriceOverrideValuesFromForm(override: IFormOverride) {
    const newTypeVal = parseInt(override.isModifier)
    const newIsModifer = newTypeVal === 1
    return {
        description: override.description,
        price: parsePriceAmountValue(override.price),
        isModifier: newIsModifer,
    }
}

function getPriceOverrideToSave(
    description: string | undefined,
    price: number | undefined,
    currency: string,
    market: EInvoicingMarket,
    isModifier: boolean,
    basePrice: IInvoicingPrice,
    priceOverride: IInvoiceingPriceOverride | undefined
) {
    if (priceOverride) {
        if (!price && price !== 0 && priceOverride.isModifier) {
            // Cannot remove legacy overrides with empty data - because legacy data is not added to the form
            return undefined
        }
        if (
            description !== priceOverride.description ||
            price !== priceOverride.amount ||
            isModifier !== priceOverride.isModifier
        ) {
            // Change detected
            const newOverride: IInvoiceingPriceOverride = {
                amount: price,
                currency: currency,
                market: market,
                description: description,
                isModifier: isModifier,
                priceCategory: basePrice.priceCategory,
                created: new Date().toISOString(),
            }
            return newOverride
        } else {
            // PriceOverride not changed
            return undefined
        }
    }

    if (!price && price !== 0) {
        // Empty and no overrides - ignore
        return undefined
    }
    if (price === 0 && isModifier) {
        // No change - ignore
        return undefined
    }

    if (price !== basePrice.amount || isModifier) {
        // First change to price for merchant
        const newOverride: IInvoiceingPriceOverride = {
            amount: price,
            currency: currency,
            market: market,
            description: description,
            isModifier: isModifier,
            priceCategory: basePrice.priceCategory,
            created: new Date().toISOString(),
        }
        return newOverride
    }

    return undefined
}

export function mapInvoicingMerchantConfigToCustomPriceSubForm(
    invoicingMerchantConfig?: IInvoicingMerchantConfig,
    shouldPrepareForReset?: boolean
): IMerchantCustomPricesSubForm {
    const baseCustomPrices = (invoicingMerchantConfig?.customPrices ?? []).filter(
        (cp) => !!cp.priceCategory && !cp.isReadOnly && cp.isReplace
    )
    const customPrices = shouldPrepareForReset ? extendCustomPricesWithOthersInSection(baseCustomPrices) : baseCustomPrices
    return {
        priceOverrides: toDict(
            (invoicingMerchantConfig?.priceOverrides ?? []).filter((cp) => !cp.isModifier),
            (po) => po.priceCategory,
            (po) => ({
                priceCategory: po.priceCategory,
                description: po.description,
                isModifier: po.isModifier ? "1" : "0",
                price: typeof po.amount === "undefined" ? "" : numberToFixedDecimals(po.amount, 2),
            })
        ),
        customPrices: toDict(
            customPrices,
            (cp) => cp.priceCategory!,
            (cp) => ({
                description: cp.description,
                periods: numberToFixedDecimals(cp.periods, 0),
                price: typeof cp.amount === "undefined" ? "" : numberToFixedDecimals(cp.amount, 2),
                priceCategory: cp.priceCategory!,
                type: cp.isReplace ? "2" : cp.isDiscount ? "1" : "0",
            })
        ),
    }
}

function extendCustomPricesWithOthersInSection(customPrices: IInvoicingCustomPrice[]): IInvoicingCustomPrice[] {
    const relevantCustomPrices = customPrices.filter(
        (cp) => !cp.isReadOnly && !cp.isDiscount && cp.isReplace && cp.customPriceType === "ValueForEachPeriod"
    )
    return [
        ...customPrices,
        ...merchantCustomPriceSections
            .map((s) => {
                const cps = relevantCustomPrices.filter((cp) => s.prices.find((p) => cp.priceCategory === p.category))
                const cpWithPeriods = cps.find((cp) => cp.periods)
                if (!cpWithPeriods) {
                    return []
                }
                // Add objects only containing the periods for the prices in the section without a value, because the input is shared across the section
                return [
                    ...s.prices
                        .filter((p) => !cps.find((cp) => cp.priceCategory === p.category))
                        .map((p) => ({
                            ...cpWithPeriods,
                            amount: undefined,
                            description: undefined,
                            priceCategory: p.category,
                            id: p.category,
                        })),
                ]
            })
            .flat(),
    ]
}

export interface ICustomPricesSection {
    type: string
    title: string
    prices: {
        category: TInvoicingPriceCategory
        showType?: "maxFee"
    }[]
}

export const merchantCustomPriceSections: ICustomPricesSection[] = [
    { type: "acquiring", title: "Acquiring", prices: [{ category: "AcquirerTransactionPct" }] },
    {
        type: "psp",
        title: "Swiipe PSP",
        prices: [
            { category: "SwiipePspMonthly" },
            { category: "SwiipePspExtraWebshopMonthly" },
            { category: "SwiipePspTransaction" },
        ],
    },
    {
        type: "mobilePay",
        title: "Mobile Pay",
        prices: [{ category: "MobilePayOnlineMonthly" }, { category: "MobilePayOnlineTransactionStep1" }],
    },
    {
        type: "klarna",
        title: "Klarna",
        prices: [{ category: "KlarnaTransaction" }],
    },
    {
        type: "applePay",
        title: "Apple Pay",
        prices: [{ category: "ApplePayTransaction" }],
    },
    {
        type: "resurs",
        title: "Resurs",
        prices: [
            { category: "ResursInvoicePctTransaction" },
            { category: "ResursPartPaymentPctTransaction" },
            { category: "ResursRevolvingCreditPctTransaction" },
        ],
    },
    {
        type: "swish",
        title: "Swish",
        prices: [{ category: "SwishTransaction" }],
    },
    {
        type: "winBack",
        title: "Win-back",
        prices: [{ category: "SwiipeWinbackPct" }, { category: "SwiipeWinbackMaxOrderAmount", showType: "maxFee" }],
    },
    {
        type: "plusSell",
        title: "Plus-sell",
        prices: [{ category: "SwiipePlusSellPct" }],
    },
    {
        type: "returnSell",
        title: "Return-sell",
        prices: [
            { category: "SwiipeReturnSellMonthly" },
            { category: "SwiipeReturnSellExtraWebshopMonthly" },
            { category: "SwiipeReturnSellTransactionPct" },
        ],
    },
]

export function getActivePriceCategoriesFromSubForm(subForm: IMerchantCustomPricesSubForm) {
    const poCategories = getKeys(subForm.priceOverrides).filter((cat) => subForm.priceOverrides[cat]?.price)
    const cpCategories = getValues(subForm.customPrices)
        .filter((cp) => cp?.price || cp?.periods)
        .map((cp) => cp!.priceCategory)
    return getDistinct([...poCategories, ...cpCategories])
}

export function getActivePriceCategoriesFromMerchantConfig(invoicingMerchantConfig: IInvoicingMerchantConfig) {
    return getDistinct([
        ...invoicingMerchantConfig.priceOverrides.map((po) => po.priceCategory),
        ...invoicingMerchantConfig.customPrices.map((cp) => cp.priceCategory).filter(hasValue),
    ])
}

export function getPriceSectionTypesFromPriceCategories(priceCategories: TInvoicingPriceCategory[]) {
    return merchantCustomPriceSections
        .filter((ps) => !!ps.prices.find((p) => priceCategories.includes(p.category)))
        .map((ps) => ps.type)
}
