import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IHealthStatusDto } from "../../type/healthStatus/IHealthStatusDto"
import { TPayment } from "../../type/TPayment"
import { StoreState } from "../StoreState"

export type HealthStatusState = Readonly<{
    healthStatuses: { [webshopId: string]: IHealthStatusDto[] }
    // Currently the same is fetched for all webshops, but added like this, to in the future filter by enabled per webshop
    paymentTypes: { [webshopId: string]: { name: TPayment; isPiq: boolean }[] }
}>

const initialState: HealthStatusState = {
    healthStatuses: {},
    paymentTypes: {},
}

export const healthStatusReducerActions = {
    setHealthStatuses: (isGlobal: boolean, webshopId: string | undefined, healthStatuses: IHealthStatusDto[]) =>
        createAction("APP/SET_HEALTH_STATUSES", { isGlobal, webshopId, healthStatuses }),
    setPaymentTypes: (isGlobal: boolean, webshopId: string | undefined, paymentTypes: { name: TPayment; isPiq: boolean }[]) =>
        createAction("APP/SET_HEALTH_STATUS_PAYMENT_TYPES", { isGlobal, webshopId, paymentTypes }),
}

function getHealthStatusKey(isGlobal: boolean, webshopId: string | undefined) {
    return isGlobal ? "global" : webshopId ?? ""
}

export type HealthStatusActions = ActionsUnion<typeof healthStatusReducerActions>

export const healthStatusReducer = (state: HealthStatusState = initialState, action: HealthStatusActions) => {
    switch (action.type) {
        case "APP/SET_HEALTH_STATUSES": {
            return {
                ...state,
                healthStatuses: {
                    ...state.healthStatuses,
                    [getHealthStatusKey(action.isGlobal, action.webshopId)]: action.healthStatuses,
                },
            }
        }
        case "APP/SET_HEALTH_STATUS_PAYMENT_TYPES": {
            return {
                ...state,
                paymentTypes: {
                    ...state.paymentTypes,
                    [getHealthStatusKey(action.isGlobal, action.webshopId)]: action.paymentTypes,
                },
            }
        }
        default: {
            return state
        }
    }
    return state
}

export const healthStatusSelectors = {
    healthStatuses: (state: StoreState, isGlobal: boolean, webshopId: string | undefined) =>
        state.healthStatus.healthStatuses[getHealthStatusKey(isGlobal, webshopId)],
    paymentTypes: (state: StoreState, isGlobal: boolean, webshopId: string | undefined) =>
        state.healthStatus.paymentTypes[getHealthStatusKey(isGlobal, webshopId)],
}
