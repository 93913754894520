export interface ILanguage {
    code: string
    label: string
}

const langs: ILanguage[] = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
    { code: "sv", label: "Swedish" },
    { code: "no", label: "Norwegian" },
    { code: "pl", label: "Polish" },
]

export const languagesIncludeShared: ILanguage[] = [{ code: "shared", label: "Shared" }, ...langs]
export const languages = langs
