export function prepareFormData<T extends { [key: string]: any }, K extends keyof T>(form: T, checkboxProps: K[]) {
    ;(Object.keys(form) as K[]).forEach(function (key: K, index) {
        const value = form[key]
        if (typeof value === "string") {
            form[key] = value.trim()
            if ((key as string).toLowerCase().indexOf("email") >= 0) {
                // Email should always be lower case
                form[key] = form[key].toLowerCase()
            }

            if (checkboxProps.indexOf(key) >= 0) {
                // Form checkbox value is string ("on") instead of boolean (true/false)
                // Make it into a boolean
                ;(form[key] as boolean) = value === "on"
            }
        }
    })
    return form
}

export function serializeRequestAsFormData(requestData?: any) {
    if (!requestData) {
        return
    }

    const formData = new FormData()

    const serializeObject = (obj: any, prefix?: string) => {
        Object.keys(obj).forEach((key) => {
            const propName = prefix ? `${prefix}[${key}]` : key
            const propValue = obj[key]

            if (typeof propValue === "undefined") {
                return
            }

            //handle arrays
            if (Array.isArray(propValue)) {
                propValue.forEach((el) => {
                    formData.append(propName, el)
                })
                return
            }

            //handle objects
            if (Object.prototype.toString.call(propValue) === "[object Object]") {
                serializeObject(propValue, propName)
                return
            }

            formData.append(propName, propValue)
        })
    }

    serializeObject(requestData)

    return formData
}
