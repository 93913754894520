import { da, enGB, pl, sv } from "date-fns/locale"
import { registerLocale, setDefaultLocale } from "react-datepicker"

export function getCultureForCountry(countryCode: string | undefined): string | undefined {
    if (!countryCode) {
        return undefined
    }
    switch (countryCode.toLowerCase()) {
        case "dk":
            return "da-DK"
        case "en":
            return "en-GB"
        case "se":
            return "se-SV"
        case "pl":
            return "pl-PL"
    }
    return undefined
}

export function getCultureForLanguage(language: string | undefined): string | undefined {
    if (!language) {
        return undefined
    }
    switch (language.toLowerCase()) {
        case "da":
            return "da-DK"
        case "en":
            return "en-GB"
        case "se":
            return "se-SV"
        case "pl":
            return "pl-PL"
    }
    return language
}

export function setLocaleForDatepicker({
    language,
    countryCode,
}: {
    language: string | undefined
    countryCode: string | undefined
}) {
    const l = getCultureForLanguage(language)
    const countryFromLanguage = l && l.split("-").length > 1 ? l.split("-")[1].toLowerCase() : undefined
    const resolvedCountryCode = countryFromLanguage || countryCode

    if (!resolvedCountryCode) {
        registerLocale("en", enGB)
        setDefaultLocale("en")
        return
    }
    switch (resolvedCountryCode.toLowerCase()) {
        case "dk":
            registerLocale("da", da)
            setDefaultLocale("da")
            return
        case "en":
            registerLocale("en", enGB)
            setDefaultLocale("en")
            return
        case "se":
            registerLocale("sv", sv)
            setDefaultLocale("sv")
            return
        case "pl":
            registerLocale("pl", pl)
            setDefaultLocale("pl")
            return
        default:
            registerLocale("en", enGB)
            setDefaultLocale("en")
    }
}
