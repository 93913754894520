import cn from "classnames"
import React from "react"
import "./FormInputColumnWithInfoColumn.scss"

interface IFormInputColumnWithInfoColumn {
    children: React.ReactNode
    infoColumn?: React.ReactNode
    infoFullWidth?: boolean
    infoNarrowWidth?: boolean
    className?: string
}

export const FormInputColumnWithInfoColumn = ({
    className,
    children,
    infoColumn,
    infoFullWidth,
    infoNarrowWidth,
}: IFormInputColumnWithInfoColumn) => {
    return (
        <div className={cn("form-input-column-with-info-column", className)}>
            <div className="form-input-column-with-info-column__input-column">{children}</div>
            <div
                className={cn(
                    "form-input-column-with-info-column__info-column-container",
                    !infoFullWidth && "form-input-column-with-info-column__info-column-container--restrict-width",
                    infoNarrowWidth && "form-input-column-with-info-column__info-column-container--narrow-width"
                )}
            >
                <div className="form-input-column-with-info-column__info-column">{infoColumn}</div>
            </div>
        </div>
    )
}
