export interface ISmsTemplateType {
    type: string
    label: string
    exampleData: { [key: string]: any }
}

const swiipeWinbackBaseData = {
    customersName: undefined,
    webshopName: "example.com",
    winbackLink: "https://winbacklinkexample.com",
    nemIDAppLink: "https://www.nemid.nu/dk-da/kom_i_gang_med_nemid/nemid_nogleapp/",
    signer: "John Doe from example.com",
}

const smsTemplateTypes: ISmsTemplateType[] = [
    {
        type: "WinbackNotificationDefault",
        label: "Win-back SMS Notification (Default)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotification3DSIssues",
        label: "Win-back SMS Notification (3DS related issues)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationCancelledByUser",
        label: "Win-back SMS Notification (Payment cancelled by user)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationNoFunds",
        label: "Win-back SMS Notification (No funds)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationCardAccountBankIssues",
        label: "Win-back SMS Notification (Card, account or bank issues)",
        exampleData: swiipeWinbackBaseData,
    },

    // Start old WinbackNotifications
    {
        type: "WinbackNotification",
        label: "Winback SMS Notification",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt1",
        label: "Winback SMS Notification - Alt 1",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt2",
        label: "Winback SMS Notification - Alt 2",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt3",
        label: "Winback SMS Notification - Alt 3",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "WinbackNotificationAlt4",
        label: "Winback SMS Notification - Alt 4",
        exampleData: swiipeWinbackBaseData,
    },

    // Start Winback split test

    {
        type: "wb3DS-A",
        label: "Win-back SMS Notification (3DS related issues - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wb3DS-B",
        label: "Win-back SMS Notification (3DS related issues - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbBankIssues-A",
        label: "Win-back SMS Notification (Card, account or bank issues - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbBankIssues-B",
        label: "Win-back SMS Notification (Card, account or bank issues - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbNoFunds-A",
        label: "Win-back SMS Notification (No Funds - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbNoFunds-B",
        label: "Win-back SMS Notification (No Funds - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbUserCancelled-A",
        label: "Win-back SMS Notification (Payment cancelled by user - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbUserCancelled-B",
        label: "Win-back SMS Notification (Payment cancelled by user - B)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbDefault-A",
        label: "Win-back SMS Notification (Default - A)",
        exampleData: swiipeWinbackBaseData,
    },
    {
        type: "wbDefault-B",
        label: "Win-back SMS Notification (Default - B)",
        exampleData: swiipeWinbackBaseData,
    },

    // End Winback split test

    {
        type: "HealthStatusIssueNotification",
        label: "Health Status Issue SMS Notification",
        exampleData: {
            environment: "Test",
            providers: "PaymentIQ, Aiia",
            paymentTypes: "CreditCard, MobilePayOnline, A2A",
            lastSuccessUtc: "2022-08-10 10:34:12.000Z",
            hostNames: "niceSite1.com, greatSite2.dk, cozySite.net, bigSite.com, myFav.com",
        },
    },
    {
        type: "PaymentIssuesAlertNotification",
        label: "Payment Issues Alert SMS Notification",
        exampleData: {
            alertTrigger: "PaymentsGeneralFailure",
            alertLevel: "Error",
            webshopName: "General check",
            fromDate: new Date().toISOString(),
            toDate: new Date().toISOString(),
            threshold: "30%",
            totalTransactions: "100",
            failedTransactions: "50",
            failedRatio: "50%",
            hostNames: "niceSite1.com, greatSite2.dk, cozySite.net, bigSite.com, myFav.com",
            environment: "Test",
        },
    },
    {
        type: "PaymentLinkNotification",
        label: "Payment Link Notification",
        exampleData: {
            platformOrderId: "PL228",
            webshopName: "example.com",
            paymentLink: "https://secure.swiipe.com/paymentlink/be020c2f-ee65-46c1-9fd4-696444e43b44",
            signedBy: "Jan From Swiipe",
        },
    },
]

export default smsTemplateTypes
