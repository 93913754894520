export interface ITermsLanguage {
    code: string
    label: string
}

export const termsLanguages: ITermsLanguage[] = [
    { code: "en", label: "English" },
    { code: "da", label: "Danish" },
    { code: "sv", label: "Swedish" },
    { code: "no", label: "Norwegian" },
    { code: "pl", label: "Polish" },
]
