import React from "react"
import { Toaster } from "react-hot-toast"
import { Container } from "reactstrap"
import { ModalRenderer } from "swiipe.portal.shared"
import { useShowLoggedInMenus } from "../../services/authService"
import { RenderModal, TAllModals } from "../modal/common/TAllModals"
import NavBar from "./../menu/NavBar"
import NavMenu from "./../menu/NavMenu"
import Sidebar from "./../menu/Sidebar"

interface IProps {
    children?: React.ReactNode
}
const MainLayout = ({ children }: IProps) => {
    const showLoggedInMenus = useShowLoggedInMenus()
    return (
        <div className="swiipe-outer-container">
            <div id="cookie-expiration-extender"></div>
            <Container id="app-container" fluid>
                <div className={!showLoggedInMenus ? "no-sidebar" : ""} id={showLoggedInMenus ? "wrapper" : ""}>
                    <Sidebar>
                        <NavMenu />
                    </Sidebar>
                    <div>
                        <div id="main-col">
                            <div id="main">
                                <NavBar />
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalRenderer<TAllModals>
                    renderModal={(modalWithoutCallback, controlProps) => RenderModal(modalWithoutCallback, controlProps)}
                />
            </Container>
            <Toaster />
        </div>
    )
}
export default MainLayout
