import { EInvoicingMarket, IInvoicingPrice } from "swiipe.portal.shared"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IInvoicingOrganization } from "../../type/invoicing/IInvoicingOrganization"
import { StoreState } from "../StoreState"
import { IInvoicingMerchantConfig } from "./../../type/IInvoicing"

export type InvoicingState = Readonly<{
    merchantConfigs: { [merchantId: string]: IInvoicingMerchantConfig }
    prices: { [merchantId: string]: IInvoicingPrice[] }
    publicPrices: { [market in EInvoicingMarket]?: IInvoicingPrice[] }
    resolvedPrices: { [merchantId: string]: IInvoicingPrice[] }
    pricesCustom: { [merchantId: string]: IInvoicingPrice[] }
    invoicingOrganizations: { [organizationId: string]: IInvoicingOrganization | undefined }
}>

const initialState: InvoicingState = {
    merchantConfigs: {},
    prices: {},
    publicPrices: {},
    resolvedPrices: {},
    pricesCustom: {},
    invoicingOrganizations: {},
}

export const invoicingReducerActions = {
    setMerchantConfig: (merchantId: string, merchantConfig: IInvoicingMerchantConfig) =>
        createAction("APP/SET_INVOICING_MERCHANT_CONFIG", { merchantId, merchantConfig }),
    setPrices: (
        merchantId: string,
        prices: IInvoicingPrice[],
        resolvedPrices: IInvoicingPrice[],
        pricesCustom: IInvoicingPrice[]
    ) => createAction("APP/SET_INVOICING_PRICES", { merchantId, prices, resolvedPrices, pricesCustom }),
    setPublicPrices: (market: EInvoicingMarket, prices: IInvoicingPrice[]) =>
        createAction("APP/SET_PUBLIC_INVOICING_PRICES", { market, prices }),
    setInvoicingOrganization: (organizationId: string, organization: IInvoicingOrganization) =>
        createAction("APP/SET_INVOICING_ORGANIZATION", { organizationId, organization }),
}

export type InvoicingActions = ActionsUnion<typeof invoicingReducerActions>

export const invoicingReducer = (state: InvoicingState = initialState, action: InvoicingActions) => {
    switch (action.type) {
        case "APP/SET_INVOICING_MERCHANT_CONFIG": {
            return {
                ...state,
                merchantConfigs: {
                    ...state.merchantConfigs,
                    [action.merchantId]: action.merchantConfig,
                },
            }
        }
        case "APP/SET_INVOICING_PRICES": {
            return {
                ...state,
                prices: {
                    ...state.prices,
                    [action.merchantId]: action.prices,
                },
                resolvedPrices: {
                    ...state.resolvedPrices,
                    [action.merchantId]: action.resolvedPrices,
                },
                pricesCustom: {
                    ...state.pricesCustom,
                    [action.merchantId]: action.pricesCustom,
                },
            }
        }
        case "APP/SET_PUBLIC_INVOICING_PRICES": {
            return {
                ...state,
                publicPrices: {
                    ...state.publicPrices,
                    [action.market]: action.prices,
                },
            }
        }
        case "APP/SET_INVOICING_ORGANIZATION": {
            return {
                ...state,
                invoicingOrganizations: {
                    ...state.invoicingOrganizations,
                    [action.organizationId]: action.organization,
                },
            }
        }
    }

    return state
}

export const invoicingSelectors = {
    merchantConfig: (state: StoreState, swMerchantId: string) => state.invoicing.merchantConfigs[swMerchantId],
    pricesForRelation: (state: StoreState, invoicingRelationId: string) => state.invoicing.prices[invoicingRelationId],
    resolvedPricesForRelation: (state: StoreState, invoicingRelationId: string) =>
        state.invoicing.resolvedPrices[invoicingRelationId],
    prices: (state: StoreState, invoicingRelationId: string) => state.invoicing.prices[invoicingRelationId],
    publicPrices: (state: StoreState, market: EInvoicingMarket) => state.invoicing.publicPrices[market],
    pricesCustom: (state: StoreState) =>
        state.userRelation.currentRelation ? state.invoicing.pricesCustom[state.userRelation.currentRelation.id] : undefined,
    invoicingOrganization: (state: StoreState) =>
        state.userRelation.currentRelation
            ? state.invoicing.invoicingOrganizations[state.userRelation.currentRelation.id]
            : undefined,
}
