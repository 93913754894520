import cn from "classnames"
import React from "react"
import "./CloseIcon.scss"

interface ICloseIcon {
    className?: string
}

export const CloseIcon = ({ className, ...otherProps }: ICloseIcon & React.HTMLAttributes<HTMLSpanElement>) => {
    return <span className={cn("close-btn-component", className)} {...otherProps} />
}
