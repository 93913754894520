import cn from "classnames"
import React, { CSSProperties, useEffect, useState } from "react"
import { Control } from "react-hook-form"
import {
    AccordionWithChildren,
    DropDownList,
    EInvoicingMarket,
    FloatingLabelInput,
    IFormBase,
    IInvoiceingPriceOverride,
    IInvoicingCustomPrice,
    IInvoicingPrice,
    TInvoicingPriceCategory,
    TitleWithAbsoluteHint,
    getDistinct,
    getFormattedNumber,
    useSelector,
    valFuncPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getPricesForSwiipePlan } from "../../../services/invoicingService"
import {
    ICustomPricesSection,
    IMerchantCustomPricesSubForm,
    getActivePriceCategoriesFromMerchantConfig,
    getPriceSectionTypesFromPriceCategories,
    mapInvoicingMerchantConfigToCustomPriceSubForm,
    merchantCustomPriceSections,
} from "../../../services/merchantCustomPricesService"
import { StoreState } from "../../../store/StoreState"
import { invoicingSelectors } from "../../../store/reducers/invoicingReducer"
import {
    getInvoicingPricesThunk,
    getPublicInvoicingPricesThunk,
    setEmptyInvoicingMerchantConfigThunk,
} from "../../../store/thunks/invoicingThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import { IInvoicingMerchantConfig } from "../../../type/IInvoicing"
import { TSwiipePlan } from "../../../type/IProvider"
import { formatLocalDate } from "../../../util/dateUtil"
import Adder from "../../common/Adder"
import "./MerchantCustomPricesForm.scss"

interface IMerchantCustomPricesFormProps<TForm extends IFormBase> {
    market: EInvoicingMarket
    control: Control<TForm>
    reset: (subForm: IMerchantCustomPricesSubForm) => void
    getFieldsFromControl: (form: TForm | undefined) => IMerchantCustomPricesSubForm | undefined
    fieldSelectorPrefix: string
    sectionDisplayType: "allAsAccordians" | "dropDownListChooseAndAdd"
    sectionIndentLeftSize?: number
    sectionIndentRightSize?: number
    merchantOrPreOnboardingId: string
    invoicingMerchantConfig: IInvoicingMerchantConfig | undefined
    isPreOnboarding: boolean
    initialActiveSectionsForPreOnboarding?: string[]
}

const MerchantCustomPricesForm = <TForm extends IFormBase>({
    market,
    reset,
    control,
    getFieldsFromControl,
    fieldSelectorPrefix,
    sectionDisplayType,
    sectionIndentLeftSize,
    sectionIndentRightSize,
    merchantOrPreOnboardingId,
    invoicingMerchantConfig,
    isPreOnboarding,
    initialActiveSectionsForPreOnboarding,
}: IMerchantCustomPricesFormProps<TForm>) => {
    const dispatch = useReduxDispatch()
    const invoicingPrices = useSelector((state: StoreState) => invoicingSelectors.prices(state, merchantOrPreOnboardingId))
    const publicInvoicingPrices = useSelector((state: StoreState) => invoicingSelectors.publicPrices(state, market))
    const [activeSections, setActiveSections] = useState<string[]>([])
    const [highlightedSections, setHighlightedSections] = useState<string[]>([])
    const [initializedMerchantId, setInitializedMerchantId] = useState<string | undefined>(undefined)

    const activeSectionsResolved =
        activeSections.length === 0 && sectionDisplayType === "dropDownListChooseAndAdd" ? ["select"] : activeSections

    const prices = invoicingPrices ?? getPricesForSwiipePlan(publicInvoicingPrices ?? [], TSwiipePlan.Basic)
    const currency = prices && prices.length > 0 ? prices[0].currency : "DKK"

    const priceOverrides = invoicingMerchantConfig?.priceOverrides ?? []

    const customPrices = (invoicingMerchantConfig?.customPrices ?? []).filter(
        (cp) => !!cp.priceCategory && cp.customPriceType === "ValueForEachPeriod"
    )

    // const subForm = getFieldsFromControl(control.getValues() as TForm)
    useEffect(() => {
        if (isPreOnboarding) {
            if (!initialActiveSectionsForPreOnboarding) {
                return
            }
            dispatch(setEmptyInvoicingMerchantConfigThunk(merchantOrPreOnboardingId))

            if (initializedMerchantId !== merchantOrPreOnboardingId) {
                setHighlightedSections(initialActiveSectionsForPreOnboarding ?? [])
                setInitializedMerchantId(merchantOrPreOnboardingId)
                if (
                    sectionDisplayType === "dropDownListChooseAndAdd" &&
                    (initialActiveSectionsForPreOnboarding ?? []).length === 0
                ) {
                    setActiveSections(["select"])
                } else {
                    setActiveSections(initialActiveSectionsForPreOnboarding ?? [])
                }
                return
            }
        } else {
            dispatch(getInvoicingPricesThunk(merchantOrPreOnboardingId, "Merchant"))
        }
        if (initializedMerchantId && initializedMerchantId !== merchantOrPreOnboardingId) {
            setInitializedMerchantId(undefined)
        }
    }, [merchantOrPreOnboardingId, initialActiveSectionsForPreOnboarding])

    useEffect(() => {
        if (invoicingMerchantConfig && !isPreOnboarding) {
            const sectionTypesWithActiveCategories = getPriceSectionTypesFromPriceCategories(
                getActivePriceCategoriesFromMerchantConfig(invoicingMerchantConfig)
            )

            setHighlightedSections(sectionTypesWithActiveCategories)

            if (initializedMerchantId !== merchantOrPreOnboardingId) {
                setInitializedMerchantId(merchantOrPreOnboardingId)
                if (sectionDisplayType === "dropDownListChooseAndAdd" && sectionTypesWithActiveCategories.length === 0) {
                    setActiveSections(["select"])
                } else {
                    setActiveSections(sectionTypesWithActiveCategories)
                }
            }
        }
    }, [merchantOrPreOnboardingId, invoicingMerchantConfig])

    useEffect(() => {
        if (!invoicingMerchantConfig) {
            return
        }
        reset(mapInvoicingMerchantConfigToCustomPriceSubForm({ priceOverrides, customPrices }, true))
    }, [invoicingMerchantConfig])

    useEffect(() => {
        dispatch(getPublicInvoicingPricesThunk(market, false))
    }, [market])

    return (
        <div className="merchant-custom-prices-form">
            {sectionDisplayType === "dropDownListChooseAndAdd" && (
                <div className="merchant-custom-prices-form__ddl-section">
                    {activeSectionsResolved.map((secType, index) => {
                        const section = merchantCustomPriceSections.find((ps) => ps.type === secType)
                        return (
                            <div key={index}>
                                <DropDownList
                                    className={cn(
                                        "merchant-custom-prices-form__ddl-section-ddl",
                                        secType !== "select" && "merchant-custom-prices-form__ddl-section-ddl--highlighted"
                                    )}
                                    smallBorderRadius
                                    options={[
                                        { text: "Select service", value: "select" },
                                        ...merchantCustomPriceSections
                                            .filter((ps) => !activeSectionsResolved.includes(ps.type) || ps.type === secType)
                                            .map((ps) => ({ text: ps.title, value: ps.type })),
                                    ]}
                                    value={secType}
                                    onChange={(e) => {
                                        const newActiveSessions = [...activeSectionsResolved]
                                        newActiveSessions[index] = e.currentTarget.value
                                        setActiveSections(newActiveSessions)
                                    }}
                                />
                                {section && (
                                    <MerchantCustomPricesFormSection
                                        control={control}
                                        getFieldsFromControl={getFieldsFromControl}
                                        fieldSelectorPrefix={fieldSelectorPrefix}
                                        customPrices={customPrices}
                                        prices={prices}
                                        priceOverrides={priceOverrides}
                                        section={section}
                                    />
                                )}
                                {(index < activeSectionsResolved.length - 1 || !activeSectionsResolved.includes("select")) && (
                                    <hr />
                                )}
                            </div>
                        )
                    })}
                    {!activeSectionsResolved.includes("select") && (
                        <Adder
                            className="merchant-custom-prices-form__ddl-section-adder"
                            buttonText="ADD ANOTHER SERVICE"
                            alignLeft
                            noPadding
                            onClick={() => setActiveSections([...activeSectionsResolved, "select"])}
                        />
                    )}
                </div>
            )}
            {sectionDisplayType === "allAsAccordians" &&
                merchantCustomPriceSections.map((sec) => {
                    return (
                        <div
                            key={sec.type}
                            className={cn(
                                "merchant-custom-prices-form__section",
                                highlightedSections.includes(sec.type) && "merchant-custom-prices-form__section--highlighted"
                            )}
                            style={{
                                ...(sectionIndentLeftSize ? { paddingLeft: sectionIndentLeftSize } : {}),
                                ...(sectionIndentRightSize ? { paddingRight: sectionIndentRightSize } : {}),
                            }}
                        >
                            <AccordionWithChildren
                                header={sec.title}
                                leftAlignHeader
                                classNameHeader="merchant-custom-prices-form__accordian-header"
                                isExpanded={activeSectionsResolved.includes(sec.type)}
                                expandedDidChange={(isExpanded: boolean) => {
                                    if (isExpanded) {
                                        setActiveSections(getDistinct([...activeSectionsResolved, sec.type]))
                                    } else {
                                        setActiveSections(activeSectionsResolved.filter((es) => es !== sec.type))
                                    }
                                }}
                            >
                                <div className="merchant-custom-prices-form__accordian-inner">
                                    <MerchantCustomPricesFormSection
                                        control={control}
                                        getFieldsFromControl={getFieldsFromControl}
                                        fieldSelectorPrefix={fieldSelectorPrefix}
                                        customPrices={customPrices}
                                        prices={prices}
                                        priceOverrides={priceOverrides}
                                        section={sec}
                                    />
                                </div>
                            </AccordionWithChildren>
                        </div>
                    )
                })}
        </div>
    )
}

interface IMerchantCustomPricesFormSectionProps<TForm extends IFormBase> {
    control: Control<TForm>
    getFieldsFromControl: (form: TForm | undefined) => IMerchantCustomPricesSubForm | undefined
    fieldSelectorPrefix: string
    prices: IInvoicingPrice[]
    customPrices: IInvoicingCustomPrice[]
    priceOverrides: IInvoiceingPriceOverride[]
    section: ICustomPricesSection
}

const MerchantCustomPricesFormSection = <TForm extends IFormBase>({
    control,
    getFieldsFromControl,
    fieldSelectorPrefix,
    prices,
    priceOverrides,
    customPrices,
    section,
}: IMerchantCustomPricesFormSectionProps<TForm>) => {
    const getOverridesFieldSelector = (fieldName: string, category: TInvoicingPriceCategory) =>
        `${fieldSelectorPrefix}.priceOverrides[${category}].${fieldName}`
    const getCustomPriceFieldSelector = (fieldName: string, category: TInvoicingPriceCategory) =>
        `${fieldSelectorPrefix}.customPrices[${category}].${fieldName}`

    return (
        <div className="merchant-custom-prices-form-section">
            <div className="merchant-custom-prices-form-section__sub-title">Custom price</div>
            {priceOverrides
                .filter((po) => po.isModifier && !!section.prices.find((p) => p.category === po.priceCategory))
                .map((po, index) => {
                    const price = prices.find((price) => price.priceCategory === po.priceCategory)
                    if (!price) {
                        return
                    }
                    return (
                        <PriceRow
                            key={index}
                            highlight
                            leftText={price.description}
                            centerContent={`${po.amount} ${price.isPercent ? "%" : price.currency} in discount`}
                            rightContent={<i>Created: {formatLocalDate(po.created, "short")}</i>}
                        />
                    )
                })}
            {section.prices.map((p) => {
                const price = prices.find((price) => price.priceCategory === p.category)
                if (!price) {
                    return
                }
                // const currentPriceOverride = getFieldsFromControl(control.getValues() as TForm)?.priceOverrides?.[p.category]
                return (
                    <PriceRow
                        key={p.category}
                        leftText={price.description}
                        centerContent={
                            <FloatingLabelInput
                                smallBorderRadius
                                name={getOverridesFieldSelector("price", p.category)}
                                placeholder={"Custom price (" + (price.isPercent ? "%" : price.currency) + ")"}
                                type="number"
                                step="0.01"
                                inputMode="decimal"
                                innerRef={control.register(
                                    valFuncPattern(validationPatterns.inputFloatNum, "Not a valid amount format")
                                )}
                            ></FloatingLabelInput>
                        }
                        rightContent={
                            <i>
                                Standard price:{" "}
                                {getFormattedNumber(price.amount, {
                                    numberFormat: "forceTwoDecimals",
                                })}{" "}
                                {price.isPercent ? "%" : price.currency}
                            </i>
                        }
                    />
                )
            })}
            <hr />
            <div className="merchant-custom-prices-form-section__sub-title">Temporary price</div>
            {customPrices
                .filter((po) => po.isReadOnly && po.isReplace && !!section.prices.find((p) => p.category === po.priceCategory))
                .map((po) => {
                    const price = prices.find((price) => price.priceCategory === po.priceCategory)
                    if (!price) {
                        return
                    }
                    return (
                        <PriceRow
                            key={po.id}
                            leftText={price.description}
                            centerContent={`${po.amount} ${price.isPercent ? "%" : price.currency} for ${po.periodsLeft} months`}
                            rightContent={
                                <i>
                                    {po.startOfPayment
                                        ? `${formatLocalDate(po.startOfPayment, "short")} - ${formatLocalDate(
                                              po.endOfPayment,
                                              "short"
                                          )}${
                                              po.endOfPayment !== po.estimatedFinalEndOfPayment
                                                  ? " End: " + formatLocalDate(po.estimatedFinalEndOfPayment, "short")
                                                  : ""
                                          }`
                                        : `Not started`}
                                </i>
                            }
                        />
                    )
                })}
            {customPrices
                .filter((po) => !po.isReplace && !!section.prices.find((p) => p.category === po.priceCategory))
                .map((po) => {
                    const price = prices.find((price) => price.priceCategory === po.priceCategory)
                    if (!price) {
                        return
                    }
                    return (
                        <PriceRow
                            key={po.id}
                            highlight
                            leftText={price.description}
                            centerContent={`${po.amount} ${price.isPercent ? "%" : price.currency} in discount for ${
                                po.periods
                            } months.`}
                            rightContent={
                                <i>
                                    {po.startOfPayment
                                        ? `${formatLocalDate(po.startOfPayment, "short")} - ${formatLocalDate(
                                              po.endOfPayment,
                                              "short"
                                          )}${
                                              po.endOfPayment !== po.estimatedFinalEndOfPayment
                                                  ? " End: " + formatLocalDate(po.estimatedFinalEndOfPayment, "short")
                                                  : ""
                                          }`
                                        : `Not started`}
                                </i>
                            }
                        />
                    )
                })}
            {section.prices.map((p) => {
                const price = prices.find((price) => price.priceCategory === p.category)
                if (!price) {
                    return
                }
                // const currentTempPrice = getFieldsFromControl(control.getValues() as TForm)?.customPrices?.[p.category]
                return (
                    <PriceRow
                        key={p.category}
                        leftText={price.description}
                        centerContent={
                            <>
                                <FloatingLabelInput
                                    smallBorderRadius
                                    name={getCustomPriceFieldSelector("price", p.category)}
                                    placeholder={"Custom price (" + (price.isPercent ? "%" : price.currency) + ")"}
                                    type="number"
                                    step="0.01"
                                    inputMode="decimal"
                                    innerRef={control.register(
                                        valFuncPattern(validationPatterns.inputFloatNum, "Not a valid amount format")
                                    )}
                                ></FloatingLabelInput>
                                <FloatingLabelInput
                                    smallBorderRadius
                                    hidden
                                    name={getCustomPriceFieldSelector("priceCategory", p.category)}
                                    type="text"
                                    defaultValue={p.category}
                                    innerRef={control.register()}
                                ></FloatingLabelInput>
                                <FloatingLabelInput
                                    smallBorderRadius
                                    hidden
                                    name={getCustomPriceFieldSelector("type", p.category)}
                                    type="text"
                                    defaultValue="2"
                                    innerRef={control.register()}
                                ></FloatingLabelInput>
                            </>
                        }
                        rightContent={
                            <i>
                                Standard price:{" "}
                                {getFormattedNumber(price.amount, {
                                    numberFormat: "forceTwoDecimals",
                                })}{" "}
                                {price.isPercent ? "%" : price.currency}
                            </i>
                        }
                    />
                )
            })}

            {section.prices.map((p, index) => {
                const price = prices.find((price) => price.priceCategory === p.category)
                if (!price) {
                    return
                }
                // const currentTempPrice = getFieldsFromControl(control.getValues() as TForm)?.customPrices?.[p.category]
                return (
                    <PriceRow
                        key={p.category}
                        style={index === 0 ? {} : { display: "none" }}
                        leftText="Fixed period (months)"
                        centerContent={
                            <FloatingLabelInput
                                smallBorderRadius
                                name={getCustomPriceFieldSelector("periods", p.category)}
                                type="number"
                                step="1"
                                inputMode="decimal"
                                placeholder="Months"
                                innerRef={control.register(valFuncPattern(validationPatterns.inputFloatNum, " "))}
                                onChange={(e) => {
                                    const newValue = e.currentTarget.value
                                    section.prices.forEach((sectionPrice) =>
                                        control.setValue(
                                            getCustomPriceFieldSelector("periods", sectionPrice.category) as any,
                                            newValue as any
                                        )
                                    )
                                }}
                            ></FloatingLabelInput>
                        }
                        rightContent={
                            <TitleWithAbsoluteHint
                                hintIconRight
                                hintBoxWhite
                                hintAppearanceTop
                                hintText="The temporary price will be in effect for a selected number of months. For new merchants, the fixed period will start on the day of the first transaction. For other merchants, it will start on the first day of the following month."
                            />
                        }
                    />
                )
            })}
        </div>
    )
}

const PriceRow = (props: {
    style?: CSSProperties
    leftText: string
    centerContent: React.ReactNode
    rightContent: React.ReactNode
    highlight?: boolean
}) => (
    <div
        className={cn(
            "merchant-custom-prices-form-section__price-row",
            props.highlight && "merchant-custom-prices-form-section__price-row--highlighted"
        )}
        style={props.style}
    >
        <div className="merchant-custom-prices-form-section__price-row-left">{props.leftText}:</div>
        <div className="merchant-custom-prices-form-section__price-row-center">{props.centerContent}</div>
        <div className="merchant-custom-prices-form-section__price-row-right">{props.rightContent}</div>
    </div>
)

export default MerchantCustomPricesForm
