import { padZero } from "./numberUtils"

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export function waitForever(): Promise<void> {
    return new Promise(() => {
        // Empty on purpose
    })
}

function ensure1Decimal(num: string) {
    if (num.includes(".")) {
        return num
    }
    return num + ".0"
}

export function getTimeBetween(time1: Date | undefined, time2: Date | undefined) {
    if (!time1 || !time2) {
        return ""
    }

    const between = time2.getTime() - time1.getTime()

    if (between > 60000) {
        return ensure1Decimal("" + Math.round(between / 6000) / 10) + "m"
    }
    return ensure1Decimal("" + Math.round(between / 100) / 10) + "s"
}

export const secondsToZeroPaddedTime = (seconds: number) =>
    padZero(Math.floor(seconds / 3600), 2) +
    ":" +
    padZero(Math.floor((seconds / 60) % 60), 2) +
    ":" +
    padZero(Math.floor(seconds % 60), 2)
