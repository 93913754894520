import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import termsTemplateTypeSpecs from "../../data/termsTemplateTypeSpecs"
import { ETermsType, allTermsTypes } from "../../type/terms/ETermsType"
import { ITermsBaseTemplate } from "../../type/terms/ITermsBaseTemplate"
import { ITermsTemplate } from "../../type/terms/ITermsTemplate"
import { ITermsTemplateFile } from "../../type/terms/ITermsTemplateFile"
import { ITermsTemplateTranslation } from "../../type/terms/ITermsTemplateTranslation"
import { hasValue } from "../../util/arrayUtil"
import { StoreState } from "../StoreState"
import { termsTemplateReducerActions, termsTemplateSelectors } from "../reducers/termsTemplateReducer"
import { endpoints } from "./../../data/endpoints"
import { addModalThunk } from "./modalThunks"

export const getTermsTemplatesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && termsTemplateSelectors.activeTermsTemplates(getState())) {
            return
        }

        const result = await dispatch(requestThunk<{ templates: ITermsTemplate[] }>(endpoints.Legal.getTermsTemplates))

        dispatch(termsTemplateReducerActions.setActiveTermsTemplates(result.templates))
    }

export const getTermsTemplateDetailsThunk =
    (termsType: ETermsType, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && termsTemplateSelectors.termsTemplateDetails(getState(), termsType)) {
            return
        }

        const result = await dispatch(
            requestThunk<{ templates: ITermsTemplate[]; translations: ITermsTemplateTranslation[]; files: ITermsTemplateFile[] }>(
                endpoints.Legal.getTermsTemplateDetails(termsType)
            )
        )

        dispatch(termsTemplateReducerActions.setTermsTemplateDetails(termsType, result.templates))
        dispatch(termsTemplateReducerActions.setTermsTemplateTranslations(termsType, result.translations))
        dispatch(termsTemplateReducerActions.setTermsTemplateFiles(termsType, result.files))
    }

export const getTermsBaseTemplatesThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && termsTemplateSelectors.termsBaseTemplates(getState())) {
            return
        }

        const result = await dispatch(
            requestThunk<{ baseTemplates: ITermsBaseTemplate[] }>(endpoints.Legal.getTermsBaseTemplates)
        )

        dispatch(termsTemplateReducerActions.setTermsBaseTemplates(result.baseTemplates))
    }

export const updateTermsTemplateThunk =
    (
        termsTemplate: ITermsTemplate,
        translations: ITermsTemplateTranslation[],
        files: ITermsTemplateFile[]
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Legal.updateTermsTemplate, {
                data: {
                    template: termsTemplate,
                    translations,
                    files,
                },
            })
        )

        await dispatch(getTermsTemplatesThunk(true))
        await dispatch(getTermsTemplateDetailsThunk(termsTemplate.termsType, true))
    }

export const removeTermsTemplateThunk =
    (termsTemplate: ITermsTemplate): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const modalResult = await dispatch(
            addModalThunk({
                type: "verify",
                text: `Are you sure you want to remove ${termsTemplateTypeSpecs[termsTemplate.termsType].label} v. ${
                    termsTemplate.version
                } r. ${termsTemplate.revision}?`,
                acceptButtonText: "Remove",
                acceptButtonIsDelete: true,
            })
        )

        if (modalResult.type !== "accepted") {
            return false
        }

        await dispatch(requestThunk(endpoints.Legal.removeTermsTemplate(termsTemplate.termsTemplateId)))

        await dispatch(getTermsTemplatesThunk(true))
        await dispatch(getTermsTemplateDetailsThunk(termsTemplate.termsType, true))
        return true
    }

export const updateTermsBaseTemplateThunk =
    (termsBaseTemplate: ITermsBaseTemplate): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Legal.updateTermsBaseTemplate, {
                data: {
                    baseTemplate: termsBaseTemplate,
                },
            })
        )

        await dispatch(getTermsBaseTemplatesThunk(true))
    }

export const getAllTermsTemplateTranslationsThunk =
    (language: string): ThunkAction<Promise<ITermsTemplateTranslation[]>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const promises = allTermsTypes.map((tt) => dispatch(getTermsTemplateDetailsThunk(tt, false)))
        await Promise.all(promises)
        const allTranslations = allTermsTypes.map((tt) => termsTemplateSelectors.termsTemplateTranslations(getState(), tt)).flat()
        return allTranslations.filter(hasValue).filter((t) => t.language === language)
    }
