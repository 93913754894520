import cn from "classnames"
import React, { useEffect, useState } from "react"
import "./AccordionWithChildren.scss"

interface IAccordionWithChildren {
    className?: string
    classNameHeader?: string
    children: React.ReactNode
    header: string
    isHeaderSectionTitle?: boolean
    leftAlignHeader?: boolean
    arrowNextToHeader?: boolean
    headerExpandedText?: string
    isSimple?: boolean
    animate?: boolean
    isInitiallyExpanded?: boolean
    isExpanded?: boolean
    expandedDidChange?: (isExpanded: boolean) => void
}

export const AccordionWithChildren = (props: IAccordionWithChildren) => {
    const [expanded, setExpanded] = useState(props.isInitiallyExpanded ?? false)

    useEffect(() => {
        if (props.isExpanded !== undefined && expanded !== props.isExpanded) {
            setExpanded(props.isExpanded)
        }
    }, [props.isExpanded])

    return (
        <div
            className={cn("accordion-with-children-component", props.className, {
                simplified: props.isSimple,
                animate: props.animate,
                "header-left": props.leftAlignHeader,
                "header-arrow-next-to": props.arrowNextToHeader,
            })}
        >
            <div
                className={cn(
                    "accordion-with-children-component__header",
                    props.isHeaderSectionTitle && "accordion-with-children-component__header--section-title",
                    props.classNameHeader
                )}
                onClick={() => {
                    if (props.isExpanded === undefined) {
                        setExpanded(!expanded)
                    }
                    if (props.expandedDidChange) {
                        props.expandedDidChange(!expanded)
                    }
                }}
            >
                <p>{expanded && props.headerExpandedText ? props.headerExpandedText : props.header}</p>
                <div className={cn("arrow-container", { up: expanded }, { down: !expanded })}>
                    {!(props.isSimple ?? false) && <i className="arrow" />}
                </div>
            </div>
            <div className={cn("children", { expanded })}>{props.children}</div>
        </div>
    )
}
