import {
    ActionsUnion,
    ISharedServerConfiguration,
    SharedStoreState,
    TEndpointNameShared,
    createAction,
} from "swiipe.portal.shared"
import { CountryRegionConfig } from "../types/general/countryRegionConfig"

export type SharedConfigurationState = Readonly<{
    serverConfiguration?: ISharedServerConfiguration
    countryRegionsConfig?: CountryRegionConfig
    initialized: boolean
}>
const initialState: SharedConfigurationState = {
    serverConfiguration: undefined,
    countryRegionsConfig: undefined,
    initialized: false,
}

export const sharedConfigurationReducerActions = {
    setServerConfiguration: (serverConfiguration: ISharedServerConfiguration) =>
        createAction("APP/SET_SERVER_CONFIGURATION", { serverConfiguration }),
    setCountryRegionsConfiguration: (countryRegionsConfiguration: CountryRegionConfig) =>
        createAction("APP/SET_COUNTRY_REGION_CONFIG", { countryRegionsConfiguration }),
    setInitialized: (initialized: boolean) => createAction("APP/SET_INITIALIZED", { initialized }),
}

export type SharedConfigurationActions = ActionsUnion<typeof sharedConfigurationReducerActions>

export const sharedConfigurationReducer = (
    state: SharedConfigurationState = initialState,
    action: SharedConfigurationActions
): SharedConfigurationState => {
    switch (action.type) {
        case "APP/SET_SERVER_CONFIGURATION": {
            return {
                ...state,
                serverConfiguration: action.serverConfiguration,
            }
        }
        case "APP/SET_COUNTRY_REGION_CONFIG": {
            return {
                ...state,
                countryRegionsConfig: action.countryRegionsConfiguration,
            }
        }
        case "APP/SET_INITIALIZED": {
            return {
                ...state,
                initialized: action.initialized,
            }
        }
    }

    return state
}

export const sharedConfigurationSelectors = {
    serverConfiguration: (state: SharedStoreState) => state.sharedConfiguration.serverConfiguration!,
    countryRegionConfiguration: (state: SharedStoreState) => state.sharedConfiguration.countryRegionsConfig,
    environment: (state: SharedStoreState) => state.sharedConfiguration.serverConfiguration?.environment ?? "Production",
    endpointConfig: (state: SharedStoreState, endpointName: TEndpointNameShared): string => {
        const origins = state.sharedConfiguration.serverConfiguration?.origins
        if (!origins) {
            return ""
        }
        return origins[endpointName]
    },
    endpointConfigs: (state: SharedStoreState): { [key in TEndpointNameShared]: string } | undefined => {
        return state.sharedConfiguration.serverConfiguration?.origins
    },

    initialized: (state: SharedStoreState) =>
        !!state.sharedConfiguration.serverConfiguration && state.sharedConfiguration.initialized,
}
