import { IDropdownOption, getText } from "swiipe.portal.shared"

export type TClearhausDeliveryDelayType = "less5days" | "5to10days" | "10to20days" | "more20days"

export function getClearhausDeliveryDelayOptions(): IDropdownOption<TClearhausDeliveryDelayType>[] {
    return [
        {
            value: "less5days",
            text: getText("acquiringOnboarding.clearhaus.deliveryDelayOptions.less5days"),
        },
        {
            value: "5to10days",
            text: getText("acquiringOnboarding.clearhaus.deliveryDelayOptions.5to10days"),
        },
        {
            value: "10to20days",
            text: getText("acquiringOnboarding.clearhaus.deliveryDelayOptions.10to20days"),
        },
        {
            value: "more20days",
            text: getText("acquiringOnboarding.clearhaus.deliveryDelayOptions.more20days"),
        },
    ]
}

export function getClearhausDeliveryDelaysRequiringComment(): TClearhausDeliveryDelayType[] {
    return ["5to10days", "10to20days", "more20days"]
}
