import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export interface WebshopSnapshot {
    webshopId: string
    cmsVersion: string
    ecommerceVersion: string
}

export type WebshopState = Readonly<{
    snapshots: {
        [webshopId: string]: WebshopSnapshot
    }
    pluginVersions: {
        [webshopId: string]: string
    }
}>
const initialState: WebshopState = {
    snapshots: {},
    pluginVersions: {},
}

export const webshopReducerActions = {
    setWebshopSnapshot: (webshopId: string, data: WebshopSnapshot) =>
        createAction("APP/SET_WEBSHOP_SNAPSHOT", { webshopId, data }),
    setPluginVersion: (webshopId: string, version: string) => createAction("APP/SET_PLUGIN_VERSION", { webshopId, version }),
}

export type WebshopActions = ActionsUnion<typeof webshopReducerActions>

export const webshopReducer = (state: WebshopState = initialState, action: WebshopActions) => {
    switch (action.type) {
        case "APP/SET_WEBSHOP_SNAPSHOT": {
            return {
                ...state,
                snapshots: {
                    ...state.snapshots,
                    [action.webshopId]: action.data,
                },
            }
        }
        case "APP/SET_PLUGIN_VERSION": {
            return {
                ...state,
                pluginVersions: {
                    ...state.pluginVersions,
                    [action.webshopId]: action.version,
                },
            }
        }
    }

    return state
}

export const webshopSelectors = {
    webshopSnapshot: (state: StoreState, webshopId: string) =>
        state.webshop.snapshots[webshopId] ? state.webshop.snapshots[webshopId] : undefined,
    swiipePluginVersion: (state: StoreState, webshopId: string) =>
        state.webshop.pluginVersions[webshopId] ? state.webshop.pluginVersions[webshopId] : undefined,
}
