import React from "react"
import Spinner from "../../images/load/spinner.gif"
import cn from "classnames"
import "./SpinnerContainer.scss"

interface ISpinnerContainerProps {
    showSpinner: boolean
    className?: string
    children?: React.ReactNode
    aboveContent?: boolean
    fullScreen?: boolean
    minHeight?: number
}

const SpinnerContainer = ({ children, showSpinner, className, aboveContent, fullScreen, minHeight }: ISpinnerContainerProps) => {
    if (aboveContent) {
        return (
            <div
                className={cn(
                    "swiipe-spinner-container clearfix",
                    className,
                    fullScreen && "swiipe-spinner-container--full-screen"
                )}
                style={{ minHeight }}
            >
                <div className={cn("child-wrapper")}>{children}</div>
                {showSpinner && <div className="overlay"></div>}
                {showSpinner && <img className="spinner" src={Spinner} />}
            </div>
        )
    }
    return (
        <div className={cn("swiipe-spinner-container clearfix", className)} style={{ minHeight }}>
            <div className={cn("child-wrapper", { hide: showSpinner })}>{children}</div>
            {showSpinner && <img className="spinner" src={Spinner} />}
        </div>
    )
}

export default SpinnerContainer
