import { configureStore } from "@reduxjs/toolkit"
import { StoreState, createRootReducer } from "./StoreState"

export default function configureStoreWrapper(initialState: StoreState) {
    const store = configureStore({
        reducer: createRootReducer(),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
        preloadedState: initialState,
    })
    return store
}
