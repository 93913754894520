import cn from "classnames"
import React from "react"
import "./PageWrapper.scss"

interface IPageWrapperProps {
    children: React.ReactNode
    fullWidth?: boolean
    fillAll?: boolean
    disableMinHeight?: boolean
    hideContent?: boolean
    wider?: boolean
    widest?: boolean
    className?: string
    useRef?: React.LegacyRef<HTMLDivElement>
    narrow?: boolean
}

export const PageWrapper = ({
    fullWidth,
    fillAll,
    children,
    disableMinHeight,
    hideContent,
    wider,
    widest,
    className,
    useRef,
    narrow,
}: IPageWrapperProps) => {
    return (
        <div
            id="main-container"
            className={cn(
                fullWidth && "full-width",
                fillAll && "fill-all",
                disableMinHeight && "disable-min-height",
                hideContent && "d-none",
                wider && "wider",
                widest && "widest",
                narrow && "narrow",
                className
            )}
            ref={useRef}
        >
            {children}
        </div>
    )
}
