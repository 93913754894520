import { IEmailBaseTemplate, IEmailTemplateImage } from "../store/reducers/emailTemplateReducer"
import { getDistinct, hasValue } from "../util/arrayUtil"

export function getImagesWithBestLanguageFit(images: IEmailTemplateImage[], language: string) {
    const imagesBestFits: IEmailTemplateImage[] = getDistinct(images, (img) => img.name)
        .map((img) => img.name)
        .map((name) => {
            const languageFit = images.find((i) => name === i.name && i.language === language)
            if (languageFit) {
                return languageFit
            }
            const sharedFit = images.find((i) => name === i.name && i.language === "shared")
            if (sharedFit) {
                return sharedFit
            }
            const first = images.find((i) => name === i.name)
            return first!
        })
    return imagesBestFits
}

export function getBaseTemplatesWithBestLanguageFit(bases: IEmailBaseTemplate[], language: string) {
    const bestFits: IEmailBaseTemplate[] = getDistinct(bases, (base) => base.typeId)
        .map((base) => base.typeId)
        .map((typeId) => {
            const languageFit = bases.find((i) => typeId === i.typeId && i.language === language)
            if (languageFit) {
                return languageFit
            }
            const sharedFit = bases.find((i) => typeId === i.typeId && i.language === "shared")
            if (sharedFit) {
                return sharedFit
            }
            return undefined
        })
        .filter(hasValue)
    return bestFits
}
