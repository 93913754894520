import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, getPlatformLogo } from "swiipe.portal.shared"
import DropDownList from "../../components/form/input/DropDownList"
import { removeSchemeFromHostName } from "../../services/merchantService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { setSelectedWebshopIdThunk } from "../../store/thunks/merchantThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/merchant/mixedmerchantdetails"
import { TPlatformValue } from "../../type/merchant/webshopdetails"
import "./WebshopSelect.scss"

interface IWebshopSelectProps {
    merchantDetails: MixedMerchantDetails
    pageWrapperWider?: boolean
    pageWrapperFullWidth?: boolean
    className?: string
    onWebshopChange?: (selectedWebshopId: string) => void
    limitToPlatforms?: TPlatformValue[]
    renderAsPage?: boolean
    hint?: string
    skipDataDispatch?: boolean
    hideTitle?: boolean
    customOptions?: { text: string; value: string }[]
    initialWebshopId?: string
}

const WebshopSelect = ({
    merchantDetails,
    pageWrapperWider,
    pageWrapperFullWidth,
    onWebshopChange,
    limitToPlatforms,
    className,
    renderAsPage = true,
    hint,
    skipDataDispatch,
    hideTitle,
    customOptions,
    initialWebshopId,
}: IWebshopSelectProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    let webshops = merchantDetails.webshops || []
    if (limitToPlatforms) {
        webshops = webshops.filter((w) => limitToPlatforms.includes(w.platform))
    }

    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId || webshops.length ? webshops[0].webshopId : "")
    const [selectedPlatfrom, setSelectedPlatform] = useState("" as TPlatformValue)

    useEffect(() => {
        if (
            initialWebshopId &&
            webshops &&
            webshops.find(
                (w) => w.webshopId === initialWebshopId || customOptions?.map((o) => o.value).includes(initialWebshopId)
            )
        ) {
            setSelectedWebshopId(initialWebshopId)

            const selectedWebshop = webshops.find((w) => w.webshopId === initialWebshopId)
            if (selectedWebshop) {
                setSelectedPlatform(selectedWebshop.platform)
            }

            onWebshopChange?.(initialWebshopId)
        } else if (webshops.length) {
            setSelectedWebshopId(webshops[0].webshopId)
            setSelectedPlatform(webshops[0].platform)

            onWebshopChange?.(webshops[0].webshopId)
        }
    }, [merchantDetails, initialWebshopId])

    const webshopSelectComponent = (
        <>
            <div className="webshop-select">
                {!hideTitle && <p className="heading mb-2">{t("webshopselect.selectwebshop")}</p>}
                <DropDownList
                    options={[
                        ...(customOptions || []),
                        ...webshops.map((w) => {
                            return { text: removeSchemeFromHostName(w.hostName), value: w.webshopId }
                        }),
                    ]}
                    onChange={(e) => {
                        setSelectedPlatform(webshops.find((w) => w.webshopId === e.target.value)!.platform)
                        setSelectedWebshopId(e.target.value)

                        if (!skipDataDispatch) {
                            localStorage[merchantDetails.swMerchant.swMerchantId + "_selectedWebshopId"] = e.target.value
                            dispatch(setSelectedWebshopIdThunk(merchantDetails.swMerchant.swMerchantId, e.target.value))
                        }
                        onWebshopChange?.(e.target.value)
                    }}
                    value={selectedWebshopId}
                ></DropDownList>
                {!!selectedPlatfrom && <img className="platform-logo" src={getPlatformLogo(selectedPlatfrom)} />}
            </div>
            {hint && (
                <div className="hint-webshop-select">
                    <span>{hint}</span>
                </div>
            )}
        </>
    )

    return renderAsPage ? (
        <PageWrapper disableMinHeight wider={pageWrapperWider} fullWidth={pageWrapperFullWidth} className={className}>
            <PageContainer id="webshop-select-comp">{webshopSelectComponent}</PageContainer>
        </PageWrapper>
    ) : (
        <div id="webshop-select-comp" className={className}>
            {webshopSelectComponent}
        </div>
    )
}

export default WebshopSelect
