import cn from "classnames"
import React from "react"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import "./PageSection.scss"

interface IPageSectionProps {
    title?: string
    children?: React.ReactNode
    className?: string
    classNameTitle?: string
    containerClassName?: string
    indentContent?: boolean
}

const PageSection = ({ children, title, className, containerClassName, classNameTitle, indentContent }: IPageSectionProps) => {
    return (
        <PageWrapper
            disableMinHeight
            widest
            className={cn("page-section", containerClassName, indentContent && "page-section--content-indent")}
        >
            <PageContainer classNameBody="page-section-body">
                <div className={cn("page-section-inner", className)}>
                    {title && <div className={cn("page-section-title", classNameTitle)}>{title}</div>}
                    <div className="page-section-content">{children}</div>
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default PageSection
