import {
    IInvoicingPrice,
    TCurrencyFormat,
    TInvoicingPriceCategory,
    TInvoicingPriceType,
    TNumberFormat,
    getKeys,
    getPriceWithCurrency,
    getText,
} from "swiipe.portal.shared"

export interface IPriceConfig {
    type?: TInvoicingPriceType
    category?: TInvoicingPriceCategory
    number?: number
    currencyFormat?: TCurrencyFormat
    numberFormat?: TNumberFormat
    wrapPriceInElement?: string
    hidePercentSign?: boolean
}

export function getTextWithInvoicingPrices(
    textKey: string,
    prices: IInvoicingPrice[] | undefined,
    replacements: {
        [key: string]: IPriceConfig
    }
) {
    const resolvedReplacements = getKeys(replacements).reduce<{ [key: string]: string }>((res, key) => {
        const config = replacements[key]
        res[key] = getResolvedInvoicingPrice(prices, config)
        return res
    }, {})
    return getText(textKey, resolvedReplacements)
}

export function getResolvedInvoicingPrice(prices: IInvoicingPrice[] | undefined, config: IPriceConfig) {
    const resolvedPrices = prices || []

    if (config.number || config.number === 0) {
        const currency = resolvedPrices.length > 0 ? resolvedPrices![0].currency : undefined
        return getPriceWithCurrency(config.number, currency, config)
    }

    if (config.type) {
        const priceFromType = resolvedPrices.find((p) => p.priceType === config.type)
        if (priceFromType) {
            if (priceFromType.isPercent) {
                return `${getPriceWithCurrency(priceFromType.amount, undefined, config)}${config.hidePercentSign ? "" : "%"}`
            }
            return getPriceWithCurrency(priceFromType.amount, priceFromType.currency, config)
        }
    }

    if (config.category) {
        const planPrice = resolvedPrices.find((p) => p.priceCategory === config.category && p.isActive)
        if (planPrice) {
            if (planPrice.isPercent) {
                return `${getPriceWithCurrency(planPrice.amount, undefined, config)}${config.hidePercentSign ? "" : "%"}`
            }
            return getPriceWithCurrency(planPrice.amount, planPrice.currency, config)
        }
    }

    return "---"
}

export function getPriceWithCategory(prices: IInvoicingPrice[] | undefined, category: TInvoicingPriceCategory) {
    const resolvedPrices = prices || []
    const planPrice = resolvedPrices.find((p) => p.priceCategory === category && p.isActive)
    return planPrice
}
