import cn from "classnames"
import React from "react"
import { Control, FieldName } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { TitleWithAbsoluteHint, useSelector } from "swiipe.portal.shared"
import { userSelectors } from "../../../store/userReducer"
import { IFormBase } from "../../../types/form/IFormBase"
import { getFullNameFromFirstAndLastName } from "../../../utils/formatUtil"
import { filterShouldValidate, valFuncRequiredAndPattern, validationPatterns } from "../../../utils/validationUtil"
import { FieldsetFormGroup } from "../../form/FieldsetFormGroup"
import { FormInputColumnWithInfoColumn } from "../../form/FormInputColumnWithInfoColumn"
import { FloatingLabelInput } from "../../form/input/FloatingLabelInput"
import { PhoneNumberInput } from "../../form/input/PhoneNumberInput"
import { CompletedFieldsReview } from "../../form/review/CompletedFieldsReview"
import "./AcquiringContactFields.scss"

export type IAcquiringContactFullNameSubForm = {
    name: string
    email: string
    phone: string
}

export type IAcquiringContactFirstAndLastNameSubForm = {
    firstName: string
    lastName: string
    email: string
    phone: string
}

export type IAcquiringContactSubForm = IAcquiringContactFullNameSubForm | IAcquiringContactFirstAndLastNameSubForm

interface IContactFieldsProps<TForm extends IFormBase> {
    control: Control<TForm>
    nameType: "fullName" | "firstAndLastName"
    showReview: boolean
    defaultToUserData?: boolean
    getFieldsFromControl: (form: TForm | undefined) => IAcquiringContactSubForm | undefined
    savedFields: IAcquiringContactSubForm | undefined
    fieldSelectorPrefix: string
    validatedFields?: string[]
    viewMode?: boolean
    emailHintText?: string
    isDisabled?: boolean
}

export const AcquiringContactFields = <TForm extends IFormBase>({
    control,
    nameType,
    showReview,
    defaultToUserData,
    getFieldsFromControl,
    savedFields,
    fieldSelectorPrefix,
    validatedFields,
    viewMode,
    emailHintText,
    isDisabled,
}: IContactFieldsProps<TForm>) => {
    const { t } = useTranslation()
    const userDataFromState = useSelector(userSelectors.userData)
    const userData = defaultToUserData ? userDataFromState : undefined
    const currentFields = (!viewMode && getFieldsFromControl(control.getValues() as TForm)) || undefined

    const getFieldSelector = (fieldName: string) => fieldSelectorPrefix + fieldName

    const ShowData = (props: { data?: string }) => {
        if (!props.data) {
            return null
        }

        return (
            <>
                {props.data}
                <br />
            </>
        )
    }

    return (
        <div className="acquiring-contact-fields">
            <div className={cn({ "d-none": showReview })}>
                {nameType === "fullName" && (
                    <FieldsetFormGroup className="acquiring-contact-fields__field" field>
                        <FloatingLabelInput
                            placeholder={t("common.fullName.label")}
                            name={getFieldSelector("name")}
                            type="text"
                            disabled={isDisabled}
                            innerRef={control.register(
                                filterShouldValidate(
                                    valFuncRequiredAndPattern(validationPatterns.fullName, t("common.fullName.errorMessage")),
                                    getFieldSelector("name"),
                                    validatedFields
                                )
                            )}
                            defaultValue={userData?.user.fullName}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                )}
                {nameType === "firstAndLastName" && (
                    <FieldsetFormGroup className="acquiring-contact-fields__field" field>
                        <FloatingLabelInput
                            placeholder={t("common.firstName.label")}
                            name={getFieldSelector("firstName")}
                            type="text"
                            disabled={isDisabled}
                            innerRef={control.register(
                                filterShouldValidate(
                                    valFuncRequiredAndPattern(validationPatterns.firstName, t("common.firstName.errorMessage")),
                                    getFieldSelector("firstName"),
                                    validatedFields
                                )
                            )}
                            defaultValue={userData?.user.fullName}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                )}
                {nameType === "firstAndLastName" && (
                    <FieldsetFormGroup className="acquiring-contact-fields__field" field>
                        <FloatingLabelInput
                            placeholder={t("common.lastName.label")}
                            name={getFieldSelector("lastName")}
                            type="text"
                            disabled={isDisabled}
                            innerRef={control.register(
                                filterShouldValidate(
                                    valFuncRequiredAndPattern(validationPatterns.lastName, t("common.lastName.errorMessage")),
                                    getFieldSelector("lastName"),
                                    validatedFields
                                )
                            )}
                            defaultValue={userData?.user.fullName}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                )}
                <FormInputColumnWithInfoColumn
                    infoColumn={emailHintText && <TitleWithAbsoluteHint title={emailHintText} iconExclamation />}
                >
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            placeholder={t("common.email.label")}
                            name={getFieldSelector("email")}
                            type="email"
                            disabled={isDisabled}
                            innerRef={control.register(
                                filterShouldValidate(
                                    valFuncRequiredAndPattern(validationPatterns.email, t("common.email.errorMessage")),
                                    getFieldSelector("email"),
                                    validatedFields
                                )
                            )}
                            defaultValue={userData?.user.email}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                </FormInputColumnWithInfoColumn>
                <FieldsetFormGroup className="acquiring-contact-fields__field mb-0" field>
                    <PhoneNumberInput
                        inputName={getFieldSelector("phone")}
                        defaultValue={userData?.user.phone}
                        register={control.register}
                        required={!validatedFields || validatedFields.includes(getFieldSelector("phone"))}
                        setValue={control.setValue}
                        getValues={control.getValues}
                        watch={control.watchInternal}
                        onValidPhoneTyped={() => control.trigger(getFieldSelector("phone") as FieldName<TForm>)}
                        isDisabled={isDisabled}
                    />
                </FieldsetFormGroup>
            </div>
            {showReview && (
                <CompletedFieldsReview>
                    {nameType === "fullName" && (
                        <ShowData
                            data={
                                (savedFields as IAcquiringContactFullNameSubForm)?.name ||
                                (currentFields as IAcquiringContactFullNameSubForm)?.name
                            }
                        />
                    )}
                    {nameType === "firstAndLastName" && (
                        <ShowData
                            data={
                                getFullNameFromFirstAndLastName(
                                    (savedFields as IAcquiringContactFirstAndLastNameSubForm)?.firstName,
                                    (savedFields as IAcquiringContactFirstAndLastNameSubForm)?.lastName
                                ) ||
                                getFullNameFromFirstAndLastName(
                                    (currentFields as IAcquiringContactFirstAndLastNameSubForm)?.firstName,
                                    (currentFields as IAcquiringContactFirstAndLastNameSubForm)?.lastName
                                )
                            }
                        />
                    )}
                    <ShowData data={savedFields?.email || currentFields?.email} />
                    <ShowData data={savedFields?.phone || currentFields?.phone} />
                </CompletedFieldsReview>
            )}
        </div>
    )
}
