import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { getValues } from "../../util/objectUtils"
import { StoreState } from "../StoreState"

export type ESegment = "A" | "B" | "C" | "D"

export interface SegmentMapping {
    averageRevenueMoreThan: number
    segment: ESegment
}

export interface RevenueReport {
    dankortCapturesPercent: number
    averageAnnualRevenue: number
    averageMonthlyRevenue: number
    lastMonthRevenue: number
    firstTransactionDate?: Date
}

export type RevenueState = Readonly<{
    reports: {
        [merchantId: string]: {
            [webshopId: string]: RevenueReport
        }
    }
    segmentConfig: SegmentMapping[]
}>
const initialState: RevenueState = { reports: {}, segmentConfig: [] }

export const revenueReducerActions = {
    setWebshopRevenueReport: (merchantId: string, webshopId: string, revenueReport: RevenueReport) =>
        createAction("APP/SET_WEBSHOP_REVENUE_REPORT", { merchantId, webshopId, revenueReport }),
    setSegmentConfig: (segmentConfig: SegmentMapping[]) => createAction("APP/SET_SEGMENT_CONFIG", { segmentConfig }),
}

export type RevenueActions = ActionsUnion<typeof revenueReducerActions>

export const revenueReducer = (state: RevenueState = initialState, action: RevenueActions) => {
    switch (action.type) {
        case "APP/SET_WEBSHOP_REVENUE_REPORT": {
            return {
                ...state,
                reports: {
                    ...(state.reports ?? {}),
                    [action.merchantId]: {
                        ...(state.reports[action.merchantId] ?? {}),
                        [action.webshopId]: action.revenueReport,
                    },
                },
            }
        }
        case "APP/SET_SEGMENT_CONFIG": {
            return {
                ...state,
                segmentConfig: action.segmentConfig,
            }
        }
    }

    return state
}

export const revenueSelectors = {
    webshopRevenue: (state: StoreState, merchantId: string, webshopId: string) =>
        state.revenue.reports[merchantId]?.[webshopId] ? state.revenue.reports[merchantId]?.[webshopId] : undefined,
    merchantRevenue: (state: StoreState, merchantId: string) => {
        const merchantReports = state.revenue.reports[merchantId]
        if (!merchantReports) {
            return undefined
        }
        // Use now as a max date for the first transaction date for comparison
        const now = new Date()
        const merchantRevenue = getValues(merchantReports).reduce(
            (merchantReport, webshopReport) => ({
                dankortCapturesPercent: merchantReport.dankortCapturesPercent + webshopReport.dankortCapturesPercent,
                averageAnnualRevenue: merchantReport.averageAnnualRevenue + webshopReport.averageAnnualRevenue,
                averageMonthlyRevenue: merchantReport.averageMonthlyRevenue + webshopReport.averageMonthlyRevenue,
                lastMonthRevenue: merchantReport.lastMonthRevenue + webshopReport.lastMonthRevenue,
                firstTransactionDate:
                    !webshopReport.firstTransactionDate ||
                    merchantReport.firstTransactionDate! < webshopReport.firstTransactionDate
                        ? merchantReport.firstTransactionDate
                        : webshopReport.firstTransactionDate,
            }),
            {
                averageAnnualRevenue: 0,
                averageMonthlyRevenue: 0,
                dankortCapturesPercent: 0,
                firstTransactionDate: now,
                lastMonthRevenue: 0,
            }
        )
        // When there is no first transaction date for the merchant, set it to undefined
        if (merchantRevenue.firstTransactionDate === now) {
            merchantRevenue.firstTransactionDate = undefined
        }
        return merchantRevenue
    },
    segmentConfig: (state: StoreState) => state.revenue.segmentConfig,
}
