import { IApiEndpoint, TEndpointNameShared } from "../types/request/IApiEndpoint"
import { THttpMethod } from "../types/request/THttpMethod"

export interface IRequestOptions {
    isOpen?: boolean
    endpoint?: TEndpointNameShared
}

export function createEndpoint0(method: THttpMethod, path: string, options?: IRequestOptions): IApiEndpoint {
    return {
        method,
        endpoint: options?.endpoint ?? "InternalApi",
        authentication: options?.isOpen ? "open" : "login",
        path,
    }
}

export function createEndpoint1(method: THttpMethod, path: string, options?: IRequestOptions): (param1: string) => IApiEndpoint {
    return (param1: string) => {
        return createEndpoint0(method, path.replace(/{[^}]*}/, param1), options)
    }
}

export function createEndpoint2(
    method: THttpMethod,
    path: string,
    options?: IRequestOptions
): (param1: string, param2: string) => IApiEndpoint {
    return (param1: string, param2: string) => {
        return createEndpoint0(method, path.replace(/{[^}]*}/, param1).replace(/{[^}]*}/, param2), options)
    }
}

export function createEndpoint3(
    method: THttpMethod,
    path: string,
    options?: IRequestOptions
): (param1: string, param2: string, param3: string) => IApiEndpoint {
    return (param1: string, param2: string, param3: string) => {
        return createEndpoint0(
            method,
            path
                .replace(/{[^}]*}/, param1)
                .replace(/{[^}]*}/, param2)
                .replace(/{[^}]*}/, param3),
            options
        )
    }
}

const apiEndpointsShared = {
    Self: {
        checkVat: createEndpoint2("get", "/checkVat/{countryCode}/{vatNumber}", { endpoint: "Self" }),
    },
    Core: {
        cityByZipcodeLookup: createEndpoint1("get", "/core/postcode/getCity/{postalcode}", { isOpen: true }),
    },
    Identity: {
        addInternalComment: createEndpoint0("post", "/identity/comments"),
        getInternalComments: createEndpoint0("get", "/identity/comments"),
        deleteInternalComment: createEndpoint1("delete", "/identity/comments/{commentId}"),
        updateInternalComment: createEndpoint1("put", "/identity/comments/{commentId}"),
        getCompanyDetails: createEndpoint0("get", "/identity/organization/getCompanyDetails"),
        setCompanyDetails: createEndpoint0("post", "/identity/organization/setCompanyDetails"),
        getMerchantDetails: createEndpoint0("get", "/identity/merchant/getMerchantDetails"),
        getMerchantAcquiring: createEndpoint0("get", "/identity/merchantAcquiring"),
        setMerchantAcquiring: createEndpoint0("post", "/identity/merchantAcquiring"),
    },
    Send: {
        getExampleEmail: createEndpoint0("post", "/send/Email/getExampleEmail"),
        getExampleSms: createEndpoint0("post", "/send/Sms/getExampleSms"),
    },
}

type IApiEndpoints = typeof apiEndpointsShared
export const endpointsShared: IApiEndpoints = apiEndpointsShared
