import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { createUrlThunk, requestThunk } from "swiipe.portal.shared"
import { ELegalRelationType, ETermsType } from "../../type/terms/ETermsType"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"

export type ETermsContentType = "text/html" | "application/pdf"

export interface IGetTermsModel {
    termsType: ETermsType
    language: string
    version: string
    revision: number
    relationId?: string
    relationType?: ELegalRelationType
}

export const getTermsContentTypeThunk =
    (model: IGetTermsModel): ThunkAction<Promise<ETermsContentType>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const type = await dispatch(
            requestThunk<ETermsContentType>(endpoints.Legal.getTermsContentType, {
                params: {
                    ...model,
                },
            })
        )
        return type
    }

export const getTermsUrlThunk =
    (model: IGetTermsModel): ThunkAction<Promise<string>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const url = dispatch(
            createUrlThunk(endpoints.Legal.getTerms, {
                ...model,
            })
        )
        return url
    }

export const getTermsThunk =
    (model: IGetTermsModel, shouldReturnString: boolean): ThunkAction<Promise<string | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const result = await dispatch(
            requestThunk<string>(endpoints.Legal.getTerms, {
                params: {
                    ...model,
                    shouldReturnString,
                },
            })
        )

        return result
    }
