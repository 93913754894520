import React from "react"
import { useCurrentRoute } from "react-navi"
import { PageWrapper } from "swiipe.portal.shared"
import FormFooter from "../components/form/footer/FormFooter"
import LoginForm from "../components/form/login/LoginForm"
import FormHeader from "../components/form/title/FormHead"
import { authService } from "../services/authService"

interface IProps {}

const LoginPage = ({}: IProps) => {
    const currentRoute = useCurrentRoute()
    if (currentRoute.url.query.otac) {
        // TODO Show redirect message
        authService.login("/", currentRoute.url.query.otac)
        return null
    }

    const trimmedForm = !!currentRoute.url.query.fromCheckout

    return (
        <PageWrapper>
            <div id="login" className="center-form">
                <FormHeader />
                <div className="panel-body">
                    <LoginForm isTrimmed={trimmedForm} />
                </div>
                <FormFooter />
            </div>
        </PageWrapper>
    )
}

export default LoginPage
