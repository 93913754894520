import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "./../../data/endpoints"
import { ITestRun } from "./../../type/testResults/ITestRun"
import { StoreState } from "./../StoreState"
import { testResultReducerActions, testResultSelectors } from "./../reducers/testResultReducer"

export const fetchTestRunsThunk =
    (isTvMode: boolean, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            const testRuns = testResultSelectors.testRuns(getState())
            if (testRuns) {
                return
            }
        }

        const response = await dispatch(
            requestThunk<{ testRuns: ITestRun[] }>(
                isTvMode ? endpoints.TestResults.getTestRunsTv : endpoints.TestResults.getTestRuns,
                {}
            )
        )
        dispatch(testResultReducerActions.setTestRens(response.testRuns))
    }
