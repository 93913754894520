import { ActionsUnion, SharedStoreState, createAction } from "swiipe.portal.shared"
import { IGenericModalPropsBase, TAllSharedModals } from "../components/modal/common/SharedSingleModal"
import { IModalCallback } from "../components/modal/common/modalTypes"

export type ModalState = Readonly<{
    modals: (TAllSharedModals & IGenericModalPropsBase & IModalCallback)[]
}>
const initialState: ModalState = { modals: [] }

export const modalReducerActions = {
    addModal: <TModals extends IGenericModalPropsBase>(modal: (TAllSharedModals | TModals) & IModalCallback) =>
        createAction("APP/ADD_MODAL", { modal }),
    removeModal: <TModals extends IGenericModalPropsBase>(modal: (TAllSharedModals | TModals) & IModalCallback) =>
        createAction("APP/REMOVE_MODAL", { modal }),
}

export type ModalActions = ActionsUnion<typeof modalReducerActions>

export const modalReducer = (state: ModalState = initialState, action: ModalActions) => {
    switch (action.type) {
        case "APP/ADD_MODAL": {
            const existingModalOfType = state.modals.find((m) => m.type === action.modal.type)

            //Modal of type already shown
            //Skip if not "terms" modal type
            // TODO: CNE change move terms to shared proejct
            // if (existingModalOfType && (action.modal as TAllSharedModals).type !== "terms") {

            if (existingModalOfType && (action.modal as any).type !== "terms") {
                action.modal.callback("ignored")
                return state
            }

            //Replace modal if "terms" modal type
            if (existingModalOfType && (action.modal as any) === "terms") {
                existingModalOfType.callback("accepted")

                return {
                    ...state,
                    modals: [...state.modals.filter((m) => m.type != action.modal.type), action.modal],
                }
            }

            return {
                ...state,
                modals: [...state.modals, action.modal],
            }
        }
        case "APP/REMOVE_MODAL": {
            return {
                ...state,
                modals: state.modals.filter((m) => m !== action.modal),
            }
        }
    }

    return state
}

export const modalSelectors = {
    modals: <TModals extends IGenericModalPropsBase>(state: SharedStoreState) =>
        state.modal.modals as (TModals & TAllSharedModals & IModalCallback)[],
}
