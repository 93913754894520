import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper, StandardButton, useSelector } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import ShowErrorMessages from "../../components/form/ShowErrorMessages"
import DropDownList from "../../components/form/input/DropDownList"
import CodeEditor from "../../components/form/syntax/CodeEditor"
import PageTitle from "../../components/page/PageTitle"
import TemplateLegend from "../../components/template/TemplateLegend"
import { StoreState } from "../../store/StoreState"
import { termsTemplateSelectors } from "../../store/reducers/termsTemplateReducer"
import { getTermsBaseTemplatesThunk, updateTermsBaseTemplateThunk } from "../../store/thunks/termsTemplateThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getDistinct, sortDescending } from "../../util/arrayUtil"
import { createGuid } from "../../util/stringUtil"
import { valFuncRequired } from "../../util/validationUtil"
import "./TermsBaseTemplatePage.scss"

interface ITermsBaseTemplatePage {}

interface IForm {
    name: string
}

const TermsBaseTemplatePage = ({}: ITermsBaseTemplatePage) => {
    const { handleSubmit, register, errors, formState, setValue } = useForm<IForm>()
    const dispatch = useReduxDispatch()
    const [showHelp, setShowHelp] = useState(false)
    const [content, setContent] = useState("")
    const [newTypeId, setNewTypeId] = useState(createGuid())
    const [dataInitialized, setDataInitialized] = useState(false)
    const [typeId, setTypeId] = useState(newTypeId)

    const termsBaseTemplate = useSelector((state: StoreState) => termsTemplateSelectors.termsBaseTemplate(state, typeId))

    const termsBaseTemplates = useSelector(termsTemplateSelectors.termsBaseTemplates)
    const typeIds = getDistinct(termsBaseTemplates ?? [], (t) => t.typeId).map((t) => t.typeId)

    useEffect(() => {
        dispatch(getTermsBaseTemplatesThunk(false))
    }, [])

    useEffect(() => {
        setContent(termsBaseTemplate?.content ?? "")
        setValue("name", termsBaseTemplate?.name ?? "")
    }, [termsBaseTemplate])

    useEffect(() => {
        if (termsBaseTemplates && !dataInitialized) {
            setDataInitialized(true)
            if (termsBaseTemplates.length > 0) {
                setTypeId(termsBaseTemplates[0].typeId)
            }
        }
    }, [termsBaseTemplates])

    const onSubmit: SubmitHandler<IForm> = async (data) => {
        try {
            await dispatch(
                updateTermsBaseTemplateThunk({
                    content,
                    termsBaseTemplateId: termsBaseTemplate?.termsBaseTemplateId ?? "",
                    name: data.name,
                    revision: termsBaseTemplate?.revision ?? 1,
                    typeId,
                })
            )
            if (typeId === newTypeId) {
                const guid = createGuid()
                setNewTypeId(guid)
            }
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper fullWidth>
            <PageContainer id="terms-base-template-page">
                <PageTitle title="Update Terms Base Template" />
                <div className="ddl-container">
                    <DropDownList
                        label="Terms Base Template Type"
                        containerClassName="terms-base-template-type"
                        value={typeId}
                        onChange={(v) => setTypeId(v.target.value)}
                        options={[
                            ...typeIds
                                .map((typeId) => {
                                    const ts = termsBaseTemplates ?? []
                                    const templateBasesForType = ts.filter((t) => t.typeId === typeId)
                                    const sortedBaseTemplates = sortDescending(templateBasesForType, (t) => t.revision)
                                    return sortedBaseTemplates[0]
                                })
                                .map((x) => ({
                                    text: x.name,
                                    value: x.typeId,
                                }))
                                .filter((x) => x.value !== newTypeId),
                            { text: "Add New Base Template", value: newTypeId },
                        ]}
                    />
                </div>

                <label>Actions</label>
                <div className="actions">
                    <StandardButton
                        className="show-help"
                        isSmall
                        invertedBlue
                        title={showHelp ? "Hide help" : "Show help"}
                        onClick={() => setShowHelp(!showHelp)}
                    />
                </div>

                {showHelp && <TemplateLegend templateType="terms" />}

                <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <div className="name-container">
                        <FloatingLabelInput
                            name="name"
                            defaultValue={termsBaseTemplate?.name ?? ""}
                            innerRef={register(valFuncRequired("Name is required"))}
                            placeholder="Base Template Name"
                        />
                        <SubmitButton formState={formState} isLarge containerClass="save-button">
                            Save Terms Base Template
                        </SubmitButton>
                    </div>
                    <ShowErrorMessages<IForm> errors={errors} />
                </Form>

                <CodeEditor
                    code={content}
                    label="Content"
                    onChange={(code) => setContent(code)}
                    language="html"
                    minHeight={300}
                />
            </PageContainer>
        </PageWrapper>
    )
}

export default TermsBaseTemplatePage
