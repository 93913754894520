import cn from "classnames"
import React from "react"
import "./Adder.scss"

interface IAdderProps {
    onClick: () => void
    buttonText?: string
    className?: string
    alignLeft?: boolean
    noPadding?: boolean
}

const Adder = ({ onClick, buttonText, className, alignLeft, noPadding }: IAdderProps) => {
    return (
        <div
            className={cn("swiipe-adder col-lg-12 bg-white mx-auto rounded", className, noPadding && "swiipe-adder--no-padding")}
        >
            <div className={cn("d-flex", { "justify-content-center": !alignLeft, "justify-content-start": alignLeft })}>
                <button className="btn btn-transparent" onClick={onClick}>
                    <span className="icon icon-addnew"></span>
                    {buttonText && <div className="text-element ml-2 text-uppercase">{buttonText}</div>}
                </button>
            </div>
        </div>
    )
}

export default Adder
