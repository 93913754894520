import cn from "classnames"
import { highlight, languages } from "prismjs"
import "prismjs/components/prism-css"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-json"
import "prismjs/components/prism-markup"
import "prismjs/themes/prism.css"
import React, { useState } from "react"
import Editor from "react-simple-code-editor"
import { StandardButton } from "swiipe.portal.shared"
import "./CodeEditor.scss"

export type TCodeEditorLanguage = "html" | "json" | "css" | "js" | "xml"

interface ICodeEditorProps {
    code: string
    onChange?: (code: string) => void
    language: TCodeEditorLanguage
    height?: number
    maxHeight?: number
    minHeight?: number
    className?: string
    label?: string
    showCopyButton?: boolean
    onSaveKey?: () => void
}

const CodeEditor = ({
    code,
    onChange,
    onSaveKey,
    showCopyButton: showCopyButton,
    height,
    maxHeight,
    minHeight,
    className,
    label,
    language,
}: ICodeEditorProps) => {
    const heightProp = height === undefined ? {} : { height: height }
    const maxHeightProp = maxHeight === undefined ? {} : { maxHeight: maxHeight }
    const minHeightProp = minHeight === undefined ? {} : { minHeight: minHeight }
    const [isTextCopied, setIsTextCopied] = useState(false)

    return (
        <div className={cn("code-editor", className)}>
            {label && <label>{label}</label>}
            <Editor
                value={code}
                onValueChange={(code) => {
                    if (onChange) {
                        onChange(code)
                    }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                    if (onSaveKey && (e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                        onSaveKey()
                        e.preventDefault()
                    }
                }}
                highlight={(code) => getHightlight(code ?? "", language)}
                padding={10}
                className="editor"
                preClassName="editor-pre"
                textareaClassName="editor-textarea"
                style={{
                    ...maxHeightProp,
                    ...heightProp,
                    ...minHeightProp,
                    fontSize: "14px",
                    fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                    overflow: "auto",
                }}
            />
            {showCopyButton && (
                <StandardButton isSmall={true} onClick={() => copyToClipboard(code, setIsTextCopied)}>
                    {isTextCopied ? "Copied!" : "Copy"}
                </StandardButton>
            )}
        </div>
    )
}

function copyToClipboard(code: string, setIsTextCopied: React.Dispatch<React.SetStateAction<boolean>>) {
    navigator.clipboard.writeText(code)
    setIsTextCopied(true)
}

function getHightlight(code: string, language: TCodeEditorLanguage) {
    if (language === "json") {
        return highlight(code, languages.json, "json")
    }
    if (language === "js") {
        return highlight(code, languages.javascript, "javascript")
    }
    if (language === "css") {
        return highlight(code, languages.css, "css")
    }
    if (language === "xml") {
        return highlight(code, languages.xml, "xml")
    }
    return highlight(code, languages.html, "html")
}

export default CodeEditor
