import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IPiqSecrets } from "../../type/paymentIQ/IPiqSecrets"
import { IWebshopPIQOnboardingDetails } from "../../type/paymentIQ/IWebshopPIQOnboardingDetails"
import { StoreState } from "../StoreState"
import { paymentIQReducerActions, paymentIQSelectors } from "../reducers/paymentIQReducer"

//diamond params: Response, state, extraArgs, action
export const fetchPiqSecrets =
    (webshopId: string, swMerchantId: string): ThunkAction<Promise<IPiqSecrets | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const endpointConfig = endpoints.PaymentIQ.getConfigSecrets
        const response = await dispatch(
            requestThunk<IPiqSecrets>(endpointConfig, {
                params: { webshopId, swMerchantId },
            })
        )
        return response
    }

export const fetchOnboardingStatusThunk =
    (webshopId: string, force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!webshopId) {
            return
        }

        if (!force && paymentIQSelectors.onboardingDetails(getState(), webshopId)) {
            return
        }

        const endpointConfig = endpoints.PaymentIQ.getOnboardingStatuses
        const webshopOnboardingDetails = await dispatch(
            requestThunk<IWebshopPIQOnboardingDetails>(endpointConfig, {
                data: { webshopId: webshopId },
            })
        )
        dispatch(paymentIQReducerActions.setOnboardingDetails(webshopId, webshopOnboardingDetails))
    }

export const adminGetApplePaySwiipeInfoThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const applePayEnvOnboarding = paymentIQSelectors.getApplePayEnvOnboarding(getState())
        if (applePayEnvOnboarding?.applePayId) {
            return
        }

        const resp = await dispatch(requestThunk<{ applePayId?: string }>(endpoints.PaymentIQ.adminGetApplePaySwiipeInfo))
        dispatch(paymentIQReducerActions.setApplePayId(resp.applePayId))
    }

export const adminGetApplePaySwiipeSigningCertsThunk =
    (applePayId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const resp = await dispatch(
            requestThunk<{ paymentProcessingCsr: string; merchantIdentityCsr: string }>(
                endpoints.PaymentIQ.adminGetApplePaySwiipeSigningCerts,
                {
                    params: {
                        applePayId,
                    },
                }
            )
        )
        dispatch(paymentIQReducerActions.setApplePayCsrs(resp.paymentProcessingCsr, resp.merchantIdentityCsr))
    }

export const adminEnableApplePaySwiipeCertsThunk =
    (applePayId: string, uploadedCertificates: File): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(
                endpoints.PaymentIQ.adminEnableApplePaySwiipeCerts,
                {
                    data: {
                        applePayId,
                        merchantCertificateFile: uploadedCertificates,
                    },
                },
                { asFormData: true }
            )
        )

        await dispatch(adminGetApplePaySwiipeInfoThunk())
    }
