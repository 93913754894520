import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export interface IQuickpayDataDto {
    webshopId: string
    qpMerchantId?: string
}

export type QuickpayState = Readonly<{
    quickpayData: {
        [merchantId: string]: IQuickpayDataDto[]
    }
}>

const initialState: QuickpayState = {
    quickpayData: {},
}

export const quickpayDataReducerActions = {
    extendQuickpayDataForMerchant: (quickpayData: IQuickpayDataDto, merchantId: string) =>
        createAction("APP/EXTEND_QUICKPAY_DATA", { quickpayData, merchantId }),
}

export type QuickpayDataActions = ActionsUnion<typeof quickpayDataReducerActions>

export const quickpayDataReducer = (state: QuickpayState = initialState, action: QuickpayDataActions) => {
    switch (action.type) {
        case "APP/EXTEND_QUICKPAY_DATA": {
            // Maybe make sure duplicates aren't present
            state.quickpayData[action.merchantId] =
                state.quickpayData[action.merchantId]?.filter((qpData) => qpData.webshopId !== action.quickpayData.webshopId) ||
                []
            return {
                ...state,
                quickpayData: {
                    ...state.quickpayData,
                    [action.merchantId]: [...(state.quickpayData[action.merchantId] || []), action.quickpayData],
                },
            }
        }
        default:
            return state
    }
}

export const quickpayDataSelectors = {
    getQuickpayData: (state: StoreState, merchantId: string, webshopId: string) =>
        state.quickpay.quickpayData[merchantId]?.find((quickpayData: IQuickpayDataDto) => quickpayData.webshopId === webshopId),
}
