import cn from "classnames"
import React from "react"
import Spinner from "../../images/loading/spinner.gif"
import "./SpinnerContainer.scss"

interface ISpinnerContainerProps {
    showSpinner: boolean
    className?: string
    children?: React.ReactNode
    maxHeight?: number
    minHeight?: number
}

export const SpinnerContainer = ({ children, showSpinner, className, maxHeight, minHeight }: ISpinnerContainerProps) => {
    return (
        <div
            className={cn("swiipe-spinner-container clearfix", className)}
            style={
                showSpinner
                    ? {
                          ...(minHeight ? { minHeight: minHeight } : {}),
                          ...(maxHeight ? { maxHeight: maxHeight } : {}),
                      }
                    : {}
            }
        >
            <div className={cn("child-wrapper", { hide: showSpinner })}>{children}</div>
            {showSpinner && <img className="spinner" src={Spinner} />}
        </div>
    )
}
