import React from "react"
import cn from "classnames"
import "./EmailTemplateImageDisplay.scss"
import { IEmailTemplateImage } from "../../store/reducers/emailTemplateReducer"
import { getFileSizeStrInKb } from "../../util/fileUtil"

interface IEmailTemplateImageDisplayProps {
    className?: string
    language: string
    image: IEmailTemplateImage
}

const EmailTemplateImageDisplay = ({ className, image, language }: IEmailTemplateImageDisplayProps) => {
    const imageExistsForLanguage = image && image.language === language
    const imageExistsForShared = image && image.language !== language && image.language === "shared"
    const imageExistsForOtherLanguage = image && image.language !== language && image.language !== "shared"

    return (
        <div className={cn("email-template-image-display", className)}>
            <div
                className={cn("image-container", {
                    green: imageExistsForLanguage,
                    blue: imageExistsForShared,
                    red: imageExistsForOtherLanguage,
                })}
            >
                <div className="image">{image && <img src={image.url} />}</div>
                <div className="info-column">
                    <div>
                        <div className="name">{image.name}</div>
                        {imageExistsForOtherLanguage && (
                            <>
                                <div>Language: {image.language}</div>
                            </>
                        )}
                        {imageExistsForLanguage && (
                            <>
                                <div>Width: {image.width}</div>
                                <div>Height: {image.height}</div>
                                <div>Size: {getFileSizeStrInKb(image.size)}</div>
                                <div>Type: {image.type}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailTemplateImageDisplay
