import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper } from "swiipe.portal.shared"
import SpinnerContainer from "../../../components/buttons/SpinnerContainer"
import SubmitButton from "../../../components/buttons/SubmitButton"
import FieldsetFormGroup from "../../../components/form/FieldsetFormGroup"
import WebshopSelect from "../../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import {
    IGoogleAnalyticsEventsOverviewResponse,
    adminGetGoogleAnalyticsEventsOverviewThunk,
    adminGetGoogleAnalyticsWebshopDataThunk,
    adminSetGoogleAnalyticsWebshopDataThunk,
} from "../../../store/thunks/googleAnalyticsThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import "./GoogleAnalyticsEventOverrides.scss"

const GoogleAnalyticsEventOverrides = () => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState("")

    const [isFormLoading, setIsFormLoading] = useState(true)
    const [isOverviewLoading, setIsOverviewLoading] = useState(false)

    if (!merchantDetails || !merchantDetails.swMerchant.swMerchantId) {
        return null
    }

    return (
        <div className="google-analytics-overrides">
            <WebshopSelect
                merchantDetails={merchantDetails}
                onWebshopChange={(selectedWebshopId) => setSelectedWebshopId(selectedWebshopId)}
            />
            <PageWrapper>
                <PageContainer>
                    <SpinnerContainer showSpinner={isFormLoading}>
                        <OverridesDisclaimer selectedWebshopId={selectedWebshopId} />
                        <OverridesForm
                            swMerchantId={merchantDetails.swMerchant.swMerchantId}
                            selectedWebshopId={selectedWebshopId}
                            onFormIsLoadingStateChange={(isLoading) => setIsFormLoading(isLoading)}
                        />
                    </SpinnerContainer>
                </PageContainer>
                <PageContainer>
                    <SpinnerContainer showSpinner={isOverviewLoading}>
                        <EventsOverview
                            swMerchantId={merchantDetails.swMerchant.swMerchantId}
                            selectedWebshopId={selectedWebshopId}
                            onOverviewIsLoadingStateChange={(isLoading) => setIsOverviewLoading(isLoading)}
                        />
                    </SpinnerContainer>
                </PageContainer>
            </PageWrapper>
        </div>
    )
}

const OverridesDisclaimer = (props: { selectedWebshopId: string }) => {
    return (
        <div className="google-analytics-overrides__disclaimer">
            Standard event names:
            <ul>
                <li>
                    Begin checkout - <span>begin_checkout</span>
                </li>
                <li>
                    Purchase - <span>purchase</span>
                </li>
            </ul>
            <p>
                For changes to take effect, in <span>performedPerWebshopDailyRuns</span> table DELETE row with <br />
                RowKey: <span>{props.selectedWebshopId}</span> <br />
                PartitonKey: <span>webshop_external_analytics_daily</span>
            </p>
        </div>
    )
}

interface IOverridesFormProps {
    swMerchantId: string
    selectedWebshopId: string
    onFormIsLoadingStateChange(isLoading: boolean): void
}
const OverridesForm = ({ swMerchantId, selectedWebshopId, onFormIsLoadingStateChange }: IOverridesFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    type EventNamesOverridesForm = {
        beginCheckoutEventName: string
        purchaseEventName: string
    }
    const { handleSubmit, register, formState, setValue } = useForm<EventNamesOverridesForm>()
    const onSubmit: SubmitHandler<EventNamesOverridesForm> = async (data) => {
        await dispatch(
            adminSetGoogleAnalyticsWebshopDataThunk(swMerchantId, selectedWebshopId, {
                eventNamesOverrides: {
                    beginCheckoutEventName: data.beginCheckoutEventName,
                    purchaseEventName: data.purchaseEventName,
                },
            })
        )
    }
    useEffect(() => {
        if (!selectedWebshopId) {
            return
        }
        ;(async () => {
            onFormIsLoadingStateChange(true)
            const webshopData = await dispatch(adminGetGoogleAnalyticsWebshopDataThunk(swMerchantId, selectedWebshopId))
            setValue("beginCheckoutEventName", webshopData.eventNamesOverrides.beginCheckoutEventName)
            setValue("purchaseEventName", webshopData.eventNamesOverrides.purchaseEventName)
            onFormIsLoadingStateChange(false)
        })()
    }, [selectedWebshopId])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldsetFormGroup>
                <FloatingLabelInput
                    placeholder="Begin checkout event name override"
                    name="beginCheckoutEventName"
                    innerRef={register}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup>
                <FloatingLabelInput placeholder="Purchase event name override" name="purchaseEventName" innerRef={register} />
            </FieldsetFormGroup>
            <SubmitButton dark noBorder formState={formState}>
                {t("common.submit")}
            </SubmitButton>
        </Form>
    )
}

interface IEventsOverviewProps {
    swMerchantId: string
    selectedWebshopId: string
    onOverviewIsLoadingStateChange(isLoading: boolean): void
}
const EventsOverview = ({ swMerchantId, selectedWebshopId, onOverviewIsLoadingStateChange }: IEventsOverviewProps) => {
    const dispatch = useReduxDispatch()
    const [overview, setOverview] = useState<IGoogleAnalyticsEventsOverviewResponse | undefined>()

    useEffect(() => {
        if (!selectedWebshopId) {
            return
        }
        ;(async () => {
            onOverviewIsLoadingStateChange(true)
            try {
                const overviewResponse = await dispatch(
                    adminGetGoogleAnalyticsEventsOverviewThunk(swMerchantId, selectedWebshopId)
                )
                setOverview(overviewResponse)
            } catch {
                setOverview({
                    events: [],
                })
            }
            onOverviewIsLoadingStateChange(false)
        })()
    }, [selectedWebshopId])

    if (!overview) {
        return null
    }

    return (
        <div className="ga-events-overview">
            <p className="ga-events-overview__title">Events overview:</p>
            {overview.events.length === 0 ? (
                <div>No events registered. Either webshop is not onboarded or no data for the past 3 weeks</div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Event name</th>
                            <th>Engaged sessions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overview.events.map((e) => (
                            <tr key={e.name}>
                                <td>{e.name}</td>
                                <td>{e.engagedSessions}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default GoogleAnalyticsEventOverrides
