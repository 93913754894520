import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { FloatingLabelInput, StandardButton, TextButton } from "swiipe.portal.shared"
import SpinnerContainer from "../../../../components/buttons/SpinnerContainer"
import SubmitButton from "../../../../components/buttons/SubmitButton"
import "./DataRow.scss"

interface DataRowProps {
    id?: string
    attributeName?: string
    value?: string
    withoutCopyBtn?: boolean
    additionalInfo?: string
    onEditCallback?: (newValue: string) => Promise<void>
    onDeleteCallback?: () => Promise<void>
    editableTextArea?: boolean
    showEditButtonsInRow?: boolean
}

interface DataRowFormProps {
    inputValue: string
}

const DataRow = ({
    attributeName,
    value,
    additionalInfo,
    withoutCopyBtn,
    onEditCallback,
    onDeleteCallback,
    editableTextArea = false,
    showEditButtonsInRow = false,
}: DataRowProps) => {
    const editable = !!onEditCallback
    const { handleSubmit, formState, register } = useForm<DataRowFormProps>()

    const [isEditing, setIsEditing] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const onSubmit: SubmitHandler<DataRowFormProps> = async (data) => {
        try {
            setIsSaving(true)
            setIsEditing(false)
            if (value !== data.inputValue) {
                await onEditCallback?.(data.inputValue)
                setInputValue(data.inputValue)
            }
        } catch (err) {
            // Catch to stop showing spinner
        } finally {
            setIsSaving(false)
        }
    }

    const [inputValue, setInputValue] = useState(value ?? "")

    useEffect(() => {
        setInputValue(value ?? "")
    }, [value])

    if (!value && !editable) return null

    const isRowEditableEmpty = editable && !value && !isEditing

    return (
        <SpinnerContainer showSpinner={formState.isSubmitting || isSaving} aboveContent>
            <div className={cn("data-row", editable && "data-row--editable", isRowEditableEmpty && "data-row--editable-empty")}>
                <div>
                    <span onClick={() => editable && setIsEditing(true)}>
                        {isRowEditableEmpty && "+ "}
                        <b>
                            {attributeName}
                            {attributeName && inputValue && ": "}
                        </b>
                        {!isEditing && (
                            <>
                                {inputValue ?? ""} {additionalInfo && "(" + additionalInfo + ")"}
                            </>
                        )}
                        {editable && value && <img height="20px" src={require("../../../../images/edit-icon.png")} />}
                    </span>
                </div>
                {!isEditing && !withoutCopyBtn && !!inputValue && (
                    <div>
                        <StandardButton
                            className="copy-btn"
                            isTiny={true}
                            onClick={() => navigator.clipboard.writeText(inputValue ?? "")}
                        >
                            {"Copy"}
                        </StandardButton>
                    </div>
                )}
                {isEditing && (
                    <Form className="data-row__form" onSubmit={handleSubmit(onSubmit)}>
                        {!editableTextArea && (
                            <FloatingLabelInput
                                required
                                containerClass="data-row__input"
                                name="inputValue"
                                innerRef={(el) => {
                                    register(el)
                                }}
                                inputMode="decimal"
                                defaultValue={inputValue}
                            ></FloatingLabelInput>
                        )}
                        {editableTextArea && (
                            <textarea
                                required
                                name="inputValue"
                                ref={(el) => {
                                    register(el)
                                }}
                                className="data-row__textarea"
                                defaultValue={inputValue}
                            />
                        )}
                        <div className={cn("data-row__buttons", showEditButtonsInRow && "data-row__buttons--in-row")}>
                            <SubmitButton formState={formState} title="Save" className="data-row__save-button" isTiny={true} />
                            <TextButton
                                className="data-row__cancel-button"
                                text="Cancel"
                                onClick={async () => {
                                    setIsEditing(false)
                                }}
                            />
                            {onDeleteCallback && (
                                <TextButton
                                    className="data-row__delete-button"
                                    text="Delete"
                                    onClick={async () => {
                                        setIsSaving(true)
                                        await onDeleteCallback()
                                        setIsEditing(false)
                                        setIsSaving(false)
                                    }}
                                />
                            )}
                        </div>
                    </Form>
                )}
            </div>
        </SpinnerContainer>
    )
}

export default DataRow
