import React, { Children } from "react"
import CodeEditor from "../form/syntax/CodeEditor"
import "./TemplateLegend.scss"

export type TemplateType = "email" | "sms" | "terms"
interface ITemplateLegendProps {
    templateType: TemplateType
}

const TemplateLegend = ({ templateType }: ITemplateLegendProps) => {
    const legendData: { code: string; text: string; templateTypes: TemplateType[] }[] = [
        {
            code: `{_My text to translate_}`,
            text: 'Translate text. "My text to translate" will be added as a key in at the bottom of the page. Space around the text is supported.',
            templateTypes: ["terms"],
        },
        {
            code: `{_My text to translate {{x}} more text_}`,
            text: "Use variables as part of the text. The variable must be within the translated text without changes to it.",
            templateTypes: ["terms"],
        },
        {
            code: `{_p style="color:red;"|My text to translate_}`,
            text: "Start the translation with the tag that should surround the text and separate by pipe character '|'. When you do this, omitting the text for a language will also omit the surrounding tag.",
            templateTypes: ["terms"],
        },
        { code: `{{x}}`, text: "Add data on root level - x can be anything", templateTypes: ["email", "sms", "terms"] },
        {
            code: `{{x.MyLevel2Property.MyLevel3Property}}`,
            text: "Add data not on root level - x can be anything",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `{{x.MyLevel2Property|My fallback text}}`,
            text: "Use pipe '|' to add a text that is inserted, if the data cannot be resolved",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `[[my-image-name]]`,
            text: "The url of the image my-image-name will be inserted. This can e.g. be used in img-tag src property.",
            templateTypes: ["email"],
        },
        {
            code: `<If {{x.MyLevel2Property}}><div>{{x.MyLevel2Property}}</div></If>`,
            text: "Only show if x.MyLevel2Property exists or is true",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `<If {{x.MyLevel2Property1}} {{x.MyLevel2Property2}}><div>{{x.MyLevel2Property3}}</div></If>`,
            text: "Only show if x.MyLevel2Property1 is equal to x.MyLevel2Property2",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `<If {{x.MyLevel2Property1}} "my value"><div>{{x.MyLevel2Property2}}</div></If>`,
            text: "Only show if x.MyLevel2Property1 is equal to my value",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `<Not {{x.MyLevel2Property}}><div>{{x.MyLevel2Property}}</div></Not>`,
            text: "Only show if x.MyLevel2Property does not exist or is false",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `<Loop {{x.MyLevel2ListProperty}}><div>
    <div>{{x}}</div>
    <div>{{x.MyLevel3Property}}</div>
    <div>{{x.MyLevel2Property}}</div>
</div></Loop>
<Loop {{x.MyLevel2ListProperty}} separator=", ">
    <div>{{x}}</div>
</Loop>`,
            text: "Loop properties that are lists. Inside the loop content, it will try to find properties on item looped (x) and fallbacks to root item. Use 'separator' attribute to add separator after each root item in the loop (except last one)",
            templateTypes: ["email", "sms", "terms"],
        },
        {
            code: `<Placeholder MyPlaceholderName><div>MyContent</div></Placeholder>`,
            text: "When using a base template, the content needs to be wrapped in a placeholder, so that the content can be added to the right place in the base template. Here MyPlaceholderName is the identifier.",
            templateTypes: ["email", "terms"],
        },
        {
            code: `{{Date(year, month, date)}}`,
            text: "Will format the date for the used language. You can omit the date part to get a year and month format.",
            templateTypes: ["terms"],
        },
        {
            code: `{{Date(year, month, day, format)}}`,
            text: "The date will be formatted according to the language used. To get only the year and month, you can omit the day. If you want to specify the format, add an optional fourth parameter (supported formats: 'short' and 'long'). If no format is specified, then 'long' or 'month year' will be used.",
            templateTypes: ["email"],
        },
        {
            code: `{{Date(x.MyYearProperty, x.MyMonthProperty, x.MyDayProperty, format)}}`,
            text: "The date will be formatted according to the language used. To get only the year and month, you can omit the day. If you want to specify the format, add add an optional fourth parameter (supported formats: 'short' and 'long'). If no format is specified, then 'long' or 'month year' will be used.",
            templateTypes: ["email"],
        },
        {
            code: `{{Price(amount, [format, convertFromCurrency, noDot])}}`,
            text: "Example: {{Price(12.5, noDot, DKK, iso)}} Format the amount. The format can be 'default', 'iso', 'symbol' or 'percent'. If omitted then default will be used. Default will use symbol if currency is primary for the language. convertFromCurrency will convert the amount to the correct currency. If omitted then no conversion will happen.",
            templateTypes: ["terms"],
        },
        {
            code: `{{Price(priceType, [format, noDot])}}`,
            text: "Example: {{Price(PriceCatSwiipePspMonthly)}} Format the price with the given priceType. The format can be 'default', 'iso', 'symbol' or 'percent'. If omitted the best one is chosen, either default or percent.",
            templateTypes: ["terms"],
        },
        {
            code: `{{Price(Market1:Price1, Market2:Price2, ..., [format, noDot])}}`,
            text: "Example: {{Price(Denmark:90.5, Sweden: 100, EU: 50, Other: 50)}} Choose the price per market. If market is missing in the list, the first market will be used with a currency conversion.",
            templateTypes: ["terms"],
        },
    ]

    return (
        <div className="template-legend">
            {legendData.map((d, i) => {
                if (!d.templateTypes.includes(templateType)) {
                    return null
                }

                return (
                    <div key={i} className="legend">
                        <CodeEditor code={d.code} language="html" />
                        <div className="text">{d.text}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default TemplateLegend
