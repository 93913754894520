import { getCulture } from "../locale/lang"

export function formatLocalDate(dateOrStr: Date | string | undefined, dateFormat: "full" | "long" | "medium" | "short") {
    const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr
    if (!date) {
        return ""
    }
    return date.toLocaleDateString(getCulture(), {
        dateStyle: dateFormat,
    })
}
