import React from "react"
import { useTranslation } from "react-i18next"
import {
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboardingOwner,
    getFullNameFromFirstAndLastName,
    getKeys,
    hasValue,
    sanitizeData,
} from "swiipe.portal.shared"
import { getmerchantPreOnboardingServiceTitle } from "../../services/merchantOfferService"
import "./MerchantOfferIntro.scss"

interface IMerchantOfferIntroProps {
    message?: string
    owner: IMerchantPreOnboardingOwner | undefined
    services?: IMerchantOnboardingServicesSubForm
}

export const MerchantOfferIntro = ({ message, services, owner }: IMerchantOfferIntroProps) => {
    const { t } = useTranslation()

    return (
        <div className="merchant-offer-intro">
            <div className="pb-4">
                <b>
                    {t("common.dear", {
                        name: getFullNameFromFirstAndLastName(owner?.firstName, owner?.lastName),
                    })}
                </b>
            </div>
            <p dangerouslySetInnerHTML={{ __html: sanitizeData(message?.replaceAll("\n", "<br/>") ?? "") }}></p>
            <div className="mb-2 merchant-offer-intro__services-title">
                <b>{t("merchantOffer.intro.chosenText")}:</b>
            </div>
            <ul className="merchant-offer-intro__services-overview">
                {getKeys(services ?? {})
                    .filter((s) => !!services?.[s])
                    .map((s) => getmerchantPreOnboardingServiceTitle(s))
                    .filter(hasValue)
                    .map((serviceTitle) => (
                        <li className="merchant-pre-onboarding-details-page__top-right-service" key={serviceTitle}>
                            {t(serviceTitle)}
                        </li>
                    ))}
            </ul>
            <div className="merchant-offer-intro__services-desc">{t("merchantOffer.intro.desc")}</div>
        </div>
    )
}
