import React, { useState } from "react"
import { modalReducerActions, modalSelectors } from "../../../store/modalReducer"
import { useReduxDispatchShared } from "../../../store/useReduxDispatchShared"
import ModalError, { IModalError } from "../shared/ModalError"
import ModalStandardInfo, { IModalStandardInfo } from "../shared/ModalStandardInfo"
import ModalVerify, { IModalVerify } from "../shared/ModalVerify"
import { IModalCallback, IModalControlProps, TModalResponseType } from "./modalTypes"

export type TAllSharedModals = IModalError | IModalVerify | IModalStandardInfo
export type IGenericModalPropsBase = { type: string }

interface ISingleModalProps<TModals extends IGenericModalPropsBase> {
    modal: (TAllSharedModals | TModals) & IModalCallback
    renderModal: (modal: TModals & IModalCallback, controlProps: IModalControlProps) => React.ReactNode
}

export function initializeModals<TModals extends IGenericModalPropsBase & IModalCallback>() {
    return {
        getModals: () => modalSelectors.modals<TModals>,
        addModal: (modal: TModals) => modalReducerActions.addModal(modal),
        removeModal: (modal: TModals) => modalReducerActions.removeModal(modal),
    }
}

export const SharedSingleModal = <TModals extends IGenericModalPropsBase>({ modal, renderModal }: ISingleModalProps<TModals>) => {
    const { callback: sharedCallback, ...sharedModalWithoutCallback } = modal as TAllSharedModals & IModalCallback

    const [modalResponse, setModalResponse] = useState<[TModalResponseType, any | undefined]>()
    const dispatch = useReduxDispatchShared()

    const controlProps = {
        isOpen: !modalResponse,
        callback: (res: TModalResponseType, data?: any) => {
            setModalResponse([res, data])
        },
        onClosed: () => {
            dispatch(modalReducerActions.removeModal(modal))
            sharedCallback(modalResponse ? modalResponse[0] : "ignored", modalResponse ? modalResponse[1] : undefined)
        },
    }

    switch (sharedModalWithoutCallback.type) {
        case "error":
            return <ModalError {...sharedModalWithoutCallback} {...controlProps} />
        case "verify":
            return <ModalVerify {...sharedModalWithoutCallback} {...controlProps} />
        case "standardInfo":
            return <ModalStandardInfo {...sharedModalWithoutCallback} {...controlProps} />
        default:
            return renderModal(modal as TModals & IModalCallback, controlProps)
    }
}
