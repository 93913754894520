import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Form, Input } from "reactstrap"
import { StandardButton, StandardButtonWithSpinner, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { termsLanguages } from "../../../data/termsLanguages"
import termsTemplateTypeSpecs from "../../../data/termsTemplateTypeSpecs"
import {
    cleanFileUrl,
    cleanTranslation,
    getIsNewVersion,
    getLatestTemplate,
    getTranslationsFromContent,
    getTranslationsToSave,
    isEditTermsTemplateSupportedOnEnvironment,
    mergePlaceholders,
    prepareTemplateTranslationKeyForFormsLib,
    revertTemplateTranslationKeyFromFormsLib,
} from "../../../services/termsTemplateService"
import { StoreState } from "../../../store/StoreState"
import { termsTemplateSelectors } from "../../../store/reducers/termsTemplateReducer"
import { addModalThunk } from "../../../store/thunks/modalThunks"
import { getAllTermsTemplateTranslationsThunk, updateTermsTemplateThunk } from "../../../store/thunks/termsTemplateThunks"
import useReduxDispatch from "../../../store/useReduxDispatch"
import { ETermsType } from "../../../type/terms/ETermsType"
import { ITermsTemplateFile } from "../../../type/terms/ITermsTemplateFile"
import { getDistinct, hasValue, sortAscending, sortDescending, toDict } from "../../../util/arrayUtil"
import { getKeys } from "../../../util/objectUtils"
import SpinnerContainer from "../../buttons/SpinnerContainer"
import SubmitButton from "../../buttons/SubmitButton"
import ShowErrorMessages from "../ShowErrorMessages"
import DropDownList from "../input/DropDownList"
import FormGroupWithCheckbox from "../input/FormGroupWithCheckbox"
import CodeEditor from "../syntax/CodeEditor"
import "./TermsTemplateForm.scss"

interface ITermsTemplateFormProps {
    version: string
    revision: number
    termsType: ETermsType
}

export interface ITermsTemplateTranslationRow {
    translation: string
    noFallback: boolean
}

export interface ITermsTemplateFormFile {
    fileUrl: string
    isInternal: boolean
}

interface ITermsTemplateForm {
    baseTemplateTypeId: string
    allowRelease: boolean
    requiresConsent: boolean
    isFile: boolean
    translations: { [language: string]: { [key: string]: ITermsTemplateTranslationRow } }
    files: { [language: string]: ITermsTemplateFormFile }
}

const TermsTemplateForm = ({ version, revision, termsType }: ITermsTemplateFormProps) => {
    const dispatch = useReduxDispatch()
    const { handleSubmit, register, errors, formState, setValue, getValues, reset, watch, trigger } =
        useForm<ITermsTemplateForm>()
    const [content, setContent] = useState("")
    const [hideContent, setHideContent] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const watchBaseTemplateTypeId = watch("baseTemplateTypeId", "")
    const watchIsFile = watch("isFile", false)
    const [language, setLanguage] = useState(termsLanguages[0].code)
    const environment = useSelector(sharedConfigurationSelectors.environment)
    const [translationState, setTranslationState] = useState<{
        [language: string]: { [key: string]: ITermsTemplateTranslationRow }
    }>({})
    const [fileState, setFileState] = useState<{
        [language: string]: ITermsTemplateFormFile
    }>({})
    const [showTermsBaseTemplate, setShowTermsBaseTemplate] = useState(false)

    const termsTemplateVersions = useSelector((state: StoreState) =>
        termsTemplateSelectors.termsTemplateDetails(state, termsType)
    )
    const termsTemplate = (termsTemplateVersions ?? []).find((t) => t.version === version && t.revision == revision)
    const disableInput = termsTemplate?.isReleased || !isEditTermsTemplateSupportedOnEnvironment(environment)

    const savedTranslations = useSelector((state: StoreState) =>
        termsTemplateSelectors.termsTemplateTranslations(state, termsType)
    )
    const savedFiles = useSelector((state: StoreState) => termsTemplateSelectors.termsTemplateFiles(state, termsType))

    const savedTranslationsForTemplateId = (savedTranslations ?? []).filter(
        (t) => t.termsTemplateId === termsTemplate?.termsTemplateId
    )

    const termsTemplateType = termsTemplateTypeSpecs[termsType]

    const termsBaseTemplates = useSelector((state: StoreState) => termsTemplateSelectors.termsBaseTemplates(state))
    const termsBaseTemplate = termsBaseTemplates?.find((x) => x.typeId === watchBaseTemplateTypeId)
    const translationsFromContent = useMemo(
        () => getTranslationsFromContent(mergePlaceholders(content, termsBaseTemplate?.content ?? "")),
        [content, termsBaseTemplate]
    )

    const translationKeys = sortAscending(
        getDistinct([...savedTranslationsForTemplateId.map((st) => st.key), ...translationsFromContent]),
        (key) => {
            const index = translationsFromContent.indexOf(key)
            return index
        }
    )

    useEffect(() => {
        const isNewVersion = getIsNewVersion(termsTemplateVersions ?? [], version, revision)
        const latestTemplate = getLatestTemplate(termsTemplateVersions ?? [])
        const takeValuesFromTemplate = isNewVersion ? latestTemplate : termsTemplate

        const translations = (savedTranslations ?? []).filter(
            (t) => t.termsTemplateId === takeValuesFromTemplate?.termsTemplateId
        )

        const initialTranslationState = toDict(
            getDistinct(translations.map((t) => t.language)),
            (l) => l,
            (l) =>
                toDict(
                    translations.filter((t) => t.language === l),
                    (t) => prepareTemplateTranslationKeyForFormsLib(t.key),
                    (t) => ({ translation: t?.translation || "", noFallback: t?.noFallback ?? false })
                )
        )

        const files = (savedFiles ?? []).filter((t) => t.termsTemplateId === takeValuesFromTemplate?.termsTemplateId)

        const initialFileState = toDict<string, string, ITermsTemplateFormFile>(
            termsLanguages.map((tl) => tl.code),
            (l) => l,
            (l) =>
                files.find((f) => f.language === l) ?? {
                    fileUrl: "",
                    isInternal: true,
                }
        )

        const defaultFormState: ITermsTemplateForm = {
            isFile: takeValuesFromTemplate?.type === "File",
            baseTemplateTypeId: takeValuesFromTemplate?.termsBaseTemplateTypeId ?? termsTemplate?.termsBaseTemplateTypeId ?? "",
            translations: initialTranslationState,
            files: initialFileState,
            allowRelease: (!isNewVersion && takeValuesFromTemplate?.allowRelease) ?? false,
            requiresConsent: (!isNewVersion && takeValuesFromTemplate?.requiresConsent) ?? false,
        }
        reset(defaultFormState)
        setContent(takeValuesFromTemplate?.content ?? termsTemplate?.content ?? "")
        setTranslationState(initialTranslationState)
        setFileState(initialFileState)
    }, [version, revision, termsTemplateVersions])

    const onSubmit: SubmitHandler<ITermsTemplateForm> = async (data) => {
        try {
            setIsSaving(true)
            const formTranslations = {
                ...translationState,
                ...data.translations,
            }

            const formFiles = {
                ...fileState,
                ...data.files,
            }

            const translationToSave = watchIsFile ? [] : translationsFromContent
            const translationsToSaveDtos = getTranslationsToSave(
                termsTemplate?.termsTemplateId ?? "",
                termsType,
                toDict(
                    getKeys(formTranslations),
                    (l) => l + "",
                    (l) =>
                        toDict(
                            getKeys(formTranslations[l]),
                            (key) => revertTemplateTranslationKeyFromFormsLib(key + ""),
                            (key) => {
                                const val = formTranslations[l][key]
                                if (!val.translation && !val.noFallback && l === "en") {
                                    val.translation = revertTemplateTranslationKeyFromFormsLib(key + "")
                                }
                                return val
                            }
                        )
                ),
                savedTranslationsForTemplateId,
                translationToSave,
                termsLanguages.map((l) => l.code)
            )

            const filesToSave = watchIsFile ? formFiles : {}
            const filesToSaveDtos = getKeys(filesToSave)
                .map<ITermsTemplateFile | undefined>((language) => {
                    const formFile = filesToSave[language]
                    if (!formFile.fileUrl) {
                        return undefined
                    }
                    return {
                        termsTemplateId: termsTemplate?.termsTemplateId ?? "",
                        termsType,
                        language: language + "",
                        fileUrl: formFile.fileUrl,
                        isInternal: formFile.isInternal,
                    }
                })
                .filter(hasValue)

            const translationsToBeDeleted = translationKeys.filter((tk) => !translationToSave.includes(tk))
            if (translationsToBeDeleted.length > 0) {
                const verifyResult = await dispatch(
                    addModalThunk({
                        type: "verify",
                        text: `${translationsToBeDeleted.length} ${
                            translationsToBeDeleted.length > 1 ? "translations" : "translation"
                        } will be deleted. Are you sure you want to proceeed?`,
                    })
                )
                if (verifyResult.type !== "accepted") {
                    setIsSaving(false)
                    return
                }
            }

            await dispatch(
                updateTermsTemplateThunk(
                    {
                        content,
                        termsBaseTemplateTypeId: data.baseTemplateTypeId,
                        allowRelease: data.allowRelease,
                        requiresConsent: data.requiresConsent,
                        version: version,
                        revision: revision,
                        termsType,
                        termsTemplateId: termsTemplate?.termsTemplateId ?? "",
                        relationTypes: termsTemplateType.relationTypes,
                        productType: termsTemplateType.productType,
                        provider: termsTemplateType.provider,
                        category: termsTemplateType.category,
                        type: data.isFile ? "File" : "ManagedContent",
                    },
                    translationsToSaveDtos,
                    filesToSaveDtos
                )
            )
            toast.success("Saved successfully", { position: "bottom-center" })
            setIsSaving(false)
        } catch (err) {
            toast.error("Failed to save", { position: "bottom-center" })
            setIsSaving(false)
        }
    }

    const forceSubmit = () => {
        trigger()
        handleSubmit(onSubmit)()
    }

    const languageSwitcher = (
        <DropDownList
            containerClassName="terms-template-form__language-switcher"
            label="Language"
            value={language}
            onChange={(v) => {
                // Remember changes to language switching away from
                const formValues = getValues()
                const currentForm: ITermsTemplateForm = formValues
                if (watchIsFile) {
                    fileState[language] = formValues.files?.[language] ?? {}
                    setFileState(fileState)
                } else {
                    translationState[language] = formValues.translations?.[language] ?? {}
                    setTranslationState(translationState)
                }
                setLanguage(v.target.value)
                reset({ ...currentForm, translations: translationState, files: fileState })
            }}
            options={termsLanguages.map((x) => ({ text: x.label, value: x.code }))}
        />
    )

    return (
        <div className="terms-template-form">
            <SpinnerContainer showSpinner={formState.isSubmitting || isSaving} aboveContent fullScreen>
                <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                    <div className="terms-template-form__save-container">
                        <FormGroupWithCheckbox
                            className="terms-template-form__is-file"
                            name={"isFile"}
                            innerRef={register}
                            title="Terms is file"
                            hint="If the terms are static files like PDFs, then enable this"
                        />
                        {!watchIsFile && (
                            <StandardButton
                                className="terms-template-form__hide-content-button"
                                title={hideContent ? "Show content" : "Hide content"}
                                invertedBlue
                                isGrey={!hideContent}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setHideContent(!hideContent)
                                }}
                            />
                        )}
                        <SubmitButton
                            disabled={disableInput}
                            formState={formState}
                            containerClass="terms-template-form__save-button"
                        >
                            Save Terms Template (or press Ctrl + S inside a text field)
                        </SubmitButton>
                    </div>
                    <ShowErrorMessages<ITermsTemplateForm> errors={errors} />
                </Form>
                <h4 className="mt-4">Settings</h4>
                {!watchIsFile && (
                    <div className="terms-template-form__base-template-container">
                        <DropDownList
                            disabled={disableInput}
                            label="Terms Base Template"
                            className="terms-template-form__base-template-ddl"
                            name="baseTemplateTypeId"
                            innerRef={register()}
                            options={[
                                { text: "None", value: "" },
                                ...(termsBaseTemplates ?? []).map((x) => ({
                                    text: x.name,
                                    value: x.typeId,
                                })),
                            ]}
                        />

                        <StandardButton
                            className="terms-template-form__base-template-show-code"
                            isSmall
                            invertedBlue
                            title={showTermsBaseTemplate ? "Hide Base Template Code" : "Show Base Template Code"}
                            onClick={() => setShowTermsBaseTemplate(!showTermsBaseTemplate)}
                        />

                        {showTermsBaseTemplate && termsBaseTemplate && (
                            <CodeEditor
                                className="terms-template-form__base-template-code"
                                code={
                                    termsTemplate?.isReleased
                                        ? termsTemplate.releasedBaseTemplateContent ?? ""
                                        : termsBaseTemplate.content
                                }
                                label="Terms Base Template Code"
                                language="html"
                            />
                        )}
                    </div>
                )}
                <div className="terms-template-form__allow-release-container">
                    <FormGroupWithCheckbox
                        disabled={disableInput}
                        className="mb-3 ml-2"
                        innerRef={register}
                        name={"allowRelease"}
                        title="Allow release of this template version"
                    />
                    <FormGroupWithCheckbox
                        disabled={disableInput}
                        className="mb-3 ml-2"
                        innerRef={register}
                        name={"requiresConsent"}
                        title="This version will require the user to give a new consent"
                    />
                </div>
                {!watchIsFile && !hideContent && <h4 className="mt-4">Content</h4>}
                {!watchIsFile && (
                    <CodeEditor
                        className={cn("terms-template-form__content", hideContent && "terms-template-form__content--hide")}
                        code={content}
                        onChange={(code) => {
                            if (!disableInput) {
                                setContent(code)
                            }
                        }}
                        language="html"
                        minHeight={300}
                        onSaveKey={() => {
                            forceSubmit()
                        }}
                    />
                )}

                {!watchIsFile && (
                    <div className="terms-template-form__translations-container">
                        <h4>Translations</h4>
                        <div className="terms-template-form__translations-actions-container">
                            {languageSwitcher}
                            <StandardButtonWithSpinner
                                title="Fill blanks with similar translations"
                                className="terms-template-form__translations-fill-blanks"
                                isSmall
                                onClick={async () => {
                                    const allTranslations = await dispatch(getAllTermsTemplateTranslationsThunk(language))
                                    const currentTranslations = getValues().translations[language] ?? {}
                                    translationKeys.forEach((key) => {
                                        const currentTranslation =
                                            currentTranslations[prepareTemplateTranslationKeyForFormsLib(key)]
                                        if (
                                            currentTranslation &&
                                            !currentTranslation.noFallback &&
                                            !currentTranslation.translation
                                        ) {
                                            // Missing translation in current
                                            const similarTranslations = allTranslations.filter(
                                                (at) => at.key === key && !!at.translation
                                            )
                                            if (similarTranslations.length > 0) {
                                                const latestSimilarTranslation = sortDescending(
                                                    similarTranslations,
                                                    (t) => t.created
                                                )[0]
                                                const namePrefix =
                                                    "translations[" +
                                                    language +
                                                    "][" +
                                                    prepareTemplateTranslationKeyForFormsLib(key) +
                                                    "]"
                                                setValue(
                                                    namePrefix + "[translation]",
                                                    cleanTranslation(latestSimilarTranslation.translation)
                                                )
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className="terms-template-form__translation-row terms-template-form__translation-row-header">
                            <div className="terms-template-form__translation-row-key">
                                <b>Translation key</b>
                            </div>
                            <div className="terms-template-form__translation-row-value">
                                <b>Translation ({termsLanguages.find((tl) => tl.code === language)?.label ?? ""})</b>
                            </div>
                            <div className="terms-template-form__translation-row-no-fallback">
                                <b>No fallback to key</b>
                            </div>
                        </div>
                        {translationKeys.map((transKey) => {
                            const namePrefix =
                                "translations[" + language + "][" + prepareTemplateTranslationKeyForFormsLib(transKey) + "]"
                            let preventOnChangeClean = false
                            return (
                                <div
                                    key={transKey + "_" + language}
                                    className={cn(
                                        "terms-template-form__translation-row",
                                        !translationsFromContent.includes(transKey) &&
                                            "terms-template-form__translation-row--delete"
                                    )}
                                >
                                    <div className="terms-template-form__translation-row-key">{transKey}</div>
                                    <Input
                                        className="terms-template-form__translation-row-value"
                                        name={namePrefix + "[translation]"}
                                        disabled={disableInput}
                                        innerRef={register()}
                                        onChange={(e) => {
                                            if (preventOnChangeClean) {
                                                preventOnChangeClean = false
                                                return
                                            }
                                            setValue(namePrefix + "[translation]", cleanTranslation(e.target.value))
                                        }}
                                        onKeyDown={(e: React.KeyboardEvent) => {
                                            if (e.key === " " || e.keyCode === 32) {
                                                preventOnChangeClean = true
                                            }
                                            if ((e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                                                forceSubmit()
                                                e.preventDefault()
                                            }
                                        }}
                                        placeholder=""
                                        type="textarea"
                                        rows={Math.ceil(transKey.length / 100)}
                                    ></Input>
                                    <Input
                                        className="terms-template-form__translation-row-no-fallback"
                                        name={namePrefix + "[noFallback]"}
                                        disabled={disableInput}
                                        innerRef={register()}
                                        onKeyDown={(e: React.KeyboardEvent) => {
                                            if ((e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                                                forceSubmit()
                                                e.preventDefault()
                                            }
                                        }}
                                        placeholder=""
                                        type="checkbox"
                                    ></Input>
                                </div>
                            )
                        })}
                    </div>
                )}
                {watchIsFile && (
                    <div className="terms-template-form__files-container">
                        <h4>File Urls</h4>
                        <div className="terms-template-form__files-inner-container">
                            {languageSwitcher}
                            <FormGroupWithCheckbox
                                className="terms-template-form__files-file-is-internal"
                                name={"files[" + language + "][isInternal]"}
                                disabled={disableInput}
                                innerRef={register()}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                    if ((e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                                        forceSubmit()
                                        e.preventDefault()
                                    }
                                }}
                                onChange={(e) => {
                                    setValue(
                                        "files[" + language + "][fileUrl]",
                                        cleanFileUrl(getValues().files[language]?.fileUrl ?? "", !!e.target.value)
                                    )
                                }}
                                title="Is internal"
                                hint="Whether the file is saved in the Swiipe database and not found on some external URL."
                            />
                            <Input
                                className="terms-template-form__files-file-url"
                                name={"files[" + language + "][fileUrl]"}
                                disabled={disableInput}
                                innerRef={register()}
                                onChange={(e) => {
                                    setValue(
                                        "files[" + language + "][fileUrl]",
                                        cleanFileUrl(e.target.value, getValues().files[language]?.isInternal ?? false)
                                    )
                                }}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                    if ((e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                                        forceSubmit()
                                        e.preventDefault()
                                    }
                                }}
                                placeholder="File url"
                                type="text"
                            />
                        </div>
                    </div>
                )}
            </SpinnerContainer>
        </div>
    )
}

export default TermsTemplateForm
