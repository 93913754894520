import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import "./GoBackButtonLink.scss"

interface IGoBackButtonLinkProps {
    text?: string
    onClick: () => void
    className?: string
    includeArrow?: boolean
}

export const GoBackButtonLink = ({ text, onClick, className, includeArrow }: IGoBackButtonLinkProps) => {
    const { t } = useTranslation()
    return (
        <span
            className={cn("go-back-button-link", className)}
            onClick={() => {
                onClick()
            }}
        >
            {includeArrow && "< "}
            {text ?? t("common.navigation.goBack")}
        </span>
    )
}
