import React from "react"
import { GoBackButtonLink } from "../buttons/GoBackButtonLink"
import { StandardBox } from "../layouts/StandardBox"
import "./FormHeader.scss"

interface IFormHeaderProps {
    title: string
    onClose?: () => void
    onBack?: () => void
    navigationControl?: IFormNavigationControl
}

export interface IFormNavigationControl {
    onBack?: () => void
    onClose?: () => void
}

export const FormHeader = ({ title, onClose, onBack, navigationControl }: IFormHeaderProps) => {
    return (
        <StandardBox className="form-header" padding="30-20" margin="0" borders="rounded">
            <div className="form-header__back">
                {(navigationControl?.onBack || onBack) && (
                    <GoBackButtonLink
                        onClick={() => {
                            if (navigationControl?.onBack) {
                                navigationControl.onBack()
                                return
                            }
                            if (onBack) {
                                onBack()
                                return
                            }
                        }}
                        includeArrow
                    />
                )}
            </div>
            <div className="form-header__text">{title}</div>
            <div>
                <a
                    className="form-header__close"
                    onClick={() => {
                        if (navigationControl?.onClose) {
                            navigationControl.onClose()
                            return
                        }
                        if (onClose) {
                            onClose()
                        }
                    }}
                />
            </div>
        </StandardBox>
    )
}
