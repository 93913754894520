import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { IPartnerDetails } from "../../type/partner/IPartnerDetails"
import { StoreState } from "../StoreState"

export type PartnerState = Readonly<{
    partnerDetails: { [partnerId: string]: IPartnerDetails }
    hasMasterPartner?: boolean
    masterPartnerId?: string
}>
const initialState: PartnerState = { partnerDetails: {} }

export const partnerReducerActions = {
    setPartnerDetails: (partnerId: string, partnerDetails: IPartnerDetails) =>
        createAction("APP/SET_PARTNER_DETAILS", { partnerId, partnerDetails }),
    setHasMasterPartner: (hasMasterPartner: boolean, masterPartnerId: string) =>
        createAction("APP/SET_HAS_MASTER_PARNTER", { hasMasterPartner, masterPartnerId }),
}

export type PartnerActions = ActionsUnion<typeof partnerReducerActions>

export const partnerReducer = (state: PartnerState = initialState, action: PartnerActions) => {
    switch (action.type) {
        case "APP/SET_PARTNER_DETAILS": {
            return {
                ...state,
                partnerDetails: {
                    ...state.partnerDetails,
                    [action.partnerId]: action.partnerDetails,
                },
            }
        }
        case "APP/SET_HAS_MASTER_PARNTER": {
            return {
                ...state,
                hasMasterPartner: action.hasMasterPartner,
                masterPartnerId: action.masterPartnerId,
            }
        }
    }

    return state
}

export const partnerSelectors = {
    partnerDetails: (state: StoreState) =>
        state.userRelation.currentRelation ? state.partner.partnerDetails[state.userRelation.currentRelation.id] : undefined,
    partnersContainMasterPartner: (state: StoreState) => state.partner.hasMasterPartner,
    partnersMasterPartnerId: (state: StoreState) => state.partner.masterPartnerId,
}
