import React, { useState } from "react"
import { AccordionWithChildren, FloatingLabelInput } from "swiipe.portal.shared"
import { TPayment } from "../../type/TPayment"
import { TRegister } from "../../type/form/ReactHooksFormTypes"
import { IHealthStatusDto } from "../../type/healthStatus/IHealthStatusDto"
import DropDownList from "../form/input/DropDownList"
import FormGroupWithCheckbox from "../form/input/FormGroupWithCheckbox"
import "./HealthStatusSection.scss"

export interface IHealthStatusSectionData {
    title: string
    description: string
    level: "Low" | "Medium" | "High"
    inheritFromGeneral: boolean
    enabled: boolean
    overrideGlobal: boolean
    useFallback: boolean
    hidePaymentMethod: boolean
    hideSavedPaymentMethods: boolean
}

interface IHealthStatusSection {
    isGlobal: boolean
    paymentType: TPayment | "general"
    data?: IHealthStatusDto
    register: TRegister
    resolvedShouldInheritFromGeneral: boolean
    createNameForInput: (key: string) => string
    isExpanded: boolean
    expandedDidChange: (isExpanded: boolean) => void
}

const HealthStatusSection = ({
    paymentType,
    data,
    register,
    resolvedShouldInheritFromGeneral,
    isGlobal,
    createNameForInput,
    isExpanded,
    expandedDidChange,
}: IHealthStatusSection) => {
    const isGeneral = paymentType === "general"
    const [rerender, setRerender] = useState(0)

    return (
        <div className="swiipe-health-status-section">
            <AccordionWithChildren
                isExpanded={isExpanded}
                expandedDidChange={expandedDidChange}
                leftAlignHeader
                isInitiallyExpanded={isGeneral}
                header={paymentType}
            >
                {!isGeneral && (
                    <FormGroupWithCheckbox
                        className="mb-3 ml-2"
                        defaultChecked={data?.inheritFromGeneral}
                        innerRef={register}
                        name={createNameForInput("inheritFromGeneral")}
                        title="Inherit settings from general"
                        onChange={(e) => {
                            setRerender(rerender + 1)
                        }}
                        hint="Title, description, global override, and enabled settings will follow the general settings instead."
                    />
                )}
                {!resolvedShouldInheritFromGeneral && (
                    <FloatingLabelInput
                        containerClass="mb-1"
                        name={createNameForInput("title")}
                        defaultValue={data?.title ?? ""}
                        type="text"
                        placeholder="Title"
                        innerRef={register}
                    ></FloatingLabelInput>
                )}
                {!resolvedShouldInheritFromGeneral && (
                    <FloatingLabelInput
                        containerClass="mb-1"
                        name={createNameForInput("description")}
                        defaultValue={data?.description ?? ""}
                        type="textarea"
                        placeholder="Description"
                        innerRef={register}
                    ></FloatingLabelInput>
                )}
                {!resolvedShouldInheritFromGeneral && (
                    <DropDownList
                        className="mb-1"
                        options={["Low", "Medium", "High"].map((v) => ({ text: v, value: v }))}
                        name={createNameForInput("level")}
                        innerRef={register}
                        defaultValue={data?.level ?? "Low"}
                    ></DropDownList>
                )}
                {!resolvedShouldInheritFromGeneral && (
                    <FormGroupWithCheckbox
                        className="ml-2"
                        defaultChecked={data?.enabled}
                        innerRef={register}
                        name={createNameForInput("enabled")}
                        title="Enabled"
                        hint={
                            isGeneral
                                ? "Show the title and description everywhere relevant in the eCommerce admin panel and Swiipe Portal"
                                : "Show the title and description for this payment method in the eCommerce admin panel and Swiipe Portal"
                        }
                    />
                )}

                {!isGlobal && !resolvedShouldInheritFromGeneral && (
                    <FormGroupWithCheckbox
                        className="ml-2"
                        defaultChecked={data?.overrideGlobal}
                        innerRef={register}
                        name={createNameForInput("overrideGlobal")}
                        title="Override global settings"
                        hint="If settings are set on the global level (Swiipe Support), then ignore the global settings for this webshop and use those added here."
                    />
                )}

                {!isGeneral && paymentType === "CreditCard" && (
                    <FormGroupWithCheckbox
                        className="ml-2"
                        defaultChecked={data?.useFallback}
                        innerRef={register}
                        name={createNameForInput("useFallback")}
                        title="Use fallback"
                        hint="Use another payment gateway to make the payment. E.g. use QuickPay for credit cards instead of PaymentIQ."
                    />
                )}
                {!isGeneral && (
                    <FormGroupWithCheckbox
                        className="ml-2"
                        defaultChecked={data?.hidePaymentMethod}
                        innerRef={register}
                        name={createNameForInput("hidePaymentMethod")}
                        title="Hide payment method"
                        hint="Do not show the payment method in the checkout."
                    />
                )}
                {!isGeneral && (
                    <FormGroupWithCheckbox
                        className="ml-2"
                        defaultChecked={data?.hideSavedPaymentMethods}
                        innerRef={register}
                        name={createNameForInput("hideSavedPaymentMethods")}
                        title="Hide saved payment methods"
                        hint="If the payment type supports saved payment method when logged in with Swiipe, then do not show these."
                    />
                )}
            </AccordionWithChildren>
        </div>
    )
}

export default HealthStatusSection
