import { useState } from "react"
import { useSelector } from "swiipe.portal.shared"
import { partnerSelectors } from "../store/reducers/partnerReducer"
import { userRelationSelectors } from "../store/reducers/userRelationReducer"
import { IRelationSearchData } from "../type/user/IRelationSearchData"
import { IUserRelation, IUserRelationFlattened } from "../type/user/IUserRelation"
import { TUserRelation } from "../type/user/TUserRelation"
import { TUserRole } from "../type/user/TUserRole"
import { toUpperAndTrim } from "./../util/stringUtil"
import { navigationService } from "./navigationService"

export const flattenUserRelations = (userRelations: IUserRelation[]): IUserRelationFlattened[] => {
    let flattened: IUserRelationFlattened[] = []
    sortUserRelationsAlphabetical(userRelations).forEach((ul) => {
        flattened = [...flattened, ...flattenUserRelation(ul)]
    })
    return flattened
}

const flattenUserRelation = (userRelation: IUserRelation): IUserRelationFlattened[] => {
    const flattenedSubRelations: IUserRelationFlattened[] = (userRelation.subRelations || []).map((sr) => ({
        id: sr.id,
        name: sr.name,
        relationType: sr.relationType,
        parentId: userRelation.id,
        userRole: sr.userRole,
        isMasterPartner: false,
        markedDeletion: sr.markedDeletion,
    }))

    return [
        {
            id: userRelation.id,
            name: userRelation.name,
            relationType: userRelation.relationType,
            userRole: userRelation.userRole,
            isMasterPartner: !!userRelation.isMasterPartner,
            markedDeletion: userRelation.markedDeletion,
        },
        ...sortFlattenedSubRelationsAlphabetical(flattenedSubRelations),
    ]
}

function sortUserRelationsAlphabetical(userRelations: IUserRelation[]): IUserRelation[] {
    const result = userRelations.sort((a, b) => {
        if (a.relationType === "User") {
            return -1
        } else if (b.relationType === "User") {
            return 1
        }

        if (toUpperAndTrim(a.name) < toUpperAndTrim(b.name)) {
            if (a.relationType === "Partner" && b.relationType === "Merchant") {
                return 1
            }

            return -1
        }

        if (toUpperAndTrim(a.name) > toUpperAndTrim(b.name)) {
            if (a.relationType === "Merchant" && b.relationType === "Partner") {
                return -1
            }

            return 1
        }

        return 0
    })

    return result
}

function sortFlattenedSubRelationsAlphabetical(flattenedSubRelations: IUserRelationFlattened[]): IUserRelationFlattened[] {
    const result = flattenedSubRelations.sort((a, b) =>
        toUpperAndTrim(a.name) < toUpperAndTrim(b.name) ? -1 : toUpperAndTrim(a.name) > toUpperAndTrim(b.name) ? 1 : 0
    )

    return result
}

export interface IRequiredUserRelation {
    requiredType?: TUserRelation
    requiredRole?: TUserRole
    requiredMasterPartner?: boolean
}

export const useRequiredUserRelations = (requirements: IRequiredUserRelation[]) => {
    const [checked, setChecked] = useState(false)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const masterPartnerId = useSelector(partnerSelectors.partnersMasterPartnerId)

    if (checked || !currentUserRelation) {
        return
    }

    const matchFound = requirements.find(
        (req) =>
            (!req.requiredRole || req.requiredRole <= currentUserRelation.userRole) &&
            (!req.requiredType || req.requiredType === currentUserRelation.relationType) &&
            (!req.requiredMasterPartner || currentUserRelation.id === masterPartnerId)
    )

    if (matchFound) {
        return
    }
    setChecked(true)
    navigationService.navigate(getRootUrlForRelation(currentUserRelation))
}

export const getCurrentUserRelationFromLocalStorage = (
    userRelations: IUserRelationFlattened[]
): IUserRelationFlattened | undefined => {
    const storedStr = localStorage.getItem("currentUserRelation")
    const relationFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as IUserRelationFlattened | undefined
    if (!relationFromLocalStorage) {
        return
    }
    const rel = userRelations.find(
        (ur) => ur.id === relationFromLocalStorage.id && ur.parentId === relationFromLocalStorage.parentId
    )
    return rel
}

export const setUserRelationToLocalStorage = (userRelationFlattened: IUserRelationFlattened) => {
    localStorage.setItem("currentUserRelation", JSON.stringify(userRelationFlattened))
}

export const getLastUsedRelationsFromLocalStorage = (
    currentUserId: string,
    userRelationsFlattened: IUserRelationFlattened[]
): IUserRelationFlattened[] | undefined => {
    const storedStr = localStorage.getItem("lastUsedRelations")
    const idsFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as { [userId: string]: string[] } | undefined

    if (!idsFromLocalStorage || !idsFromLocalStorage[currentUserId]) {
        return
    }

    return filterAndSortUserRelationsForSwitchUserMenu(userRelationsFlattened, idsFromLocalStorage[currentUserId])
}

export const filterAndSortUserRelationsForSwitchUserMenu = (
    userRelationsFlattenedActive: IUserRelationFlattened[],
    lastVisitedAccounts: string[]
) => {
    const uniqueRelations = userRelationsFlattenedActive.filter(
        (rel, index, self) => self.findIndex((r) => r.id === rel.id) === index
    )

    const filteredAndSorted = uniqueRelations
        .filter((rel) => lastVisitedAccounts.includes(rel.id))
        .sort((a, b) => (lastVisitedAccounts.indexOf(a.id) > lastVisitedAccounts.indexOf(b.id) ? 1 : -1))

    return filteredAndSorted.length === 5
        ? filteredAndSorted
        : [...filteredAndSorted, ...uniqueRelations]
              .filter((rel, index, self) => self.findIndex((r) => r.id === rel.id) === index)
              .slice(0, 5)
}

export const setLastUsedRelationsToLocalStorage = (currentUserId: string, ids: string[]) => {
    const storedStr = localStorage.getItem("lastUsedRelations")
    const idsFromLocalStorage = (storedStr ? JSON.parse(storedStr) : undefined) as { [userId: string]: string[] } | undefined

    if (!idsFromLocalStorage) {
        localStorage.setItem("lastUsedRelations", JSON.stringify({ [currentUserId]: ids }))
        return
    }

    idsFromLocalStorage[currentUserId] = ids
    localStorage.setItem("lastUsedRelations", JSON.stringify(idsFromLocalStorage))
}

export const getRootUrlForRelation = (userRelationFlattened: IUserRelationFlattened) => {
    switch (userRelationFlattened.relationType) {
        case "Merchant":
            return "/merchantDetails"
        case "User":
            return "/personalinfo"
        case "Partner":
            return userRelationFlattened.isMasterPartner ? "/merchantOnboardings" : "/sentEmails"
        case "CallCenter":
            return "/"
        default:
            return "/"
    }
}

export const getTextIdForRole = (role: number) => {
    if (role >= TUserRole.Owner) {
        return "roles.owner"
    }
    if (role >= TUserRole.Admin) {
        return "roles.admin"
    }
    return "roles.member"
}

export const getIconClassForRelation = (userRelation: IUserRelationFlattened) => {
    switch (userRelation.relationType) {
        case "User":
            return "icon-nav-user"
        case "Merchant":
            return "icon-roleswitch-merchant"
        case "Partner":
            return "icon-roleswitch-partner"
        case "CallCenter":
            if (userRelation.userRole >= TUserRole.Admin) {
                return "icon-nav-manager"
            }
            return "icon-icon-agent"
        default:
            return ""
    }
}

export const filterRelationsBySearchValue = (
    searchValue: string,
    userRelations: IUserRelationFlattened[],
    userRelationsSearchData?: IRelationSearchData
) => {
    let relationIds = [] as string[]
    const searchValueLowerCase = searchValue.toLowerCase()

    if (searchValue != "" && userRelationsSearchData) {
        const searchData = userRelationsSearchData.relationSearchData
        const relationIdsFromSearch = Object.keys(searchData)

        relationIds = relationIdsFromSearch.filter((relationId) => {
            if (searchValue.includes(relationId.toLowerCase())) {
                return true
            }
            const relationData = searchData[relationId]
            if (relationData.cvr?.indexOf(searchValueLowerCase) >= 0) {
                return true
            }
            if (relationData.webshopUrls?.find((wurl: string) => wurl?.toLowerCase().indexOf(searchValueLowerCase) >= 0)) {
                return true
            }
            if (relationData.clientIds?.find((clientId: string) => clientId?.toLowerCase() == searchValueLowerCase)) {
                return true
            }
            if (relationData.webshopIds?.find((webshopId: string) => webshopId?.toLowerCase() == searchValueLowerCase)) {
                return true
            }
            return false
        })
    }

    return userRelations.filter(
        (rel) => (!!rel.name && rel.name.toLowerCase().indexOf(searchValueLowerCase) >= 0) || relationIds.includes(rel.id)
    )
}

export function mapUserRelationTypeToOrganizationType(userRelationType: TUserRelation): string {
    switch (userRelationType) {
        case "Merchant":
            return "SwMerchant"
        case "Partner":
            return "Partner"
        case "CallCenter":
            return "CallCenter"
    }

    throw "User not an organization type"
}
