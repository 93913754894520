import React from "react"
import "./StandardBox.scss"
import cn from "classnames"

export type TStandardBoxWidth = "narrow"

interface IStandardBoxProps {
    children?: React.ReactNode
    id?: string
    className?: string
    width?: "narrow"
    margin?: "0" | "20-0" | "none"
    padding?: "15" | "15-20" | "30-20" | "35-20"
    borders?: "rounded"
}

export const StandardBox = ({ children, id, className, width, margin = "20-0", padding = "15", borders }: IStandardBoxProps) => {
    return (
        <div
            className={cn(
                className,
                "swiipe-standard-box",
                width && `swiipe-standard-box__width-${width}`,
                margin && `swiipe-standard-box__margin-${margin}`,
                padding && `swiipe-standard-box__padding-${padding}`,
                borders && `swiipe-standard-box__borders-${borders}`
            )}
            id={id}
        >
            {children}
        </div>
    )
}
