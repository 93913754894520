import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"

interface IGoogleAnalyticsWebshopDataResponse {
    eventNamesOverrides: IGoogleAnalyticsEventNamesOverrides
}
interface IGoogleAnalyticsEventNamesOverrides {
    beginCheckoutEventName: string
    purchaseEventName: string
}

export const adminGetGoogleAnalyticsWebshopDataThunk =
    (
        swMerchantId: string,
        webshopId: string
    ): ThunkAction<Promise<IGoogleAnalyticsWebshopDataResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IGoogleAnalyticsWebshopDataResponse>(endpoints.GoogleAnalytics.getWebshopData, {
                params: {
                    swMerchantId,
                    webshopId,
                },
            })
        )
    }

export const adminSetGoogleAnalyticsWebshopDataThunk =
    (
        swMerchantId: string,
        webshopId: string,
        webshopData: IGoogleAnalyticsWebshopDataResponse
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.GoogleAnalytics.setWebshopData, {
                data: {
                    swMerchantId,
                    webshopId,
                    ...webshopData,
                },
            })
        )
    }

export interface IGoogleAnalyticsEventsOverviewResponse {
    events: IGoogleAnalyticsEventOverview[]
}
interface IGoogleAnalyticsEventOverview {
    name: string
    engagedSessions: number
}
export const adminGetGoogleAnalyticsEventsOverviewThunk =
    (
        swMerchantId: string,
        webshopId: string
    ): ThunkAction<Promise<IGoogleAnalyticsEventsOverviewResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IGoogleAnalyticsEventsOverviewResponse>(
                endpoints.GoogleAnalytics.getEventsOverview,
                {
                    params: {
                        swMerchantId,
                        webshopId,
                    },
                },
                {
                    noErrorAlert: true,
                }
            )
        )
    }
