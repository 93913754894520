export const validationPatterns = {
    //Email regular expression
    email: /^[\wÆæøØåÅ]+([.-]?[\wÆæøØåÅ]+)*@[\wÆæøØåÅ]+([.-]?\w+)*(\.[\wÆæøØåÅ]{2,3})+$/,
    emailsCommaSeparated: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s?)+$/,
    //At least one upper case
    //At least one lower case
    //At least one number
    //Length  minimum 8
    password: /^(?=.*?[A-ZÅÆØ])(?=.*?[a-zåæø])(?=.*?[0-9]).{8,}$/,
    fileName: /^[a-z0-9\-\_]{3,}$/,
    phone: /^(\+\d{2})\d{8}$/,

    inputFloatNum: /^-?\d+.*(\d+)*$/,

    saleClaimedBy: /^[a-z]{3}$/,
    partnerId: /^\d{4}$/,
}

// Sadly, react-hook-form fire validation pattern for non-required field, even when the field has no value.
// To avoid this bug, we instead use the custom validation funtion

export function valFuncPattern(pattern: RegExp, patternErrorMeessage: string): { validate: (value: string) => true | string } {
    return {
        validate: (value: string) => {
            if (!value) {
                return true
            }
            const matches = value.match(pattern)
            if (matches && matches.length > 0 && matches[0] === value) {
                return true
            }
            return patternErrorMeessage
        },
    }
}

export function valFuncRequired(requiredErrorMeessage: string): { required: string } {
    return {
        required: requiredErrorMeessage,
    }
}

export function fileMinMaxAmountMaxSizeBytesFunc(
    requiredErrorMeessage: string,
    minFilesAmount?: number,
    maxFilesAmount?: number,
    maxFileSizeInBytes?: number
): { validate: (value: FileList) => true | string } {
    return {
        validate: (value: FileList) => {
            const filesKeys = Object.keys(value)

            if (minFilesAmount && filesKeys.length < minFilesAmount) {
                return requiredErrorMeessage
            }

            if (maxFilesAmount && filesKeys.length > maxFilesAmount) {
                return requiredErrorMeessage
            }

            if (maxFileSizeInBytes) {
                const hasLargeFiles = filesKeys.find((key) => (value[key].size > maxFileSizeInBytes ? true : false))

                if (hasLargeFiles) {
                    return requiredErrorMeessage
                }
            }

            return true
        },
    }
}

export function valFuncRequiredAndPattern(
    pattern: RegExp,
    patternErrorMeessage: string,
    requiredErrorMessage?: string
): { validate: (value: string) => true | string; required: string } {
    return {
        validate: (value: string) => {
            if (!value) {
                return true
            }
            const matches = value.match(pattern)
            if (matches && matches.length > 0 && matches[0] === value) {
                return true
            }
            return patternErrorMeessage
        },
        required: requiredErrorMessage || patternErrorMeessage,
    }
}

export const isValidEmail = (email: string): boolean => {
    email = email.trim()
    const matches = email.match(validationPatterns.email)
    if (matches && matches.length > 0 && matches[0] === email) {
        return true
    }

    return false
}
