import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { endpointsShared } from "../data/endpointsShared"
import { SharedStoreState } from "../store/SharedStoreState"
import { swMerchantActions, swMerchantSelectors } from "../store/swMerchantSlice"
import { IMerchantAcquiringKyc } from "../types/merchant/IMerchantAcquiring"
import { requestThunk } from "./serverThunks"

export const getMerchantAcquiringThunk =
    (swMerchantId: string, force: boolean): ThunkAction<void, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && swMerchantSelectors.acquiringKycs(getState(), swMerchantId)) {
            return
        }
        const res = await dispatch(
            requestThunk<{ acquiringKycs: IMerchantAcquiringKyc[] }>(endpointsShared.Identity.getMerchantAcquiring, {
                params: {
                    swMerchantId,
                },
            })
        )
        dispatch(
            swMerchantActions.setAcquiringKycs({
                swMerchantId,
                acquiringKycs: res.acquiringKycs,
            })
        )
    }

export const setMerchantAcquiringThunk =
    (acquiringKyc: IMerchantAcquiringKyc, ignoreWebshopIds: boolean): ThunkAction<void, SharedStoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<{ acquiringKycs: IMerchantAcquiringKyc[] }>(endpointsShared.Identity.setMerchantAcquiring, {
                data: {
                    ignoreWebshopIds,
                    acquiringKyc,
                },
            })
        )
        await dispatch(getMerchantAcquiringThunk(acquiringKyc.swMerchantId, true))
    }
